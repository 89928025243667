import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelThreeMaterial({levelFour, setLevelFour, setDocuments, color}) {
    return (
        <Grid xs={7} container justifyContent={'space-between'} direction="column">

            <Grid item xs={7}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 0 ? color : null}}
                       onClick={() => {
                           setLevelFour(0)
                           setDocuments(false)
                       }}>
                    <Typography>Договор</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 1 ? color : null}}
                       onClick={() => {
                           setLevelFour(1)
                           setDocuments(false)
                       }}>
                    <Typography>График доставка</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 2 ? color : null}}
                       onClick={() => {
                           setLevelFour(2)
                           setDocuments(false)
                       }}>
                    <Typography>Анекс</Typography>
                </Paper>
            </Grid>
            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 3 ? color : null}}
                       onClick={() => {
                           setLevelFour(3)
                           setDocuments(true)
                       }}>
                    <Typography>Документи</Typography>
                </Paper>
            </Grid>

        </Grid>

    );
}

export default LevelThreeMaterial;