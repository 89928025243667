import Grid from "@mui/material/Grid";
import LevelTwoInvestorDeliver from "../../levels/LevelTwoInvestorDeliver";
import React, {useState} from "react";
import LevelThreeMost from "../../levels/levelThree/LevelThreeMost";
import LevelFourOffers from "../../levels/levelFour/LevelFourOffers";
import LevelDocuments from "../../levels/LevelDocuments";
import TrackOneInvestor from "./TrackOneInvestor";
import TrackOneDeliver from "./TrackOneDeliver";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import OfferObjectDialog from "../../OfferObjectDialog";

function TrackOne({
                      objects,
                      selectedObjectForOffer, setSelectedObjectForOffer,
                      selectedObjectForOfferName, setSelectedObjectForOfferName,
                      levelDeliver,
                      service,
                      material, setMaterial,
                      selectedObject,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup, setLevelDeliver,
                      stage, setStage,
                      group, setGroup,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                      color, SERVICE, MATERIAL
                  }) {

    const [isObjectChosen, setObjectChosen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const spanRef = React.useRef();


    return (

        /* ----------- 2.-----------*/

            <Grid container>

                {!selectedObjectForOfferName ? <Grid item xs={1.5}>
                    <Grid container xs={10} direction="column">
                        <Grid item xs={10}>
                            <Paper elevation={6} className={"paper-style"} ref={spanRef}
                                   style={{background: isObjectChosen ? color : null}}
                                   onClick={() => {
                                       setAnchorEl(spanRef.current);
                                       setObjectChosen(true)
                                   }}>
                                <Typography>{selectedObjectForOfferName ? selectedObjectForOfferName : "Обект"}</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid> : null}




                {
                    selectedObjectForOffer ? <Grid item xs={3} sm={2}>
                        {/*
                            Доставчик, Инвеститор
                        */}
                        <LevelTwoInvestorDeliver
                            levelOneTwo={levelOneTwo}
                            levelTwo={levelTwo}
                            setLevelTwo={setLevelTwo}
                            setLevelOneTwo={setLevelOneTwo}
                            setLevelThree={setLevelThree}
                            setLevelFour={setLevelFour}
                            setServiceMaterial={setServiceMaterial}
                            setLevelDeliver={setLevelDeliver}
                            color={color}/>
                    </Grid>
                        :
                        null

                }


                    <Grid item xs={7} sm={8}>
                        {levelOneTwo === 0 ?

                            <TrackOneInvestor
                                selectedObject={selectedObjectForOffer}
                                levelOneTwo={levelOneTwo}
                                levelThree={levelThree}
                                levelFour={levelFour}
                                setLevelTwo={setLevelTwo}
                                setLevelOneTwo={setLevelOneTwo}
                                setLevelThree={setLevelThree}
                                setLevelFour={setLevelFour}
                                isDocuments={isDocuments}
                                setDocuments={setDocuments}
                                isScanned={isScanned}
                                setScanned={setScanned}
                                isElectronic={isElectronic}
                                setElectronic={setElectronic}
                                isPhysical={isPhysical}
                                setPhysical={setPhysical}
                                color={color}/>
                            :
                            levelOneTwo === 1 ?
                            <TrackOneDeliver
                                levelDeliver={levelDeliver}
                                setLevelDeliver={setLevelDeliver}
                                good={service}
                                material={material}
                                setMaterial={setMaterial}
                                selectedObject={selectedObjectForOffer}
                                levelOneTwo={levelOneTwo}
                                levelThree={levelThree}
                                levelFour={levelFour}
                                setLevelTwo={setLevelTwo}
                                setLevelOneTwo={setLevelOneTwo}
                                setLevelThree={setLevelThree}
                                setLevelFour={setLevelFour}
                                serviceMaterial={serviceMaterial}
                                setServiceMaterial={setServiceMaterial}
                                stage={stage}
                                setStage={setStage}
                                group={group}
                                setGroup={setGroup}
                                assignee={assignee}
                                setAssignee={setAssignee}
                                levelStageGroup={levelStageGroup}
                                isDocuments={isDocuments}
                                setDocuments={setDocuments}
                                isScanned={isScanned}
                                setScanned={setScanned}
                                isElectronic={isElectronic}
                                setElectronic={setElectronic}
                                isPhysical={isPhysical}
                                setPhysical={setPhysical}
                                color={color}
                                SERVICE={SERVICE}
                                MATERIAL={MATERIAL}
                                />
                                :
                                null
                        }
                    </Grid>


                <OfferObjectDialog
                    objects={objects}
                    anchorEl={anchorEl}
                    open={isObjectChosen}
                    selectedObject={selectedObjectForOffer}
                    setSelectedObject={setSelectedObjectForOffer}
                    selectedObjectForOfferName={selectedObjectForOfferName}
                    setSelectedObjectForOfferName={setSelectedObjectForOfferName}
                    handleClose={() => {
                        setAnchorEl(null);
                        setObjectChosen(false)
                    }}/>

            </Grid>


    );
}
export default TrackOne;