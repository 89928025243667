import LevelFourOffers from "./LevelFourOffers";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelFourRest({levelFour, levelFourOne, setLevelFour, setLevelFourOne, color}) {

    return (
        <Grid container>
            <Grid item xs={6}>
                <Grid xs={8} container justifyContent={'space-between'} direction="column">

                    <Grid item xs={7}>
                        <Paper elevation={6} className={"paper-style"}
                               style={{background: levelFour === 0 ? color : null}}
                               onClick={() => {
                                   setLevelFour(0)
                               }}>
                            <Typography>Акт 1</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <Paper elevation={6} className={"paper-style"}
                               style={{background: levelFour === 1 ? color : null}}
                               onClick={() => {
                                   setLevelFour(1)
                               }}>
                            <Typography>Акт 2</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <Paper elevation={6} className={"paper-style"}
                               style={{background: levelFour === 2 ? color : null}}
                               onClick={() => {
                                   setLevelFour(2)
                               }}>
                            <Typography>Акт 3</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <Paper elevation={6} className={"paper-style"}
                               onClick={() => {


                               }}>
                            <Typography>Добави акт</Typography>
                        </Paper>
                    </Grid>


                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid xs={8} container justifyContent={'space-between'} direction="column">

                    <Grid item xs={7}>
                        <Paper elevation={6} className={"paper-style"}
                               style={{background: levelFourOne === 0 ? color : null}}
                               onClick={() => {
                                   setLevelFourOne(0)
                               }}>
                            <Typography>Акт</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <Paper elevation={6} className={"paper-style"}
                               style={{background: levelFourOne === 1 ? color : null}}
                               onClick={() => {
                                   setLevelFourOne(1)
                               }}>
                            <Typography>Фактура</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <Paper elevation={6} className={"paper-style"}
                               style={{background: levelFourOne === 2 ? color : null}}
                               onClick={() => {
                                   setLevelFourOne(2)
                               }}>
                            <Typography>Платежно</Typography>
                        </Paper>
                    </Grid>


                </Grid>
            </Grid>
        </Grid>

    )
}
export default LevelFourRest;