import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {ListItemButton} from "@mui/material";
import {Fragment} from "react";
import Grid from "@mui/material/Grid";
import {Group} from "@mui/icons-material";
import moment from "moment";
import bg from 'moment/locale/bg'
import {getFile} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function CategoryDialogComponent(props) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    return  (
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {props.category} - {props.object}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={props.handleClose}>
                        Добави файл
                    </Button>
                </Toolbar>
            </AppBar>
            <List>
                {
                    props.attachments && props.attachments.length > 0 ? props.attachments.map((attachment) => {
                        return <Fragment>
                            <ListItemButton>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <ListItemText primary={attachment.filename} secondary={'Качен на ' + moment(attachment.date_doc).format("dddd d MMM YYYY HH:mm") + ' от ' + attachment.creater} />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <ListItemButton onClick={() => {
                                            getFile(navigate, accessToken, setAccessToken, attachment.id);
                                        }} >Изтегли</ListItemButton>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ListItemButton onClick={() => {
                                            const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${attachment.id}&token=${accessToken}`;
                                            props.setViewFile(url);
                                        }} >Прегледай</ListItemButton>
                                    </Grid>
                                </Grid>

                            </ListItemButton>
                            <Divider />
                        </Fragment>
                    })
                    :
                        <Grid container justifyContent={'center'}>
                            <Grid item>
                                <Typography variant={'h5'}>Нямате файлове</Typography>
                            </Grid>
                        </Grid>
                }
            </List>
        </Dialog>
    )
}
export default CategoryDialogComponent;