import Grid from "@mui/material/Grid";
import LevelTwoInvestorDeliver from "../../levels/LevelTwoInvestorDeliver";
import React from "react";

import TrackTwoInvestor from "./TrackTwoInvestor";
import TrackTwoDeliver from "./TrackTwoDeliver";

function TrackTwo({
                      good, material, setMaterial,
                      selectedObject,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup, levelDeliver, setLevelDeliver,
                      stage, setStage,
                      group, setGroup,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                      color, SERVICE, MATERIAL
                  }) {

    return (

        /* ----------- 2.-----------*/

            <Grid container>

                <Grid item xs={4} sm={1.5}>
{/*
            Доставчик, Инвеститор
*/}

                    <LevelTwoInvestorDeliver
                        levelOneTwo={levelOneTwo}
                        levelTwo={levelTwo}
                        setLevelTwo={setLevelTwo}
                        setLevelOneTwo={setLevelOneTwo}
                        setLevelThree={setLevelThree}
                        setLevelFour={setLevelFour}
                        setServiceMaterial={setServiceMaterial}
                        setLevelDeliver={setLevelDeliver}
                        color={color}/>
                </Grid>


                    <Grid item xs={8} sm={10.5}>
                        {levelOneTwo === 0 ?

                            <TrackTwoInvestor
                                selectedObject={selectedObject}
                                levelOneTwo={levelOneTwo}
                                levelThree={levelThree}
                                levelFour={levelFour}
                                setLevelTwo={setLevelTwo}
                                setLevelOneTwo={setLevelOneTwo}
                                setLevelThree={setLevelThree}
                                setLevelFour={setLevelFour}
                                isDocuments={isDocuments}
                                setDocuments={setDocuments}
                                isScanned={isScanned}
                                setScanned={setScanned}
                                isElectronic={isElectronic}
                                setElectronic={setElectronic}
                                isPhysical={isPhysical}
                                setPhysical={setPhysical}
                                color={color}/>
                            :

                            levelOneTwo === 1 ?
                                <TrackTwoDeliver
                                    levelDeliver={levelDeliver}
                                    setLevelDeliver={setLevelDeliver}
                                    selectedObject={selectedObject}
                                    levelOneTwo={levelOneTwo}
                                    levelThree={levelThree}
                                    levelFour={levelFour}
                                    setLevelTwo={setLevelTwo}
                                    setLevelOneTwo={setLevelOneTwo}
                                    setLevelThree={setLevelThree}
                                    setLevelFour={setLevelFour}
                                    serviceMaterial={serviceMaterial}
                                    setAssignee={setAssignee}
                                    material={material}
                                    good={good}
                                    setMaterial={setMaterial}
                                    stage={stage}
                                    setStage={setStage}
                                    group={group}
                                    setGroup={setGroup}
                                    assignee={assignee}
                                    levelStageGroup={levelStageGroup}
                                    setServiceMaterial={setServiceMaterial}
                                    isDocuments={isDocuments}
                                    setDocuments={setDocuments}
                                    isScanned={isScanned}
                                    setScanned={setScanned}
                                    isElectronic={isElectronic}
                                    setElectronic={setElectronic}
                                    isPhysical={isPhysical}
                                    setPhysical={setPhysical}
                                    color={color}
                                    SERVICE={SERVICE}
                                    MATERIAL={MATERIAL}
                                />
                                :
                                null

                        }
                    </Grid>

            </Grid>


    );
}
export default TrackTwo;