import {Grid} from "@mui/material";
import LevelTwoInvestorDeliver from "../../levels/LevelTwoInvestorDeliver";
import React from "react";
import TrackThreeInvestor from "./investor/TrackThreeInvestor";
import TrackThreeDeliver from "./deliver/TrackThreeDeliver";

function TrackThree({
                        hideBeforeDeliverer, selectedObject,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      material, setMaterial,
                      service, setService,
                      levelStageGroup,
                      levelDeliver, setLevelDeliver,
                      stage, setStage,
                      group, setGroup,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                        act, setAct,
                      color, SERVICE, MATERIAL
                  }) {

    return (
        <Grid container>

            {
                !hideBeforeDeliverer &&
                <Grid item xs={4} sm={1.5}>
                    {/*
            Доставчик, Инвеститор
*/}

                    <LevelTwoInvestorDeliver
                        levelOneTwo={levelOneTwo}
                        levelTwo={levelTwo}
                        setLevelTwo={setLevelTwo}
                        setLevelOneTwo={setLevelOneTwo}
                        setLevelThree={setLevelThree}
                        setLevelFour={setLevelFour}
                        setServiceMaterial={setServiceMaterial}
                        setLevelDeliver={setLevelDeliver}
                        color={color}/>
                </Grid>
            }


            {
                levelOneTwo === 0 ?
                    <Grid item xs={8} sm={hideBeforeDeliverer? 12 : 10.5}>

                        <TrackThreeInvestor
                            hideBeforeDeliverer={hideBeforeDeliverer}
                            service={service}
                            levelDeliver={levelDeliver}
                            selectedObject={selectedObject}
                            levelTwo={levelTwo}
                            setLevelTwo={setLevelTwo}
                            levelThree={levelThree}
                            setLevelThree={setLevelThree}
                            levelFour={levelFour}
                            setLevelFour={setLevelFour}
                            act={act}
                            setAct={setAct}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}
                            color={color}
                        />
                    </Grid>
                    :
                    levelOneTwo === 1 ?

                        <Grid item xs={8} sm={hideBeforeDeliverer ? 12 : 10.5}>

                        <TrackThreeDeliver
                            hideBeforeDeliverer={hideBeforeDeliverer}
                            selectedObject={selectedObject}
                            levelTwo={levelTwo}
                            setLevelTwo={setLevelTwo}
                            levelThree={levelThree}
                            setLevelThree={setLevelThree}
                            levelFour={levelFour}
                            setLevelFour={setLevelFour}
                            material={material}
                            setMaterial={setMaterial}
                            service={service}
                            setService={setService}
                            act={act}
                            setAct={setAct}
                            levelDeliver={levelDeliver}
                            setLevelDeliver={setLevelDeliver}
                            stage={stage}
                            setStage={setStage}
                            group={group}
                            setGroup={setGroup}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            assignee={assignee}
                            setAssignee={setAssignee}
                            serviceMaterial={serviceMaterial}
                            setServiceMaterial={setServiceMaterial}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}
                            color={color}
                            SERVICE={SERVICE}
                            MATERIAL={MATERIAL}
                        />
                    </Grid>
                        :
                        null
            }
        </Grid>
    )
}
export default TrackThree;