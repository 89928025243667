import List from "@mui/material/List";
import {
    alpha, Input, InputAdornment,
    InputBase,
    ListItem,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {purple} from "@mui/material/colors";
import AnalysisDialog from "../analysis/AnalysisDialog";
import {CommonFetch} from "../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ObjectDataDialog from "./AddEditWorkDialog";

function SicComponent() {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [items, setItems] = useState([]);


    const [searchCriteria, setSearchCriteria] = useState(null);
    const [analysisDialogOpen, setAnalysisDialogOpen] = useState(false);
    const [idSmrGroup, setIdSmrGroup] = useState(null);
    const [workId, setWorkId] = useState(null);

    const [createEditWorkDialogOpen, setCreateEditWorkDialogOpen] = useState(false);
    const [workName, setWorkName] = useState(null);
    const [workCode, setWorkCode] = useState(null);
    const [workPercWork, setWorkPercWork] = useState(null);
    const [workPercMaterial, setWorkPercMaterial] = useState(null);
    const [workPercMech, setWorkPercMech] = useState(null);

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/groups?`+ new URLSearchParams({
            token: accessToken
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        }).then((result) => {
            setItems(result)
        });

    }, [])
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    }));
    return (
        <List>
            <Input style={{marginLeft: 18}}
                   placeholder={'Търси'}
                   onChange={(event) => {
                       setSearchCriteria(event.target.value)
                   }}
                   startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
            }/>

            {items && items.items && items.items
                .filter((top) => {
                    if (searchCriteria && searchCriteria.length > 0) {
                        const lowerSearchCriteria = searchCriteria.toLowerCase();
                        if (top && top.name && top.name.toLowerCase().includes(lowerSearchCriteria)) {
                            return true;
                        }
                        return top.sub.filter(sub =>
                            (sub && sub.name && sub.name.toLowerCase().includes(lowerSearchCriteria))
                            || (sub && sub.code && sub.code.toLowerCase().includes(lowerSearchCriteria))
                    ).length > 0}
                    return true;
                })
                .map((top) => (

                <ListItem>
                    <Grid container>
                        <Grid item xs={10} style={{background: '#007afe', padding: 20}}>
                            <Typography style={{color: 'white'}}>{top.name}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{background: '#007afe', padding: 20}}
                              onClick={() => {
                                  setWorkName(null);
                                  setWorkCode(null);
                                  setWorkPercWork(null);
                                  setWorkPercMaterial(null);
                                  setWorkPercMech(null)
                                  setCreateEditWorkDialogOpen(true)
                              }}>
                            <ColorButton
                                color="secondary" style={{color: 'white'}}>Нов анализ</ColorButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>КОД</TableCell>
                                        <TableCell>Наименование</TableCell>
                                        <TableCell align="right">Мярка</TableCell>
                                        <TableCell align="right">Цена</TableCell>
                                        <TableCell align="right">Труд</TableCell>
                                        <TableCell align="right">Материали</TableCell>
                                        <TableCell align="right">Механизация</TableCell>
                                        <TableCell align="right">Доп. разходи</TableCell>
                                        <TableCell align="right">Действия</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {top && top.sub && top.sub
                                        .filter((sub) => {
                                            if (searchCriteria && searchCriteria.length > 0) {
                                                const lowerSearchCriteria = searchCriteria.toLowerCase();
                                                return (sub && sub.name && sub.name.toLowerCase().includes(lowerSearchCriteria))
                                                    || (sub && sub.code && sub.code.toLowerCase().includes(lowerSearchCriteria));
                                            }
                                            return true;
                                        })
                                        .map((item) => (
                                        <TableRow
                                            key={item.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            onClick={() => {
                                                setWorkId(item.id)
                                                setIdSmrGroup(item.id_smr_group)
                                                setAnalysisDialogOpen(true)
                                            }}
                                        >
                                            <TableCell>{item.code}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.name}
                                            </TableCell>
                                            <TableCell align="right">{item.entity}</TableCell>
                                            <TableCell align="right">{item.price ? item.price.toFixed(2) : null}</TableCell>
                                            <TableCell align="right">{item.price_work}</TableCell>
                                            <TableCell align="right">{item.price_goods}</TableCell>
                                            <TableCell align="right">{item.price_mech}</TableCell>
                                            <TableCell align="right">{item.price_other}</TableCell>
                                            <TableCell align="right">
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <IconButton aria-label="delete" onClick={(e) => {
                                                            e.stopPropagation()
                                                            e.nativeEvent.stopImmediatePropagation();
                                                            setWorkName(item.name);
                                                            setWorkCode(null);
                                                            setWorkPercWork(item.price_work);
                                                            setWorkPercMaterial(null);
                                                            setWorkPercMech(null)
                                                            setCreateEditWorkDialogOpen(true)
                                                        }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                   <Grid item xs={6}>

                                                       <IconButton aria-label="delete">
                                                           <DeleteIcon />
                                                       </IconButton>
                                                   </Grid>
                                                </Grid>

                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    {
                                        items && items.detail && items.detail.helping &&
                                        <TableRow
                                            key={'helping'}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{items.detail.helping.name}</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{items.details.helping.quantity_percent}</TableCell>
                                            <TableCell align="right">{items.details.helping.entity_price}</TableCell>
                                            <TableCell align="right">{items.details.helping.total_price}</TableCell>
                                        </TableRow>
                                    }

                                    {
                                        items && items.detail && items.detail.delivery &&
                                        <TableRow
                                            key={'helping'}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{items.details.delivery.name}</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{items.details.delivery.quantity_percent}</TableCell>
                                            <TableCell align="right">{items.details.delivery.entity_price}</TableCell>
                                            <TableCell align="right">{items.details.delivery.total_price}</TableCell>
                                        </TableRow>
                                    }

                                   {/* {
                                        items && items.total &&
                                        <TableRow
                                            key={'helping'}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row"><b>{items.total.name}</b></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{items.total.price}</TableCell>
                                        </TableRow>
                                    }*/}

                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
            </ListItem>
                ))
            }

            <AnalysisDialog
                idSmrGroup={idSmrGroup}
                open={analysisDialogOpen}
                workId={workId}
                handleClose={() => setAnalysisDialogOpen(false)}/>

            {/*
            workName, setWorkName] = useState(null);
    const [workCode, setWorkCode] = useState(null);
    const [workPercWork, setwWrkPercWork] = useState(null);
    const [workPercMaterial, setWorkPercMaterial] = useState(null);
    const [workPercMech, setWorkPercMech
            */}
            <ObjectDataDialog
                name={workName}
                setName={setWorkName}

                workCode={workCode}
                setWorkCode={setWorkCode}

                workPercWork={workPercWork}
                setWorkPercWork={setWorkPercWork}

                workPercMaterial={workPercMaterial}
                setWorkPercMaterial={setWorkPercMaterial}

                workPercMech={workPercMech}
                setWorkPercMech={setWorkPercMech}

                open={createEditWorkDialogOpen}
                handleClose={() => {
                    setCreateEditWorkDialogOpen(false)
                }}/>
        </List>
    )
}
export default SicComponent;