import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelFiveAvansDeliveryDocs({levelTwo, setLevelTwo, setLevelFour, color}) {

    return (
        <Grid container>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelTwo === 0 ? color : null}}
                       onClick={() => {
                           setLevelTwo(0)
                           setLevelFour(null)
                       }}>
                    <Typography>Аванс</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelTwo === 1 ? color : null}}
                       onClick={() => {
                           setLevelTwo(1)
                           setLevelFour(null)
                       }}>
                    <Typography>Доставка</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelTwo === 2 ? color : null}}
                       onClick={() => {
                           setLevelTwo(2)
                           setLevelFour(null)
                       }}>
                    <Typography>Документи</Typography>
                </Paper>
            </Grid>

        </Grid>
    )
}

export default LevelFiveAvansDeliveryDocs;