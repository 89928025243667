import {TableCell, TableRow} from "@mui/material";
import * as React from "react";
import ChangePriceDialog from "./ChangePriceDialog";
import {changePercentWork} from "../../utils/NetworkUtils";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";

export default function TempAdditionalCell({filter, additionalCost, totalItems, serviceAdditionalCostPerc, setForceRefresh, id}) {
    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [additionalCostDialogEdit, setAdditionalCostDialogEdit] = useState(null);

    const [changeAdditionalCostDialog, setChangeAdditionalCostDialog] = useState(false);

    return (
        <React.Fragment>

            <TableRow
                id={'add-temp-cell'}
                key={'add-temp-cell'}>
                <TableCell component="th" scope="row" style={style.tableCell}>
                    <b>Допълнителни разходи Услуги</b>
                </TableCell>
                <TableCell align="right" style={style.tableCell}>%</TableCell>
                <TableCell align="right" style={style.tableCell} onClick={() => {
                    setChangeAdditionalCostDialog(true)
                }}>{serviceAdditionalCostPerc}%</TableCell>
                {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                <TableCell align="right" style={style.tableCell}>{totalItems ? (totalItems).toFixed(2) : null }</TableCell>
                <TableCell align="right" style={style.tableCell}>{additionalCost ? additionalCost.toFixed(2) : null}
                </TableCell>
            </TableRow>


            <ChangePriceDialog
                open={changeAdditionalCostDialog}
                handleClose={() => setChangeAdditionalCostDialog(false)}
                label={'Допълнителни разходи'}
                item={additionalCostDialogEdit}
                setItem={setAdditionalCostDialogEdit}
                onClick={() => {
                    changePercentWork(id, 10, Number(additionalCostDialogEdit), navigate, accessToken, setAccessToken)
                    setChangeAdditionalCostDialog(false)
                    setForceRefresh(Math.random())

                }}/>
        </React.Fragment>

    )
}