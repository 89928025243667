import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import TextField from "@mui/material/TextField";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import Button from "@mui/material/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ChangePriceDialog({open, handleClose,
                              item, setItem,
                                onClick,
    label
}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    return  (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Промени
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container padding={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="price"
                        value={item}
                        onChange={(event) =>  {
                            setItem(event.target.value)
                        }}
                        label={label}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        margin="dense"
                        variant={'contained'}
                        id="done"
                        onClick={(event) =>  {
                            onClick(event)
                        }}
                    >
                        Запази
                    </Button>
                </Grid>

            </Grid>


        </Dialog>
    )
}
export default ChangePriceDialog;