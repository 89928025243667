import ListItem from "@mui/material/ListItem";
import {Button, Collapse, ListItemButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import List from "@mui/material/List";
import LevelDocumentsForRef from "./levels/LevelDocumentsForRef";
import * as React from "react";
import {useLocalStorage} from "../useLocalStorage";
import AttachmentDialogComponent from "./AttachmentDialogComponent";
import Grid from "@mui/material/Grid";
import {setDocState} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";

function OfferContractCard({data, partner, viewFile, setRandomToRefresh, setViewFile, setAttachmentId, department, open, items, openId, setOpenId}) {
    let navigate = useNavigate();

    function rowIs(row, name) {
        return row['path='].endsWith(name)
    }

    function rowHas(row, name) {
        return !!row[name];
    }

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    function upload(event, path) {
        event.stopPropagation();

        console.log(event)
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            // Create a new array from the current files
            setDocState({id: department}, navigate, accessToken, setAccessToken, path,
                event.target.files[0],
                "scanned").then(r => setRandomToRefresh(Math.random()))
        }
    }

    return (
        <div>
            {
               data.map((item) => {
                    return <Grid container alignItems={'flex-end'} spacing={1} style={{margin: 2, border: '0.4px solid #b2b2b2'}}>
                        <Grid item xs={4} style={{marginBottom: 10}}>
                            {item.name}
                        </Grid>
                        <Grid item xs={8}>
                            <Table sx={{ pl: 1 }} size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Физ</TableCell>
                                        <TableCell>Скан</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow>
                                        <TableCell>
                                            <Button color={item.physical && item.physical.remark ? "success" : 'error' }
                                                    onClick={() => {
                                                        if (item.physical && item.physical.remark) {

                                                        }
                                                    }}>{item.physical && item.physical.remark ? "Да" : "Не"}</Button>
                                        </TableCell>

                                        <TableCell>
                                            <Button color={item.scanned && item.scanned.id ? "success" : 'error' }
                                                    component={!(item.scanned && item.scanned.id) ? "label" : null}
                                                    onClick={() => {
                                                        if (item.scanned && item.scanned.id) {
                                                            const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${item.scanned.id}&token=${accessToken}`
                                                            setViewFile(url);
                                                            setAttachmentId(item.scanned.id)
                                                        }
                                                    }}>{item.scanned && item.scanned.id ? "Виж" : "Качи"}
                                                {
                                                !(item.scanned && item.scanned.id) && <input
                                                    type="file"
                                                    onChange={(event => {
                                                        console.log(item)
                                                        upload(event, item.path)
                                                    })}
                                                    hidden
                                                /> }</Button>
                                            {(false && item.scanned && item.scanned.id) && <Button
                                                component={"label"}>Качи
                                                <input
                                                    type="file"
                                                    onChange={(event => {
                                                        upload(event, item.path)
                                                    })}
                                                    hidden
                                                />
                                            </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                })

            }

        </div>
    )
}
export default OfferContractCard;
