import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import {useLocation, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {useLocalStorage} from "./useLocalStorage";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ObjectDataDialog from "./components/ObjectDataDialog";
import CalculationsDialog from "./components/CalculationsDialog";
import CalculateIcon from '@mui/icons-material/Calculate';
import SearchIcon from '@mui/icons-material/Search';
import SicDialog from "./components/sic/SicDialog";
import WorkTypeDialog from "./components/workType/WorkTypeDialog";
import EngineeringIcon from '@mui/icons-material/Engineering';
import FromScratchAnalysisDialog from "./components/analysis/FromScratchAnalysis/AnalysisDialog";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

function RightOptionsSideMenu(props) {
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();
    const location = useLocation()

    const [objectSettings, setObjectSettings] = React.useState(null);
    const [calculationsSettings, setCalculationsSettings] = React.useState(null);
    //const [analysisDialogOpen, setAnalysisDialogOpen] = React.useState(null);
    const [workTypeDialogOpen, setWorkTypeDialogOpen] = React.useState(null);
    const [emptyAnalysisDialog, setEmptyAnalysisDialog] = React.useState(null);

    const list = (anchor) => (
        <Box
            style={{paddingLeft: 20}}
            sx={{ right: 20 }}
            role="presentation">
            <List>

                <ListItemButton onClick={() => {
                    setObjectSettings(true)
                }}>
                    <ListItemIcon >
                        <CorporateFareIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Данни за обект'} />
                </ListItemButton>


                <ListItemButton onClick={() => {
                    setCalculationsSettings(true)
                }}>
                    <ListItemIcon >
                        <CalculateIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Начисления'} />
                </ListItemButton>


              {/*  <ListItemButton onClick={() => {
                    setAnalysisDialogOpen(true)
                }}>
                    <ListItemIcon >
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={'СЕК'} />
                </ListItemButton>*/}



                <ListItemButton onClick={() => {
                    setWorkTypeDialogOpen(true)
                }}>
                    <ListItemIcon >
                        <EngineeringIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Видове работа'} />
                </ListItemButton>


                <ListItemButton onClick={() => {
                    setEmptyAnalysisDialog(true)
                }}>
                    <ListItemIcon >
                        <ManageSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Празен анализ'} />
                </ListItemButton>

            </List>
        </Box>
    );

    return (
        <React.Fragment key={'right'}>
            <Drawer
                anchor={'right'}
                open={props.isOpen}
                onClose={() => {
                    props.setOpen(false)
                }}
            >
                {list('right')}
            </Drawer>

            <ObjectDataDialog
                open={objectSettings}
                handleClose={() => setObjectSettings(false)}/>

            <CalculationsDialog
                open={calculationsSettings}
                handleClose={() => setCalculationsSettings(false)}/>

           {/* <SicDialog
                open={analysisDialogOpen}
                handleClose={() => setAnalysisDialogOpen(false)}/>
*/}
            <WorkTypeDialog
                open={workTypeDialogOpen}
                handleClose={() => setWorkTypeDialogOpen(false)}/>

            <FromScratchAnalysisDialog
                open={emptyAnalysisDialog}
                handleClose={() => setEmptyAnalysisDialog(false)}/>
        </React.Fragment>
    );
}

export default RightOptionsSideMenu;
