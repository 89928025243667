import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {ListItemButton} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Group} from "@mui/icons-material";
import moment from "moment";
import bg from 'moment/locale/bg'
import {getFile} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import PDFRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/pdf";
import PNGRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/png";
import DocViewer from "@cyntler/react-doc-viewer";
import JPGRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/gif";
import MSDocRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/msdoc";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ObjectDataDialog({open, handleClose, onClick, setObjectSettings,
                              setSelectedObjectForOffer, setSelectedObjectForOfferName}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [client, setClient] = useState(null);
    const [orderer, setOrderer] = useState(null);
    const [objectName, setObjectName] = useState(null);
    const [address, setAddress] = useState(null);
    const [description, setDescription] = useState(null);
    const [responsible, setResponsible] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [area, setArea] = useState(null);
    const [workingDays, setWorkingDays] = useState(null);

    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Данни за обект
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container padding={3}>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="orderer"
                        value={orderer}
                        onChange={(event) =>  {}
                        }
                        label="Възложител"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="objectName"
                        value={objectName}
                        onChange={(event) =>  {
                            setObjectName(event.target.value)
                        }}
                        label="Име на обект"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="address"
                        value={address}
                        onChange={(event) =>  {}
                        }
                        label="Адрес"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="description"
                        value={description}
                        onChange={(event) =>  {}
                        }
                        label="Описание"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="responsible"
                        value={responsible}
                        onChange={(event) =>  {}
                        }
                        label="Отговорник"
                    />
                </Grid>
                <Grid item xs={5}>
                </Grid>




                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="from"
                        value={fromDate}
                        onChange={(event) =>  {}
                        }
                        label="От"
                    />
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="to"
                        value={toDate}
                        onChange={(event) =>  {}
                        }
                        label="До"
                    />
                </Grid>





                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="area"
                        value={area}
                        onChange={(event) =>  {}
                        }
                        label="Площ (кв. м.)"
                    />
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="workingDays"
                        value={workingDays}
                        onChange={(event) =>  {}
                        }
                        label="Работни дни"
                    />
                </Grid>

                <Grid item xs={12}/>

                <Grid item xs={2.75}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        id={"save"}
                        onClick={(event) =>  {
                            if (onClick) {
                                //likely addObject
                                onClick(navigate, accessToken, setAccessToken,
                                    objectName, objectName.substring(0, 2))
                                    .then((result) => {
                                        setSelectedObjectForOffer(result.id)
                                        setSelectedObjectForOfferName(objectName)
                                        setObjectSettings(false)
                                        handleClose()
                                    })
                            }
                        }}
                    >Запис</Button>
                </Grid>
            </Grid>


        </Dialog>
    )
}
export default ObjectDataDialog;