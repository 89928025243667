import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

function FirmSelectComponent({selectedFirm, setSelectedFirm, firms, setSelectedObject, setOffersMode, resetProps}) {
    return (
        <FormControl style={{minWidth: 100, maxWidth: 350, marginLeft: 20}}>
            <InputLabel htmlFor="firms-select"  id="firms-label">Фирма</InputLabel>
            <Select
                labelId="firms-label"
                id="firms-select"
                focused={selectedFirm}
                value={selectedFirm ? selectedFirm : 1}
                label="Фирма"
                onChange={(event) => {
                    setSelectedFirm(event.target.value)
                }}
            >
                {
                    firms && firms.map((firm) => {
                        return <MenuItem
                            onClick={() => {
                                console.log('firm selected')
                                setSelectedObject(0)
                                setOffersMode(false)
                                resetProps()
                            }}
                            value={firm.id}>{firm.name}</MenuItem>
                    })
                }
            </Select>
        </FormControl>

    )
}
export default FirmSelectComponent;