import List from "@mui/material/List";
import {Button, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import Grid from "@mui/material/Grid";
import AddEditWorkDialog from "./AddEditWorkDialog";
import {importWorkingGroupsFromFile, setDocState} from "../../utils/NetworkUtils";
import {ExcelRenderer} from 'react-excel-renderer';
import KssDialog from "../kss/KssDialog";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import '@nosferatu500/react-sortable-tree/style.css';
import { DndProvider, DndContext } from 'react-dnd'
import DeleteIcon from '@mui/icons-material/Delete';

// You can import the default tree with dnd context
import SortableTree, {
    addNodeUnderParent,
    changeNodeAtPath,
    removeNode,
    removeNodeAtPath
} from '@nosferatu500/react-sortable-tree';
import { HTML5Backend } from 'react-dnd-html5-backend'
import AddItemDialog from "./AddItemDialog";


function DescriptionWorkComponent({items, setItems, kssDialogOpen, setKssDialogOpen, selectedObject}) {
    let navigate = useNavigate();
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const getNodeKey = ({ treeIndex }) => treeIndex

    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };


    const [name, setName] = useState( null);
    const [entity, setEntity] = useState( null);
    const [quantity, setQuantity] = useState( null);

    const [addWorkOpen, setAddWorkOpen] = useState(false);


    const [newItemName, setNewItemName] = useState(null);
    const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);

    const TEAM_COLORS = ['Red', 'Black', 'Green', 'Blue']

    function isLetter(char) {
        return ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'Й', 'К',
            'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц',
            'Ч', 'Ш', 'Щ', 'Ь', 'Ъ', 'Ю', 'Я'
        ].includes(char);
    }

    function nthSetElement(array, index) {
        let setElement = 0;
        for (const arrayElement of array) {
            if (arrayElement) {
                if (index === setElement) {
                    return arrayElement;
                }

                setElement++;
            }
        }
        return null;
    }

    function parseExcel(response) {
        let groupId = 1;
        const newItems = [];
        if (response.rows) {
            let resp = response.rows;

            for (let i = 0; i < resp.length; i++) {
                const respI = resp[i];

                if (respI && respI.length > 0 && isLetter(nthSetElement(respI, 0)) && nthSetElement(respI, 1)) {
                    const etap = {name: nthSetElement(respI, 1), sub: []}

                    for (let j = i + 1; j < resp.length; j++) {
                        i++;

                        const respJ = resp[j];
                        if (respJ && respJ.length > 0 && !isNaN(nthSetElement(respJ, 0)) && nthSetElement(respJ, 1)) {
                            const group = {id: groupId++, name: nthSetElement(respJ, 1), works: []}

                            for (let k = j + 1; i < resp.length; k++) {
                                i++;
                                j++;

                                const respK = resp[k];
                                if (respK && isNaN(nthSetElement(respK, 0)) && !isLetter(nthSetElement(respK, 0))) {
                                    group.works.push({
                                        id: Math.floor(Math.random()*1000),
                                        name: nthSetElement(respK, 0),
                                        entity: nthSetElement(respK, 1),
                                        quantity: nthSetElement(respK, 2)
                                    })
                                } else {
                                    i--;
                                    j--;
                                    break;
                                }

                            }
                            etap.sub.push(group)

                        }
                        else {
                            i--;
                            break;
                        }
                    }
                    newItems.push(etap)
                }
            }

            setItems(newItems)
            importWorkingGroupsFromFile({id: selectedObject}, newItems, navigate, accessToken, setAccessToken)

        }

    }
    function parseExcelBasic(response) {
        const newItems = [];
        if (response.rows) {
            for (let i = 0; i < response.rows.length; i++) {

                newItems.push({
                    id: Math.floor(Math.random() * 1000),
                    name: nthSetElement(response.rows[i], 0),
                    title: nthSetElement(response.rows[i], 0),
                    entity: nthSetElement(response.rows[i], 1),
                    quantity: nthSetElement(response.rows[i], 2),
                    children: []
                })
            }
        }


        setItems(newItems)
        console.log('newItems ', newItems)
        //importWorkingGroupsFromFile({id: selectedObject}, newItems, navigate, accessToken, setAccessToken)

    }

    return (
        <React.Fragment>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Button
                        style={{width: 250, margin: 10}}
                        variant={'contained'}
                        component="label">
                        Импортирай от Excel
                        <input
                            type="file"
                            onChange={(event => {
                                event.stopPropagation();

                                console.log('start reading')

                                if (event && event.target && event.target.files && event.target.files.length > 0) {
                                    let fileObj = event.target.files[0];

                                    //just pass the fileObj as parameter
                                    ExcelRenderer(fileObj, (err, resp) => {
                                        if(err){
                                            console.log(err);
                                        }
                                        else{
                                            console.log(resp)
                                            const filteredResp = resp.rows.filter(value => Object.keys(value).length !== 0);
                                            parseExcelBasic({rows: filteredResp})
                                            console.log('filteredResp ', filteredResp)

                                            event.target.value = '';
                                        }
                                    });
                                }
                            })}
                            hidden
                        />
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        style={{width: 250, margin: 10}}
                        variant={'contained'}
                        onClick={() => {
                            setAddItemDialogOpen(true)
                        }}>
                        Добави
                    </Button>
                </Grid>

            </Grid>

            <div style={{height:500}}>
                <DndProvider backend={HTML5Backend}>

                    <SortableTree
                        treeData={items}
                        onChange={treeData => setItems(treeData)}
                        maxDepth={3}
                        getNodeKey={getNodeKey}
                        generateNodeProps={({ node, path }) => {
                            const rootLevelIndex =
                                items.reduce((acc, n, index) => {
                                    if (acc !== null) {
                                        return acc
                                    }
                                    if (path[0] === n.id) {
                                        return index
                                    }
                                    return null
                                }, null) || 0
                            const playerColor = TEAM_COLORS[rootLevelIndex] || ""

                            return {
                                style: {
                                    background: `${playerColor.toLowerCase()}`,
                                },
                                title: <Grid container style={{width: 600}} justifyContent={'space-between'} spacing={2}>
                                    <Grid item xs={9}>
                                        <p style={{maxWidth: 450, textOverflow: 'ellipsis', overflow: 'hidden'}}>{node.title}</p>
                                    </Grid>

                                    <Grid item xs={'auto'}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={'auto'}>
                                                <Button style={{marginTop: 10}} size={'small'} variant={'contained'}
                                                onClick={() => {
                                                    setItems(removeNodeAtPath({
                                                        treeData: items,
                                                        path,
                                                        getNodeKey,
                                                    }))
                                                }}>
                                                    <DeleteIcon/>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={'auto'}>
                                                <Typography style={{marginTop: 5}} variant={'subtitle2'}>{
                                                    path.length === 1 ? ' Етап' :
                                                        path.length === 2 ? ' Група' :
                                                            path.length === 3 ? ' Работа' : ''
                                                }</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                ,
                                onClick: () => {

                                },
                            }
                        }}
                    />

                </DndProvider>

            </div>




            <KssDialog
                selectedObject={selectedObject}
                open={kssDialogOpen}
                handleClose={() => {
                    setKssDialogOpen(false)
                }}
                items={items}
                setItems={setItems}/>

            <AddItemDialog
                open={addItemDialogOpen}
                name={newItemName}
                setName={setNewItemName}
                onClick={() => {
                    const newItems = items;
                    newItems.unshift({
                        name: newItemName,
                        title: newItemName,
                        children: []
                    })
                    setItems(newItems)
                    setNewItemName(null)
                    setAddItemDialogOpen(false)
                }}
                handleClose={() => {
                    setAddItemDialogOpen(false)
                }}
            />
        </React.Fragment>
    )
}
export default DescriptionWorkComponent;