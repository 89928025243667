import {Grid} from "@mui/material";
import LevelTwoInvestorDeliver from "../../../levels/LevelTwoInvestorDeliver";
import React from "react";
import LevelFiveAvansAktDocs from "../../../levels/levelFive/LevelFiveAvansAktDocs";
import LevelFiveAvansAkt from "../../../levels/levelFive/LevelFiveAvansAkt";
import LevelFiveAkt from "../../../levels/levelFive/LevelFiveAkt";
import LevelDocuments from "../../../levels/LevelDocuments";

function TrackThreeInvestorAkt({
                       actNumber, selectedObject,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup,
                      stage, setStage,
                      group, setGroup,
                      service, levelDeliver, deliveryNum,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                      color, SERVICE, MATERIAL
                  }) {

    function computeDocName() {
        let levelFourText;
        switch (levelFour) {
            case 0:
                levelFourText = 'kss';
                break;
            case 1:
                levelFourText = 'act';
                break;
            case 2:
                levelFourText = 'invoice';
                break;
            case 3:
                levelFourText = 'payment';
                break;
        }
        const docNumber = actNumber ? actNumber.doc_no : 0;

        return `performance-act-${docNumber}-${levelFourText}`;
    }

    return (
        <Grid container>


            <Grid item xs={4} sm={6}>
                <LevelFiveAkt
                    levelFour={levelFour}
                    setLevelFour={setLevelFour}
                    color={color}
                />
            </Grid>


            {/* ----------- 5.-----------*/}
            {
                (levelFour !== null) ?
                    <Grid item xs={5} sm={6}>
                        {/*
                                Физически, Електронен, Сканиран
*/}
                        <LevelDocuments
                            selectedObject={selectedObject}
                            docName={computeDocName()}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}/>
                    </Grid>
                    :
                    null
            }


        </Grid>
    )
}
export default TrackThreeInvestorAkt;