import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelFourOffers({levelFour, setLevelFour, color, setDocuments}) {

    return (
        <Grid xs={7} container justifyContent={'space-between'} direction="column">

            <Grid item xs={7}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 0 ? color : null}}
                       onClick={() => {
                           setLevelFour(0)
                           setDocuments(true)
                       }}>
                    <Typography>КСС</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 1 ? color : null}}
                       onClick={() => {
                           setLevelFour(1)
                           setDocuments(true)
                       }}>
                    <Typography>График</Typography>
                </Paper>
            </Grid>

        </Grid>

    );
}
export default LevelFourOffers;