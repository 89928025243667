import {Button, TableCell, TableRow} from "@mui/material";
import Grid from "@mui/material/Grid";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import * as React from "react";

function EmptyGroupRow({group, setAddWorkOpen, style, setWorkName, setWorkEntity, setWorkQuantity}) {
    return (
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell width="10%" component="th" scope="row" style={style.tableCell}>
               <Button onClick={() => {
                   setWorkName(null)
                   setWorkEntity(null)
                   setWorkQuantity(null)
                   setAddWorkOpen(group.id)
                }}
                                        style={{padding: 5, marginLeft: 20}}>
                    <ControlPointIcon fontSize={'medium'} sx={{color: 'black'}}/>
                </Button>

            </TableCell>
            <TableCell width="45%" style={style.tableCell} onClick={() => {

            }}></TableCell>
            <TableCell width="7%" align="right" style={style.tableCell}></TableCell>
            <TableCell width="10%" align="right" style={style.tableCell}></TableCell>
            <TableCell width="10%" align="right" style={style.tableCell}></TableCell>
            <TableCell width="15%" align="right" style={style.tableCell}></TableCell>
        </TableRow>
    )
}
export default EmptyGroupRow;