import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import KssComponent from "./KssComponent";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {getKss, getWorkingGroups} from "../../utils/NetworkUtils";
import {useLocalStorage} from "../../useLocalStorage";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function KssDialog({open, handleClose, selectedObject}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    moment.locale("bg"); // it is required to select default locale manually

    const [items, setItems] = useState();
    const [emptyAnalysisDialog, setEmptyAnalysisDialog] = useState(false);
    const [grandTotal, setGrandTotal] = useState(0);

    useEffect(() => {
        getKss({id: selectedObject}, navigate, accessToken, setAccessToken)
            .then((result) => {
                if (result.grand_total) {
                    setGrandTotal(result.grand_total)
                }
                if (result && result.items) {
                    for (const resultElement of result.items) {
                        resultElement.name = resultElement.name_group;
                        resultElement.sub = resultElement.subgroups;
                        for (const resultElementElement of resultElement.sub) {
                            resultElementElement.name = resultElementElement.name_group;
                            for (const work of resultElementElement.works) {
                                work.name = work.name_work;
                                work.entity = work.edm;
                                work.quantity = work.qty;
                            }
                        }
                    }
                    setItems(result.items)
                }


            })
            .catch((error) => {

            })
    }, [open, emptyAnalysisDialog, selectedObject]);

    return  (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            maxWidth={'md'}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        КСС на подлежащи за изпълнение работи
                    </Typography>

                    <IconButton edge={"end"}>

                            <Button variant={'contained'}>Създай оферта</Button>
                    </IconButton>

                </Toolbar>
            </AppBar>

            <KssComponent
                selectedObject={selectedObject}
                items={items}
                setItems={setItems}
                grandTotal={grandTotal}
                emptyAnalysisDialog={emptyAnalysisDialog}
                setEmptyAnalysisDialog={setEmptyAnalysisDialog}/>

        </Dialog>
    )
}
export default KssDialog;