import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

function ConfirmationDialog({open, handleClose, title, dialogText,
                            positiveText, positiveAction,
                            negativeText}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
        >
            <DialogTitle id="confirmation-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirmation-dialog-description">
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{negativeText}</Button>
                <Button onClick={() => {
                    positiveAction();
                }} autoFocus>
                    {positiveText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;