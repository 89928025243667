import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelTwoInvestorDeliver({levelTwo, levelOneTwo,
                                     setLevelStageGroup, setLevelTwo, setLevelThree,
                                     setLevelFour, setLevelOneTwo,setServiceMaterial,
                                     setLevelDeliver,
                                     color
}) {
    function resetProps() {
        setLevelOneTwo(null)
        setLevelTwo(null)
        setLevelThree(null)
        setLevelFour(null)
        setServiceMaterial(null)
        setLevelDeliver(null)
    }

    return (
        <Grid xs={8} container justifyContent={'space-between'} direction="column">
            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       onClick={() => {
                           resetProps()
                           setLevelOneTwo(0)
                       }}
                       style={{background: levelOneTwo === 0 ? color : null}}>
                    <Typography>Инвеститор</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       onClick={() => {
                           resetProps()
                           setLevelOneTwo(1)
                       }}
                       style={{background: levelOneTwo === 1 ? color : null}}>
                    <Typography>Доставчик</Typography>
                </Paper>
            </Grid>

        </Grid>

    )
}

export default LevelTwoInvestorDeliver;