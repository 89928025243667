import Grid from "@mui/material/Grid";
import LevelThreeMost from "../../levels/levelThree/LevelThreeMost";
import LevelFourOffers from "../../levels/levelFour/LevelFourOffers";
import LevelDocuments from "../../levels/LevelDocuments";
import React from "react";
import LevelThreeMostInvestor from "../../levels/levelThree/LevelThreeMostInvestor";
import LevelFourOffersInvestor from "../../levels/levelFour/LevelFourOffersInvestor";

function TrackOneInvestor({
                              selectedObject,
                      levelOneTwo,levelThree, levelFour,
                      setLevelThree, setLevelFour,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                      color
                  }) {

    function computeDocName() {
        let levelFourText;
        switch (levelFour) {
            case -1:
                levelFourText = 'workdescript';
                break;
            case 0:
                levelFourText = 'kss';
                break;
            case 1:
                levelFourText = 'graphic';
                break;
        }

        return `offer-${levelFourText}`;
    }

    return (
        <Grid container>
            {
                /* ----------- 3.-----------*/
            }
            {
                (levelOneTwo !== null) ?
                    <Grid item xs={5} sm={2}>
{/*
            Опис работи, КСС, График, Документи
*/}
                        <LevelThreeMostInvestor
                            selectedObject={selectedObject}
                            setLevelThree={setLevelThree}
                            levelThree={levelThree}
                            color={color}/>
                    </Grid>
                    :
                    null
            }


            {
                /* ----------- 4.-----------*/
            }
            { levelThree === 2 ?
                <Grid item xs={5} sm={1.5}>
                    {/*
                        Опис работи, КСС, График
*/}
                    <LevelFourOffersInvestor
                        levelFour={levelFour}
                        setLevelFour={setLevelFour}
                        color={color}
                        setDocuments={setDocuments}/>
                </Grid>
                :
                null
            }


            {/* ----------- 5.-----------*/}
            {
                (levelFour !== null && isDocuments) ?
                    <Grid item xs={5} sm={1}>
{/*
                                Физически, Електронен, Сканиран
*/}
                        <LevelDocuments
                            selectedObject={selectedObject}
                            docName={computeDocName()}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}/>
                    </Grid>
                    :
                    null
            }
        </Grid>
    );


}
export default TrackOneInvestor;