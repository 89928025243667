import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {AutocompleteProps} from "../../../inputs/AutocompleteProps";
import {getDocNums} from "../../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";

function LevelFiveAvansAktDocs({
                                   selectedObject, actPath,
                                   levelThree, setLevelThree, setLevelFour, act, setAct, color}) {

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [acts, setActs] = useState([]);

    useEffect(() => {
        getDocNums({id: selectedObject}, navigate, accessToken, setAccessToken, actPath)
            .then(result => {
                const deliveries = result.items;
                deliveries.forEach((element, index) => {
                    const newElement = element;
                    newElement.doc_no = newElement.doc_no+"";
                    deliveries[index] = newElement;
                });

                setActs(deliveries)
            })
    }, [actPath])

    return (
        <Grid container>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 0 ? color : null}}
                       onClick={() => {
                           setLevelThree(0)
                           setLevelFour(null)
                       }}>
                    <Typography>Аванс</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>

                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 1 ? color : null, height: "auto", minHeight: 80}}
                       onClick={() => {
                           setLevelThree(1)
                           setLevelFour(null)
                       }}>
                    <Grid container style={{padding: 15}}>

                        <Grid item xs={12}>
                            <Typography>Актуване</Typography>
                        </Grid>

                        {
                            levelThree === 1 &&
                            <Grid item xs={12}>

                                <AutocompleteProps
                                    autocompleteProperty={act}
                                    dataset={acts}
                                    setAutocompleteProperty={setAct}
                                    restApiAddPropsFunction={(name) => {
                                    }}
                                    title={"Акт"}
                                    propName={"doc_no"}
                                />
                            </Grid>
                        }
                    </Grid>

                </Paper>


            </Grid>



        </Grid>
    )
}

export default LevelFiveAvansAktDocs;