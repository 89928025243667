import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

function ObjectSelectComponent(props) {
    return (
        <FormControl style={{maxWidth: 350, marginLeft: 20}}>
            <InputLabel htmlFor="objects-select" shrink={props.selectedObject !== null && props.selectedObject !== ""} id="objects-label">{props.label}</InputLabel>
            <Select
                labelId="objects-label"
                id="objects-select"
                focused={props.selectedObject}
                value={props.selectedObject ? props.selectedObject : 0}
                label={props.label}
                onChange={(event) => {
                    props.setSelectedObject(event.target.value)
                    props.setOffersMode(0)
                }}
            >
                <MenuItem disabled value={0}>
                    <em>Избери</em>
                </MenuItem>
                {
                    props.objects && props.objects.map((object) => {
                        return <MenuItem value={object.id}>{object.name_project}</MenuItem>
                    })
                }
            </Select>
        </FormControl>

    )
}
export default ObjectSelectComponent;