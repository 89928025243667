import Grid from "@mui/material/Grid";
import LevelThreeMost from "../../levels/levelThree/LevelThreeMost";
import LevelFourOffers from "../../levels/levelFour/LevelFourOffers";
import LevelDocuments from "../../levels/LevelDocuments";
import React from "react";
import LevelThree from "../../levels/levelThree/LevelThree";
import LevelThreeZeroOneOfferInvector from "../../levels/LevelThreeZeroOne/LevelThreeZeroOneOfferInvestor";

function TrackTwoInvestor({
                              selectedObject,
                              levelOneTwo,levelThree, levelFour,
                              setLevelThree, setLevelFour,
                              isDocuments, setDocuments,
                              isScanned, setScanned,
                              isElectronic, setElectronic,
                              isPhysical, setPhysical,
                              color
                          }) {

    function computeDocName() {
        let levelFourText;
        switch (isDocuments) {
            case 0:
                levelFourText = 'contract';
                break;
            case 1:
                levelFourText = 'kss';
                break;
            case 2:
                levelFourText = 'graphic';
                break;
            case 3:
                levelFourText = 'paysgraphict';
                break;
            case 4:
                levelFourText = 'agreement';
                break;
            case 5:
                levelFourText = 'annex';
                break;
        }

        return `contract-${levelFourText}`;
    }

    return (
        <Grid container>
            {
                /* ----------- 3.-----------*/
            }


            <Grid item xs={5} sm={2}>
            {
                /*
                Договор, КСС, График, График плащания, Споразумение ЗБУТ, Анекс, Документи
                */
            }
                <LevelThree
                    levelFour={levelFour}
                    setLevelFour={setLevelFour}
                    setDocuments={setDocuments}
                    color={color}/>
            </Grid>



            {
                /* ----------- 4.-----------*/
            }
            {
                /*
                Договор, КСС, График, График плащания, Споразумение ЗБУТ, Анекс
                */
            }
            {
                levelFour === 6 &&
                <Grid item xs={5} sm={2}>
                    <LevelThreeZeroOneOfferInvector
                        levelFourDocuments={isDocuments}
                        setLevelFourDocuments={setDocuments}
                        color={color}/>
                </Grid>
            }



            {/* ----------- 5.-----------*/}
            {
                (levelFour === 6 && isDocuments !== null) ?
                    <Grid item xs={5} sm={1}>
                        {/*
                                Физически, Електронен, Сканиран
*/}
                        <LevelDocuments
                            selectedObject={selectedObject}
                            docName={computeDocName()}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}/>
                    </Grid>
                    :
                    null
            }
        </Grid>
    );


}
export default TrackTwoInvestor;