import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelFiveAvans({levelFour, setLevelFour, isCertificateEnabled, color}) {

    return (
        <Grid container>

            <Grid item xs={10}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 0 ? color : null}}
                       onClick={() => {
                           setLevelFour(0)
                       }}>
                    <Typography>Фактура</Typography>
                </Paper>
            </Grid>


            <Grid item xs={10}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 1 ? color : null}}
                       onClick={() => {
                           setLevelFour(1)
                       }}>
                    <Typography>Платежно</Typography>
                </Paper>
            </Grid>

            {
                isCertificateEnabled &&
                <Grid item xs={10}>
                    <Paper elevation={6} className={"paper-style"}
                           style={{background: levelFour === 2 ? color : null}}
                           onClick={() => {
                               setLevelFour(2)
                           }}>
                        <Typography>Сертификат</Typography>
                    </Paper>
                </Grid>
            }


        </Grid>
    )
}

export default LevelFiveAvans;