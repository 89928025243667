import Grid from "@mui/material/Grid";
import LevelThreeMost from "../../levels/levelThree/LevelThreeMost";
import LevelFourOffers from "../../levels/levelFour/LevelFourOffers";
import LevelDocuments from "../../levels/LevelDocuments";
import React from "react";
import LevelServiceMaterial from "../../levels/LevelServiceMaterial";
import LevelStageGroup from "../../levels/LevelStageGroup";
import LevelThreeMostOpis from "../../levels/levelThree/LevelThreeMostOpis";
import LevelFourOffersOpis from "../../levels/levelFour/LevelFourOffersOpis";
import LevelServiceMaterialAutocomplete from "../../levels/LevelServiceMaterialAutocomplete";
import TrackThreeDeliverMaterial from "../trackThree/deliver/TrackThreeDeliverMaterial";
import LevelDeliver from "../../levels/LevelDeliver";

function TrackOneDeliver({
                             levelDeliver, setLevelDeliver,
                             good, material, setMaterial,
                             selectedObject,
                             levelOneTwo,levelThree, levelFour, levelTwo,
                             setLevelThree, setLevelTwo, setLevelFour,
                             assignee, setAssignee,
                             stage, setStage,
                             group, setGroup,
                             serviceMaterial, setServiceMaterial,
                             levelStageGroup,
                             isDocuments, setDocuments,
                             isScanned, setScanned,
                             isElectronic, setElectronic,
                             isPhysical, setPhysical,
                             color, SERVICE, MATERIAL
                         }) {

    function computeDocName() {
        let levelFourText;
        switch (levelFour) {
            case 0:
                levelFourText = 'kss';
                break;
            case 1:
                levelFourText = 'graphic';
                break;
        }
        const stageId = stage ? stage.id : 0;
        const groupId = group ? group.id : 0;
        const goodId = serviceMaterial === MATERIAL ?
            material ? material.id : 0
            :
            good ? good.id : 0;

        const assigneeId = levelDeliver ? levelDeliver.id : 0;

        return `offer-${stageId}-${groupId}-${goodId}-${assigneeId}-${levelFourText}`;
    }

    return (
        <Grid container>

            {
                levelOneTwo &&
                <Grid item xs={4} sm={2}>
                    <LevelStageGroup
                        selectedObject={selectedObject}
                        levelStageGroup={levelStageGroup}
                        stage={stage}
                        setStage={setStage}
                        group={group}
                        setGroup={setGroup}
                        color={color}/>
                </Grid>
            }


            { levelOneTwo &&
                <Grid item xs={7} sm={3}>
                    <LevelServiceMaterialAutocomplete
                        stage={stage}
                        group={group}
                        selectedObject={selectedObject}
                        serviceMaterial={serviceMaterial}
                        levelTwo={levelTwo}
                        setLevelTwo={() => {}}
                        setAssignee={setAssignee}
                        setServiceMaterial={setServiceMaterial}
                        material={material}
                        setMaterial={setMaterial}
                        color={color}
                        SERVICE={SERVICE}
                        MATERIAL={MATERIAL}
                    />
                </Grid>
            }


            {
                serviceMaterial &&
                <Grid item xs={4} sm={2}>
                    <LevelDeliver
                        isService={serviceMaterial === SERVICE}
                        selectedObject={selectedObject}
                        levelDeliver={levelDeliver}
                        setLevelDeliver={setLevelDeliver}
                        stage={stage}
                        group={group}
                        good={material}
                        options={[{name: 'Радо'}, {name: 'Емо'}]}
                        color={color}/>
                </Grid>
            }

            {
                (levelDeliver) &&

            <Grid item xs={5} sm={2}>
{/*
            Опис работа, График, Документи
*/}
                <LevelThreeMostOpis
                    setLevelThree={setLevelThree}
                    levelThree={levelThree}
                    color={color}/>
            </Grid>

            }


            {
                levelThree===2 &&

            <Grid item xs={5} sm={2}>
{/*
            Опис работа, График
*/}
                <LevelFourOffersOpis
                    levelFour={levelFour}
                    setLevelFour={setLevelFour}
                    color={color}
                    setDocuments={setDocuments}/>
            </Grid>

            }



            {/* ----------- 5.-----------*/}
            {
                (levelFour !== null && isDocuments) ?
                    <Grid item xs={5} sm={1}>
{/*
                                Физически, Електронен, Сканиран
*/}
                        <LevelDocuments
                            selectedObject={selectedObject}
                            docName={computeDocName()}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}/>
                    </Grid>
                    :
                    null
            }

        </Grid>
    );


}
export default TrackOneDeliver;