import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {useLocalStorage} from "./useLocalStorage";
import SideMenu from "./SideMenu";
import RightOptionsSideMenu from "./RightOptionsSideMenu";
import {Tooltip} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SearchIcon from "@mui/icons-material/Search";
import ListItemText from "@mui/material/ListItemText";
import SicDialog from "./components/sic/SicDialog";

function AppBarComponent(props) {
    const [auth, setAuth] = React.useState(true);
    const [isOpen, setOpen] = React.useState(false);
    const [isSettingsOpen, setSettingsOpen] = React.useState(false);
    const [analysisDialogOpen, setAnalysisDialogOpen] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <SideMenu
                isOpen={isOpen}
                setOpen={setOpen}
                setView={props.setView}/>

            <RightOptionsSideMenu
                isOpen={isSettingsOpen}
                setOpen={setSettingsOpen}/>

            <Toolbar>
                { accessToken &&
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setOpen(true)
                        }}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                }
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Управление на проекти
                </Typography>

                { accessToken &&
                    <Tooltip title={"СЕК"}>
                        <IconButton onClick={() => {
                            setAnalysisDialogOpen(true)
                        }}>
                            <ListItemText style={{color: 'white'}} primary={'СЕК'} />
                        </IconButton>
                    </Tooltip>
                }

                { accessToken &&
                    <Tooltip title={"Още"}>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => {
                                setSettingsOpen(true)
                            }}
                            sx={{mr: 1}}
                        >
                            <SettingsIcon/>
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar>

            <SicDialog
                open={analysisDialogOpen}
                handleClose={() => setAnalysisDialogOpen(false)}/>

        </AppBar>
    );
}

export default AppBarComponent;
