import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import AppBarComponent from "../AppBarComponent";
import React, {useEffect, useState} from "react";
import {getObjects} from "../utils/NetworkUtils";
import ObjectSelectComponent from "../components/ObjectSelectComponent";
import Grid from "@mui/material/Grid";
import './HomeScreen.css'
import CategoryDialogComponent from "../components/CategoryDialogComponent";
import LevelOneOfferContractExecution from "../components/levels/LevelOneOfferContractExecution";
import LevelTwoInvestorDeliver from "../components/levels/LevelTwoInvestorDeliver";
import LevelStageGroup from "../components/levels/LevelStageGroup";
import LevelServiceMaterial from "../components/levels/LevelServiceMaterial";
import LevelThree from "../components/levels/levelThree/LevelThree";
import LevelThreeService from "../components/levels/levelThree/LevelThreeService";
import LevelThreeMaterial from "../components/levels/levelThree/LevelThreeMaterial";
import LevelThreeMost from "../components/levels/levelThree/LevelThreeMost";
import LevelThreeRest from "../components/levels/levelThree/LevelThreeRest";
import LevelThreeZeroOneOfferInvector from "../components/levels/LevelThreeZeroOne/LevelThreeZeroOneOfferInvestor";
import LevelThreeZeroOneService from "../components/levels/LevelThreeZeroOne/LevelThreeZeroOneService";
import LevelThreeZeroOneMaterial from "../components/levels/LevelThreeZeroOne/LevelThreeZeroOneMaterial";
import LevelFourOffers from "../components/levels/levelFour/LevelFourOffers";
import LevelFourRest from "../components/levels/levelFour/LevelFourRest";
import LevelDocuments from "../components/levels/LevelDocuments";
import FirmSelectComponent from "../components/FirmSelectComponent";
import TrackOne from "../components/tracks/trackOne/TrackOne";
import TrackTwo from "../components/tracks/trackTwo/TrackTwo";
import TrackThree from "../components/tracks/trackThree/TrackThree";
import Button from "@mui/material/Button";
import ReferenceComponent from "../components/ReferenceComponent";
import {AutocompleteProps} from "../inputs/AutocompleteProps";
import ObjectSelectForDialogComponent from "../components/ObjectSelectForDialogComponent";
import Typography from "@mui/material/Typography";
import ObjectDataDialog from "../components/ObjectDataDialog";

function HomeScreen() {
    const SERVICE = 'SERVICE';
    const MATERIAL = 'MATERIAL';
    const color = 'rgba(164,62,227,0.49)';

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [objects, setObjects] = React.useState([]);
    const [offerObject, setOfferObjects] = React.useState([{id: 1, name_project: 'Пепина'}]);

    const [selectedObject, setSelectedObject] = React.useState(0);
    const [selectedFirm, setSelectedFirm] = React.useState(null);

    const [stage, setStage] = React.useState(null);
    const [group, setGroup] = React.useState(null);
    const [levelDeliver, setLevelDeliver] = React.useState(null);

    const [hideBeforeDeliverer, setHideBeforeDeliverer] = React.useState(false);

    const [levelOne, setLevelOne] = React.useState(null);
    const [levelOneTwo, setLevelOneTwo] = React.useState(null);
    const [levelStageGroup, setLevelStageGroup] = React.useState(null);
    const [levelTwo, setLevelTwo] = React.useState(null);
    const [levelThree, setLevelThree] = React.useState(null);
    const [levelFour, setLevelFour] = React.useState(null);
    const [levelFourDocuments, setLevelFourDocuments] = React.useState(null);
    const [levelFourOne, setLevelFourOne] = React.useState(null); //optional
    const [act, setAct] = React.useState(null); //optional

    const [material, setMaterial] = React.useState(null); //optional
    const [service, setService] = React.useState(null); //optional


    // Fullscreen attachment dialog
    const [fullscreenDialogOpen, setFullscreenDialogOpen] = React.useState(null);
    const [categorySelected, setCategorySelected] = React.useState(null);
    const [objectNameSelected, setObjectNameSelected] = React.useState(null);

    // Reference
    const [referenceOpen, setReferenceOpen] = React.useState(null);

    const [serviceMaterial, setServiceMaterial] = React.useState(SERVICE);
    const [assignee, setAssignee] = React.useState(null);
    const [isDocuments, setDocuments] = React.useState(false);


    const [isPhysical, setPhysical] = React.useState(false);
    const [isElectronic, setElectronic] = React.useState(false);
    const [isScanned, setScanned] = React.useState(false);

    const [selectedObjectOffer, setSelectedObjectOffer] = React.useState(0);
    const [selectedObjectForOfferName, setSelectedObjectForOfferName] = React.useState(null);

    const [objectSettings, setObjectSettings] = React.useState(false);
    const [offersMode, setOffersMode] = React.useState(false);

    const openAttachmentDialog = (category) => {
        setCategorySelected(category)

        const foundObject = objects.find((object) => {
            return object.id === selectedObject
        })
        if (foundObject) {
            console.log(foundObject)
            setObjectNameSelected(foundObject.name_project)
        }

        setFullscreenDialogOpen(true)
    }

    function resetProps() {
        setLevelOne(null)
        setLevelOneTwo(null)
        setLevelStageGroup(null)
        setLevelTwo(null)
        setLevelThree(null)
        setLevelFour(null)
        setLevelFourDocuments(null)
        setLevelFourOne(null)
        setServiceMaterial(null)
        setAct(null)
        setLevelDeliver(null)
        setSelectedObjectOffer(0)
        setSelectedObjectForOfferName(null)
    }

    useEffect(() => {
        getObjects(navigate, accessToken, setAccessToken, setObjects, setSelectedObject)
    }, [])

    return (
        <React.Fragment>
            <AppBarComponent/>
            <br/>

            <Grid container justifyContent={"flex-start"}>
                <Grid item>
                    <FirmSelectComponent
                        firms={[{id: 1, name: 'СИНИ ЕООД'}]}
                        selectedFirm={selectedFirm}
                        setSelectedFirm={setSelectedFirm}
                        setSelectedObject={setSelectedObject}
                        setOffersMode={setOffersMode}
                        resetProps={resetProps}
                    />
                </Grid>



                { objects && objects.find((object) => object.id === selectedObject) ? null :
                    <Grid item>
                        <ObjectSelectComponent
                            label={"Обекти"}
                            objects={objects}
                            setOffersMode={setOffersMode}
                            selectedObject={selectedObject}
                            setSelectedObject={setSelectedObject}
                        />
                    </Grid>
                }

                <Grid item style={{marginLeft: 30}}>
                    { objects && objects.find((object) => object.id === selectedObject) ? null :

                        <Grid container alignItems={'center'}>
                            <Grid item xs={4} style={{width: 250, border: '1px solid gray',
                                background: offersMode ? color : null,
                                borderRadius: 10, padding: 10, cursor: 'pointer'}}>
                                <Typography
                                    style={{color: 'black'}}
                                    fullWidth
                                    variant={'outlined'}
                                    id={"save"}
                                    onClick={(event) =>  {
                                        setSelectedObject(0)
                                        setOffersMode(true)
                                    }}
                                >Оферта</Typography>
                            </Grid>

                        </Grid>
                    }


                </Grid>

                <Grid item xs={12}><p></p></Grid>

                {
                    selectedObject ?
                    <Typography style={{padding: 20}}>{objects && objects.find((object) => object.id === selectedObject) ? objects.find((object) => object.id === selectedObject).name_project : null}</Typography>
                        : null
                }
                {
                    (offersMode && selectedObjectForOfferName) ?
                    <Typography style={{padding: 20}}>{selectedObjectForOfferName}</Typography>
                        : null
                }



                <Grid item xs={12} style={{padding: 20}}>

                    <Grid container>
                        {/* -------- 1.-----------*/}

                        {!hideBeforeDeliverer && <Grid item xs={4} sm={1}>

                            {/*
                            Оферта, Договор, Изпълнение
*/}
                            <LevelOneOfferContractExecution
                                offersMode={offersMode}
                                selectedObject={selectedObject}
                                levelOne={levelOne}
                                setLevelOne={setLevelOne}
                                color={color}
                                setDocuments={setDocuments}
                                setReferenceOpen={setReferenceOpen}
                                resetProps={resetProps}
                            />
                        </Grid>
                        }



                        {
                            levelOne === 0 ?
                                <Grid item xs={8} sm={11}>
                                    <TrackOne
                                        objects={objects}
                                        selectedObjectForOffer={selectedObjectOffer}
                                        setSelectedObjectForOffer={setSelectedObjectOffer}
                                        selectedObjectForOfferName={selectedObjectForOfferName}
                                        setSelectedObjectForOfferName={setSelectedObjectForOfferName}
                                        levelDeliver={levelDeliver}
                                        selectedObject={selectedObject}
                                        levelOne={levelOne}
                                        levelTwo={levelTwo}
                                        levelOneTwo={levelOneTwo}
                                        levelThree={levelThree}
                                        levelFour={levelFour}
                                        setLevelTwo={setLevelTwo}
                                        setLevelOneTwo={setLevelOneTwo}
                                        setLevelThree={setLevelThree}
                                        setLevelFour={setLevelFour}
                                        levelStageGroup={levelStageGroup}
                                        setLevelDeliver={setLevelDeliver}
                                        stage={stage}
                                        setStage={setStage}
                                        group={group}
                                        setGroup={setGroup}
                                        service={service}
                                        material={material}
                                        setMaterial={setMaterial}
                                        serviceMaterial={serviceMaterial}
                                        setServiceMaterial={setServiceMaterial}
                                        isDocuments={isDocuments}
                                        setDocuments={setDocuments}
                                        isScanned={isScanned}
                                        setScanned={setScanned}
                                        assignee={assignee}
                                        setAssignee={setAssignee}
                                        isElectronic={isElectronic}
                                        setElectronic={setElectronic}
                                        isPhysical={isPhysical}
                                        setPhysical={setPhysical}
                                        color={color}
                                        SERVICE={SERVICE}
                                        MATERIAL={MATERIAL}
                                    />
                                </Grid>
                                :

                                levelOne === 1 ?
                                    <Grid item xs={8} sm={11}>

                                        <TrackTwo
                                            selectedObject={selectedObject}
                                            levelOne={levelOne}
                                            levelTwo={levelTwo}
                                            levelOneTwo={levelOneTwo}
                                            levelThree={levelThree}
                                            levelFour={levelFour}
                                            setLevelTwo={setLevelTwo}
                                            setLevelOneTwo={setLevelOneTwo}
                                            setLevelThree={setLevelThree}
                                            setLevelFour={setLevelFour}
                                            levelStageGroup={levelStageGroup}
                                            levelDeliver={levelDeliver}
                                            material={material}
                                            good={service}
                                            setMaterial={setMaterial}
                                            stage={stage}
                                            setStage={setStage}
                                            group={group}
                                            setGroup={setGroup}
                                            serviceMaterial={serviceMaterial}
                                            setServiceMaterial={setServiceMaterial}
                                            setLevelDeliver={setLevelDeliver}
                                            isDocuments={isDocuments}
                                            setDocuments={setDocuments}
                                            isScanned={isScanned}
                                            setScanned={setScanned}
                                            assignee={assignee}
                                            setAssignee={setAssignee}
                                            isElectronic={isElectronic}
                                            setElectronic={setElectronic}
                                            isPhysical={isPhysical}
                                            setPhysical={setPhysical}
                                            color={color}
                                            SERVICE={SERVICE}
                                            MATERIAL={MATERIAL}
                                        />
                                    </Grid>
                                    :

                                    levelOne === 2 ?

                                    <Grid item xs={8} sm={hideBeforeDeliverer ? 12 : 11}>

                                        <TrackThree
                                            hideBeforeDeliverer={hideBeforeDeliverer}
                                            selectedObject={selectedObject}
                                            levelOne={levelOne}
                                            levelTwo={levelTwo}
                                            levelOneTwo={levelOneTwo}
                                            levelThree={levelThree}
                                            levelFour={levelFour}
                                            setLevelTwo={setLevelTwo}
                                            setLevelOneTwo={setLevelOneTwo}
                                            setLevelThree={setLevelThree}
                                            setLevelFour={setLevelFour}
                                            levelStageGroup={levelStageGroup}
                                            levelDeliver={levelDeliver}
                                            setLevelDeliver={setLevelDeliver}
                                            stage={stage}
                                            setStage={setStage}
                                            group={group}
                                            setGroup={setGroup}
                                            material={material}
                                            setMaterial={setMaterial}
                                            service={service}
                                            setService={setService}
                                            serviceMaterial={serviceMaterial}
                                            setServiceMaterial={setServiceMaterial}
                                            isDocuments={isDocuments}
                                            setDocuments={setDocuments}
                                            isScanned={isScanned}
                                            setScanned={setScanned}
                                            assignee={assignee}
                                            setAssignee={setAssignee}
                                            isElectronic={isElectronic}
                                            setElectronic={setElectronic}
                                            isPhysical={isPhysical}
                                            setPhysical={setPhysical}
                                            act={act}
                                            setAct={setAct}
                                            color={color}
                                            SERVICE={SERVICE}
                                            MATERIAL={MATERIAL}
                                        />
                                    </Grid>

                                        :

                                    <div style={{visibility: 'hidden'}}>

                                        {/* ----------- 2.-----------*/}
                                        {
                                            levelOne !== null ?
                                                <Grid item xs={4} sm={1.5}>
                                                    {/*
                            Доставчик, Инвеститор
*/}

                                                    <LevelTwoInvestorDeliver
                                                        levelOneTwo={levelOneTwo}
                                                        levelTwo={levelTwo}
                                                        setLevelTwo={setLevelTwo}
                                                        setLevelOneTwo={setLevelOneTwo}
                                                        color={color}/>
                                                </Grid>
                                                :
                                                null
                                        }


                                        {/* ----------- 2.0.1-----------*/}
                                        {
                                            /*
                                                                        Услуга, Материал
                                            */
                                            (levelOne !== null && levelOneTwo === 1) ?
                                                <Grid item xs={7} sm={3}>
                                                    <LevelServiceMaterial
                                                        serviceMaterial={serviceMaterial}
                                                        levelTwo={levelTwo}
                                                        setLevelTwo={setLevelTwo}
                                                        setAssignee={setAssignee}
                                                        setServiceMaterial={setServiceMaterial}
                                                        color={color}
                                                        SERVICE={SERVICE}
                                                        MATERIAL={MATERIAL}
                                                    />
                                                </Grid>
                                                :
                                                null
                                        }


                                        {/* ----------- 2.1.-----------*/}
                                        {
                                            /*
                                                                        Етап, група
                                            */
                                            levelOneTwo !== null && levelOne === 0 ?
                                                <Grid item xs={4} sm={2}>
                                                    <LevelStageGroup
                                                        levelStageGroup={levelStageGroup}
                                                        stage={stage}
                                                        setStage={setStage}
                                                        group={group}
                                                        setGroup={setGroup}
                                                        color={color}/>
                                                </Grid>
                                                :
                                                null
                                        }

                                        {/* ----------- 3.-----------*/}
                                        {
                                            levelTwo !== null && levelOne === 1 ?
                                                levelTwo === 0 ?
                                                    <Grid item xs={5} sm={2}>
                                                        {/*
                                        Договор, КСС, График, График плащания, Споразумение ЗБУТ, Анекс, Документи
*/}
                                                        <LevelThree
                                                            levelFour={levelFour}
                                                            setLevelFour={setLevelFour}
                                                            setDocuments={setDocuments}
                                                            color={color}/>
                                                    </Grid>

                                                    :
                                                    serviceMaterial === SERVICE ?
                                                        <Grid item xs={5} sm={1.5}>
                                                            {/*
                                            Договор, КСС, График, Споразумение ЗБУТ, Анекс, Документи
*/}
                                                            <LevelThreeService
                                                                levelFour={levelFour}
                                                                setLevelFour={setLevelFour}
                                                                setDocuments={setDocuments}
                                                                color={color}/>
                                                        </Grid>
                                                        :
                                                        <Grid item xs={5} sm={1.5}>
                                                            {/*
                                            Договор, График доставка, Анекс, Документи
*/}
                                                            <LevelThreeMaterial
                                                                levelFour={levelFour}
                                                                setLevelFour={setLevelFour}
                                                                setDocuments={setDocuments}
                                                                color={color}/>
                                                        </Grid>



                                                :
                                                (levelTwo !== null) ?
                                                    levelOne !== 2 ?
                                                        <Grid item xs={5} sm={2}>
                                                            {/*
                                КСС, График, Документи
*/}
                                                            <LevelThreeMost
                                                                setLevelThree={setLevelThree}
                                                                levelThree={levelThree}
                                                                color={color}/>
                                                        </Grid>
                                                        :
                                                        <Grid item xs={6} sm={2}>

                                                            {/*
                                Акт, Анекс, Фактура, Платежно
*/}
                                                            <LevelThreeRest
                                                                color={color}
                                                                levelThree={levelThree}
                                                                setLevelThree={setLevelThree()}/>
                                                        </Grid>
                                                    :
                                                    null
                                        }

                                        {/* ----------- 3.0.1-----------*/}
                                        {

                                            /*
                                                                        Договор, КСС, График, График плащания, Споразумение ЗБУТ, Анекс
                                            */
                                            isDocuments && levelOne !== 0 ?
                                                levelTwo === 0 ?
                                                    <Grid item xs={5} sm={2}>
                                                        <LevelThreeZeroOneOfferInvector
                                                            levelFourDocuments={levelFourDocuments}
                                                            setLevelFourDocuments={setLevelFourDocuments}
                                                            color={color}/>
                                                    </Grid>

                                                    :
                                                    serviceMaterial === SERVICE ?
                                                        <Grid item xs={5} sm={1.5}>
                                                            {/*
                                            Договор, КСС, График, Споразумение ЗБУТ, Анекс
*/}
                                                            <LevelThreeZeroOneService
                                                                levelFourDocuments={levelFourDocuments}
                                                                setLevelFourDocuments={setLevelFourDocuments}
                                                                color={color}
                                                            />
                                                        </Grid>
                                                        :
                                                        <Grid item xs={5} sm={1.5}>
                                                            {/*
                                            Договор, График доставка, Анекс
*/}
                                                            <LevelThreeZeroOneMaterial
                                                                levelFourDocuments={levelFourDocuments}
                                                                setLevelFourDocuments={setLevelFourDocuments}
                                                                color={color}/>
                                                        </Grid>

                                                :
                                                null
                                        }





                                        {/* ----------- 4.-----------*/}
                                        {
                                            (levelThree !== null) ?
                                                levelOne === 0 && levelThree === 2 ?
                                                    <Grid item xs={5} sm={1.5}>
                                                        {/*
                                    КСС, График
*/}
                                                        <LevelFourOffers
                                                            levelFour={levelFour}
                                                            setLevelFour={setLevelFour}
                                                            color={color}
                                                            setDocuments={setDocuments}/>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={6} sm={2}>
                                                        {/*
                                    Акт 1, Акт 2, Акт 3, Добави акт
*/}
                                                        <LevelFourRest
                                                            levelFour={levelFour}
                                                            levelFourOne={levelFourOne}
                                                            setLevelFour={setLevelFour}
                                                            setLevelFourOne={setLevelFourOne}
                                                            color={color}/>
                                                    </Grid>
                                                :
                                                null
                                        }


                                        {/* ----------- 5.-----------*/}
                                        {
                                            (levelFour !== null && isDocuments) ?
                                                <Grid item xs={5} sm={1}>
                                                    {/*
                                Физически, Електронен, Сканиран
*/}
                                                    <LevelDocuments
                                                        isScanned={isScanned}
                                                        setScanned={setScanned}
                                                        isElectronic={isElectronic}
                                                        setElectronic={setElectronic}
                                                        isPhysical={isPhysical}
                                                        setPhysical={setPhysical}/>
                                                </Grid>
                                                :
                                                null
                                        }
                                    </div>


                        }





                    </Grid>



                </Grid>

            </Grid>


            <CategoryDialogComponent
                open={fullscreenDialogOpen}
                handleClose={() => setFullscreenDialogOpen(false)}
                category={categorySelected}
                object={objectNameSelected}
            />

            <ReferenceComponent
                department={selectedObject}
                open={referenceOpen}
                handleClose={() => setReferenceOpen(false)}
                color={color}
            />
        </React.Fragment>
    )
}
export default HomeScreen;
