import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import DescriptionWorkDialog from "../../descriptionWork/DescriptionWorkDialog";
import KssDialog from "../../kss/KssDialog";
import {getWorkingGroups} from "../../../utils/NetworkUtils";
import {useLocalStorage} from "../../../useLocalStorage";
import {useNavigate} from "react-router-dom";

function LevelThreeMostInvestor({selectedObject, setLevelThree, levelThree, color}) {
    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [workDescriptionOpen, setWorkDescriptionOpen] = useState(false);
    const [kssDialogOpen, setKssDialogOpen] = useState(false);

    const [kssItems, setKssItems] = useState(false);

    return (
        <Grid xs={7} container justifyContent={'space-between'} direction="column">

            <Grid item xs={7}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === -1 ? color : null}}
                       onClick={() => {
                           setLevelThree(-1)
                           setWorkDescriptionOpen(true)
                       }}>
                    <Typography>Опис работа</Typography>
                </Paper>
            </Grid>

            <Grid item xs={7}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 0 ? color : null}}
                       onClick={() => {
                           setLevelThree(0)

                           getWorkingGroups({id: selectedObject}, navigate, accessToken, setAccessToken)
                               .then((result) => {
                                   for (const resultElement of result.items) {
                                       resultElement.name = resultElement.name_group;
                                       resultElement.sub = resultElement.subgroups;
                                       for (const resultElementElement of resultElement.sub) {
                                           resultElementElement.name = resultElementElement.name_group;
                                           for (const work of resultElementElement.works) {
                                               work.name = work.name_work;
                                               work.entity = work.edm;
                                               work.quantity = work.qty;
                                           }
                                       }
                                   }
                                   setKssItems(result.items)
                                   setKssDialogOpen(true)

                               })
                       }}>
                    <Typography>КСС</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 1 ? color : null}}
                       onClick={() => {
                           setLevelThree(1)
                       }}>
                    <Typography>График</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 2 ? color : null}}
                       onClick={() => {

                           setLevelThree(2)
                       }}>
                    <Typography>Документи</Typography>
                </Paper>
            </Grid>

            <DescriptionWorkDialog
                selectedObject={selectedObject}
                open={workDescriptionOpen}
                handleClose={() => {
                    setLevelThree(null)
                    setWorkDescriptionOpen(false)
                }}/>

            <KssDialog
                selectedObject={selectedObject}
                open={kssDialogOpen}
                handleClose={() => {
                    setKssDialogOpen(false)
                    setLevelThree(null)
                }}
                items={kssItems}
                setItems={setKssItems}/>


        </Grid>

    )
}
export default LevelThreeMostInvestor;