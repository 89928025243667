import {Grid} from "@mui/material";
import LevelTwoInvestorDeliver from "../../../levels/LevelTwoInvestorDeliver";
import React from "react";
import LevelServiceMaterial from "../../../levels/LevelServiceMaterial";
import LevelStageGroup from "../../../levels/LevelStageGroup";
import LevelFiveAvansAktDocs from "../../../levels/levelFive/LevelFiveAvansAktDocs";
import LevelFiveAvansAktCert from "../../../levels/levelFive/LevelFiveAvansAktCert";
import LevelFiveAvans from "../../../levels/levelFive/LevelFiveAvans";
import LevelDocuments from "../../../levels/LevelDocuments";
import LevelFiveAktCert from "../../../levels/levelFive/LevelFiveAktCert";

function TrackThreeDeliverServiceAvans({
                                           selectedObject,
                       material, levelDeliver, deliveryNum,
                       levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup,
                      stage, setStage,
                      group, setGroup,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical, isCertificateEnabled,
                                act, setAct,
                      color, SERVICE, MATERIAL
                  }) {

    function computeDocName() {
        console.log('stage ', stage)
        console.log('group ', group)
        console.log('material ', material)
        console.log('levelDeliver ', levelDeliver)
        const stageId = stage ? stage.id : 0;
        const groupId = group ? group.id : 0;
        const goodId = material ? material.id : 0;
        const assigneeId = levelDeliver ? levelDeliver.id : 0;

        const actNum = deliveryNum ? deliveryNum.doc_no : 0;

        let levelFourText;
        switch (levelFour) {
            case 0:
                levelFourText = 'kss';
                break;
            case 1:
                levelFourText = 'act';
                break;
            case 2:
                levelFourText = 'invoice';
                break;
            case 3:
                levelFourText = 'payment';
                break;
            case 4:
                levelFourText = 'certificate';
                break;
        }
        return `performance-${stageId}-${groupId}-${goodId}-${assigneeId}-act-${actNum}-${levelFourText}`;
    }


    return (
        <Grid container>

            <Grid item xs={4} sm={6}>
{/*
                КСС, Акт, Фактура, Платежно, Сертификат
*/}
                <LevelFiveAktCert
                    level={levelFour}
                    setLevel={setLevelFour}
                    color={color}
                />
            </Grid>

            {/* ----------- 5.-----------*/}
            {
                (levelFour !== null) ?
                    <Grid item xs={5} sm={1}>
                        {/*
                                Физически, Електронен, Сканиран
*/}
                        <LevelDocuments
                            selectedObject={selectedObject}
                            docName={computeDocName()}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}/>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
}
export default TrackThreeDeliverServiceAvans;