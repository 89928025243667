import List from "@mui/material/List";
import * as React from 'react';
import {ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ChangePriceDialog from "./../ChangePriceDialog";
import Button from "@mui/material/Button";
import workTypeDialog from "../../workType/WorkTypeDialog";
import TempAdditionalCell from "./../TempAdditionalCell";
import AddEditWorkDialog from "./../AddEditWorkDialog";
import DostSkladCell from "./../DostSkladCell";
import ProfitCell from "./../ProfitCell";
import TotalNoVatRow from "./../TotalNoVatRow";
import {
    addAnalysisDetail,
    addAnalysisDetailFromScratch,
    changePercent, changePercentWork, deleteAwork, updateAnalysisDetailFromScratch,
    updateDetail
} from "../../../utils/NetworkUtils";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ConfirmationDialog from "../ConfirmationDialog";

function AnalysisComponent({id, filter, topData, setTopData, items, setItems, setTotalWithNoVat,
                               deletionOpen, setDeletionOpen, handleAnalysisClose,
                               totalWithNoVat, idSmrGroup, setForceRefresh,
                               serviceAdditionalCostPerc, setServiceAdditionalCostPerc,
                               serviceAdditionalProfitPerc, setServiceAdditionalProfitPerc}) {
    let navigate = useNavigate();
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [changePriceDialogOpen, setChangePriceDialogOpen] = useState(false);
    const [changeFiraDialogOpen, setChangeFiraDialogOpen] = useState(false);

    const [changeAdditionalCostDialog, setChangeAdditionalCostDialog] = useState(false);
    const [additionalCostDialogEdit, setAdditionalCostDialogEdit] = useState(null);

    const [editingItemId, setEditingItemId] = useState(null);
    const [entityPrice, setEntityPrice] = useState(null);
    const [fira, setFira] = useState(null);

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDetailDialogOpen, setEditDetailDialogOpen] = useState(false);
    const [nameAddDialog, setNameAddDialog] = useState(null);
    const [entityAddDialog, setEntityAddDialog] = useState(null);
    const [costRateAddDialog, setCostRateAddDialog] = useState(null);
    const [firaAddDialog, setFiraAddDialog] = useState(null);
    const [entityPriceDialogAddDialog, setEntityPriceDialogAddDialog] = useState(null);
    const [dostSkladoviState, setDostSkladovi] = useState(null);

    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };

    const calculateTotalAmount = (item) => {
        return (item.cost_rate + item.cost_rate * (Number(item.perc_fira) / 100));
    }
    useEffect(() => {

        setTotalWithNoVat(0)
        let allTypes = 0;
        for (let i = 1; i <= 4; i++) {
            const type = i;

            let additionalCost = 0;

            //Calculate items only
            const totalItems = (items && items.items) && items.items
                .filter((item) => {
                    return item.id_detail_type === type
                })
                .reduce(function (a, b) {
                    const totalQuantity = calculateTotalAmount(b);

                    return totalQuantity ? a + (totalQuantity * b.price) : a;
                }, 0);

            // get the line with id === 0
            let additionalItem = (items && items.items) && items.items.find((item) => {
                return item.id === 0 && item.id_detail_type === type;
            });
            // if a SERVICE
            if (type === 4) {
                additionalItem = {
                    cost_rate: !isNaN(serviceAdditionalCostPerc) ? Number(serviceAdditionalCostPerc) : 0,
                    profit: !isNaN(serviceAdditionalProfitPerc) ? Number(serviceAdditionalProfitPerc) : 0
                }
            }

            if (additionalItem && additionalItem.cost_rate) {
                additionalCost = ((additionalItem.cost_rate / 100) * totalItems)
            }

            // Only calculate Доставно складови, if materials
            const percDostSkladovi = additionalItem && !isNaN(additionalItem.perc_goods_store) ? Number(additionalItem.perc_goods_store) : 0;
            const dostSkladovi = type === 3 ? (totalItems + additionalCost) * (percDostSkladovi / 100) : 0;
            setDostSkladovi(topData.perc_goods_store)

            const profitPercent = additionalItem ? additionalItem.profit || 0 : 0;
            const profitNum = (totalItems + additionalCost + dostSkladovi) * (profitPercent / 100);

            console.log('totalItems ', totalItems)
            console.log('additionalCost ', additionalCost)
            console.log('dostSkladovi ', dostSkladovi)
            console.log('profitNum ', profitNum)
            const total = totalItems + additionalCost + dostSkladovi + profitNum;
            console.log('adding to total no vat ', total)
            allTypes += total;
        }
        console.log('calc triggered ', allTypes)
        setTotalWithNoVat(allTypes)
    }, [items, addDialogOpen, nameAddDialog, entityAddDialog, costRateAddDialog, firaAddDialog, entityPriceDialogAddDialog,
        changeAdditionalCostDialog, changePriceDialogOpen, changeFiraDialogOpen])


    const renderMainContent = (type, name) => {
        let additionalCost = 0;

        //Calculate items only
        const totalItems = (items && items.items) && items.items
            .filter((item) => {
                return item.id_detail_type === type
            })
            .reduce( function(a, b){
                const totalQuantity = calculateTotalAmount(b);

                return totalQuantity ? a + (totalQuantity * b.price): a;
            }, 0);

        // get the line with id === 0
        let additionalItem = (items && items.items) && items.items.find((item) => {
            return item.id === 0 && item.id_detail_type === type;
        });
        // if a SERVICE
        if (type === 4) {
            additionalItem = {
                cost_rate: !isNaN(serviceAdditionalCostPerc) ? Number(serviceAdditionalCostPerc) : 0,
                profit: !isNaN(serviceAdditionalProfitPerc) ? Number(serviceAdditionalProfitPerc) : 0
            }
        }

        if (additionalItem && additionalItem.cost_rate){
            additionalCost = ((additionalItem.cost_rate / 100) * totalItems )
        }

        // Only calculate Доставно складови, if materials
        const percDostSkladovi = topData ? topData.perc_goods_store : 0;
        const dostSkladovi = type === 3 ? (totalItems + additionalCost) * (percDostSkladovi / 100) : 0;

        const profitPercent = additionalItem && additionalItem.profit ? additionalItem.profit : 0;
        const profitNum = (totalItems + additionalCost + dostSkladovi) * (profitPercent/100);


        return (
            <React.Fragment>
                <TableRow
                    key={name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell colSpan={7}>
                        <Typography variant={'h5'}>&nbsp;</Typography>
                    </TableCell>
                </TableRow>

                <TableRow
                    key={name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell>
                        <Grid container justifyContent={'space-between'}>
                            <Grid item>
                                <Typography variant={'h5'}>{ name }</Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => {
                                    setAddDialogOpen(type)
                                }}><ControlPointIcon fontSize={'medium'} sx={{color: 'black'}}/></Button>

                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell colSpan={6}>

                    </TableCell>
                </TableRow>

                {
                    items && items.items && items.items
                        .filter((item) => {
                            return item.id_detail_type === type
                        })
                        .map((item) => {
                            const totalQuantity = calculateTotalAmount(item);

                            return <TableRow
                                key={item.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" style={style.tableCell} onClick={() => {
                                    if (item.id !== 0) {
                                        console.log(item)
                                        setNameAddDialog(item.name)
                                        setEntityAddDialog(item.entity)
                                        setCostRateAddDialog(item.cost_rate)
                                        setFiraAddDialog(item.perc_fira)
                                        setEntityPriceDialogAddDialog(item.price)
                                        setEditDetailDialogOpen(item.id)
                                    }
                                }}>
                                    {item.id === 0 ? <b>{item.name}</b> : item.name }
                                </TableCell>

                                <TableCell align="right" style={style.tableCell}>{item.entity}</TableCell>

                                <TableCell align="right" style={style.tableCell}
                                    onClick={(e) => {
                                        if (item.id === 0) {
                                            e.stopPropagation()
                                            e.nativeEvent.stopImmediatePropagation();
                                            setChangeAdditionalCostDialog(type)
                                            setAdditionalCostDialogEdit(item.cost_rate)
                                        }
                                    }}
                                    >{item.cost_rate ? (item.cost_rate % 1 !== 0 ? item.cost_rate.toFixed(2).concat(item.id === 0 ? "%" : "") : item.cost_rate.toString().concat(item.id === 0 ? "%" : ""))
                                    : item.id === 0 ? '0' : null}</TableCell>

                                {(!filter || item.id_detail_type === 3) && <TableCell
                                    style={style.tableCell}
                                    onClick={(e) => {
                                        if (item.id_detail_type === 3 && item.id !== 0) {
                                            e.stopPropagation()
                                            e.nativeEvent.stopImmediatePropagation();
                                            setChangeFiraDialogOpen(true)
                                            setFira(item.perc_fira)
                                            setEditingItemId(item.id)
                                        }

                                    }}
                                    align="right">{ item.id !== 0 ? item.id_detail_type === 3 ? ((item.perc_fira) ? item.perc_fira + '%' : '0%') : null : null}</TableCell>}

                                {(!filter || item.id_detail_type === 3) && <TableCell style={style.tableCell}
                                    align="right">{(item.id_detail_type === 3) ? totalQuantity ? totalQuantity.toFixed(2) : null : null}</TableCell>}

                                <TableCell
                                    style={style.tableCell}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.nativeEvent.stopImmediatePropagation();
                                        setChangePriceDialogOpen(true)
                                        setEntityPrice(item.price)
                                        setEditingItemId(item.id)
                                    }}
                                    align="right">{item.id !== 0 ?
                                        item.price ? parseFloat(item.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")  : null
                                    :
                                        totalItems.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    }</TableCell>

                                <TableCell align="right"
                                   style={style.tableCell}>{
                                    item.id !== 0 ?
                                        totalQuantity && item.cost_rate ?
                                        (totalQuantity * item.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null
                                        :
                                        (additionalCost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

                                }</TableCell>
                            </TableRow>
                })
                }

                {(type === 4) && <TempAdditionalCell
                    id={id}
                    filter={filter}
                    serviceAdditionalCostPerc={serviceAdditionalCostPerc}
                    additionalCost={additionalCost}
                    setForceRefresh={setForceRefresh}
                    totalItems={totalItems}/> }

                {(type === 3) && <DostSkladCell
                    workId={id} setForceRefresh={setForceRefresh}
                    filter={filter} additionalCost={additionalCost}
                    dostSkladovi={dostSkladoviState} totalItems={totalItems}/>
                }

               <ProfitCell
                   workId={id}
                   filter={filter} profitNum={profitNum} profitPercent={profitPercent}
                   topData={topData} setTopData={setTopData}
                   setForceRefresh={setForceRefresh}
                   type={type}
                   totalItems={totalItems} additionalCost={additionalCost} dostSkladovi={dostSkladovi}/>

                <TableRow
                    key={'profit'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row" style={style.tableCell}>
                        <b>Общо {name}</b>
                    </TableCell>
                    <TableCell align="right" style={style.tableCell}></TableCell>
                    <TableCell align="right" style={style.tableCell}></TableCell>
                    {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                    {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                    <TableCell align="right" style={style.tableCell}></TableCell>
                    {
                        console.log('totalItems + additionalCost + dostSkladovi + profitNum ',
                            totalItems, additionalCost, dostSkladovi, profitNum)
                    }
                    <TableCell align="right" style={style.tableCell}>{(totalItems + additionalCost + dostSkladovi + profitNum).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    return (
        <Paper>

            <Grid container spacing={2}>


                <Grid item xs={12}>
                    <Table sx={{ minWidth: 650, maxWidth: '60%' }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Име</TableCell>
                                <TableCell align="right">Мярка</TableCell>
                                <TableCell align="right">Разход</TableCell>
                                {(!filter || filter === 3) && <TableCell align="right">Фира%</TableCell>}
                                {(!filter || filter === 3) && <TableCell align="right">Общо разход</TableCell>}
                                <TableCell align="right">Ед. цена (лв)</TableCell>
                                <TableCell align="right">Стойност</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!filter || filter === 1) &&
                                renderMainContent(1, "Труд")
                            }
                            {
                                (!filter || filter === 3) &&
                                renderMainContent(3, "Материали")
                            }
                            {
                                (!filter || filter === 4) &&
                                renderMainContent(4, "Услуги")
                            }
                            {
                                (!filter || filter === 2) &&
                                renderMainContent(2, "Механизация")
                            }
                            {
                                !filter && <TotalNoVatRow totalWithNoVat={totalWithNoVat}/>
                            }

                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <ChangePriceDialog
                open={changePriceDialogOpen}
                handleClose={() => setChangePriceDialogOpen(false)}
                label={'Цена'}
                item={entityPrice}
                setItem={setEntityPrice}
                onClick={() => {
                    const newItems = items;
                    const itemToEditIndex = newItems.items
                        .findIndex((item) => {
                            return item.id === editingItemId
                        })

                    newItems.items[itemToEditIndex].price = entityPrice;

                    updateDetail(editingItemId,
                        newItems.items[itemToEditIndex].name,
                        newItems.items[itemToEditIndex].entity,
                        newItems.items[itemToEditIndex].qty,
                        newItems.items[itemToEditIndex].perc_fira,
                        newItems.items[itemToEditIndex].cost_rate,
                        entityPrice,
                        newItems.items[itemToEditIndex].amount,
                        navigate, accessToken, setAccessToken
                        )
                    setItems(newItems)
                    setChangePriceDialogOpen(false)
                }}/>


            <ChangePriceDialog
                open={changeFiraDialogOpen}
                handleClose={() => setChangeFiraDialogOpen(false)}
                label={'Фира'}
                item={fira}
                setItem={setFira}
                onClick={() => {
                    const newItems = items;
                    const itemToEditIndex = newItems.items
                        .findIndex((item) => {
                            return item.id === editingItemId
                        })

                    newItems.items[itemToEditIndex].perc_fira = fira;
                    setItems(newItems)
                    setChangeFiraDialogOpen(false)

                }}/>


            <ChangePriceDialog
                open={changeAdditionalCostDialog}
                handleClose={() => setChangeAdditionalCostDialog(false)}
                label={'Допълнителни разходи'}
                item={additionalCostDialogEdit}
                setItem={setAdditionalCostDialogEdit}
                onClick={() => {
                    const newItems = items;
                    const itemToEditIndex = newItems.items
                        .findIndex((item) => {
                            return item.id === 0 && item.id_detail_type === changeAdditionalCostDialog
                        })

                    newItems.items[itemToEditIndex].cost_rate = Number(additionalCostDialogEdit);
                    setItems(newItems)
                    changePercentWork(id, changeAdditionalCostDialog, Number(additionalCostDialogEdit), navigate, accessToken, setAccessToken)
                    console.log('newItems ', newItems)
                    setChangeAdditionalCostDialog(false)

                }}/>

            <AddEditWorkDialog
                open={addDialogOpen}
                handleClose={() => {setAddDialogOpen(false)}}
                name={nameAddDialog}
                setName={setNameAddDialog}
                entity={entityAddDialog}
                setEntity={setEntityAddDialog}
                costRate={costRateAddDialog}
                setCostRate={setCostRateAddDialog}
                fira={firaAddDialog}
                setFira={setFiraAddDialog}
                entityPrice={entityPriceDialogAddDialog}
                setEntityPrice={setEntityPriceDialogAddDialog}
                onClick={() => {
                    const newItems = items;
                    console.log(newItems)
                    const itemToInsertIndex = newItems && newItems.items ? newItems.items
                        .findLastIndex((item) => {
                            return item.id_detail_type === addDialogOpen
                        }) : 0


                    let objectToAdd = {
                        cost_rate: Number(costRateAddDialog),
                        entity: entityAddDialog.name,
                        price: Number(entityPriceDialogAddDialog),
                        perc_fira: firaAddDialog ? firaAddDialog : 0,
                        id_detail_type: addDialogOpen,
                        id: new Date().valueOf(),
                        name: nameAddDialog
                    };
                    if (newItems.items) {
                        newItems.items.splice(itemToInsertIndex, 0, objectToAdd);
                    } else {
                        newItems.items = [];
                        newItems.items.push(objectToAdd)
                    }



                addAnalysisDetailFromScratch(id, addDialogOpen, nameAddDialog,
                    entityAddDialog.name, Number(costRateAddDialog) * (Number(firaAddDialog) / 100), firaAddDialog,
                    Number(costRateAddDialog), Number(entityPriceDialogAddDialog), 1,
                    navigate, accessToken, setAccessToken)
                    .then(() => {
                        setForceRefresh(Math.random())
                    })

                    setItems(newItems)
                    setAddDialogOpen(false)
                    setNameAddDialog(null)
                    setEntityAddDialog(null)
                    setCostRateAddDialog(null)
                    setFiraAddDialog(null)
                    setEntityPriceDialogAddDialog(null)
                }}/>

            <AddEditWorkDialog
                open={editDetailDialogOpen}
                handleClose={() => {setEditDetailDialogOpen(false)}}
                name={nameAddDialog}
                setName={setNameAddDialog}
                entity={entityAddDialog}
                setEntity={setEntityAddDialog}
                costRate={costRateAddDialog}
                setCostRate={setCostRateAddDialog}
                fira={firaAddDialog}
                setFira={setFiraAddDialog}
                entityPrice={entityPriceDialogAddDialog}
                setEntityPrice={setEntityPriceDialogAddDialog}
                onClick={() => {
                    updateAnalysisDetailFromScratch(editDetailDialogOpen, nameAddDialog,
                        entityAddDialog, Number(costRateAddDialog) * (Number(firaAddDialog) / 100), firaAddDialog,
                        Number(costRateAddDialog), Number(entityPriceDialogAddDialog), 1,
                        navigate, accessToken, setAccessToken)
                        .then(() => {
                            setForceRefresh(Math.random())
                        })

                        setEditDetailDialogOpen(false)
                        setNameAddDialog(null)
                        setEntityAddDialog(null)
                        setCostRateAddDialog(null)
                        setFiraAddDialog(null)
                        setEntityPriceDialogAddDialog(null)
                    }}/>

            <ConfirmationDialog
                open={deletionOpen}
                handleClose={() => setDeletionOpen(false)}
                title={"Да изтрия ли?"}
                dialogText={"Наистина ли желате да изтриете този анализ?"}
                positiveText={"Изтрий"}
                positiveAction={() => {
                    deleteAwork(id, navigate, accessToken, setAccessToken)
                        .then(() => {
                            setDeletionOpen(false)
                            handleAnalysisClose()

                        })

                }}
                negativeText={"Отказ"}/>

        </Paper>
    )
}
export default AnalysisComponent;