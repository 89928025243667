import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import {setDocState} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";

// open, handleSubmit, handleClose, longName, setLongName, shortName, setShortName, department
export function SetPhysicalDialog({setDocState, open, setOpen, handleClose, setPhysical, selectedObject, docName, isPhysical}) {

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [location, setLocation] = useState(null);

    return (
        <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Физически</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Въведете данни
                    </DialogContentText>
                    <Grid container direction="row" spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                autoFocus
                                margin="dense"
                                id="longName"
                                value={location}
                                onChange={(event) =>
                                    setLocation(event.target.value)
                                }
                                label="Къде се намира"
                                type="text"
                                variant="standard"
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отказ</Button>

                    <Button onClick={() => {
                        setPhysical(true)
                        setDocState({id: selectedObject}, navigate, accessToken, setAccessToken, docName,
                            {
                                physical: {state: 1, remark: location}
                            })
                        setLocation(null)
                        setOpen(false)

                    }} disabled={!location}>Добави</Button>
                </DialogActions>
        </Dialog>
    )

}