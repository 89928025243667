import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelFiveAkt({levelFour, setLevelFour, color}) {

    return (
        <Grid container>

            <Grid item xs={8.5}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 0 ? color : null}}
                       onClick={() => {
                           setLevelFour(0)
                       }}>
                    <Typography>КСС</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8.5}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 1 ? color : null}}
                       onClick={() => {
                           setLevelFour(1)
                       }}>
                    <Typography>Акт</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8.5}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 2 ? color : null}}
                       onClick={() => {
                           setLevelFour(2)
                       }}>
                    <Typography>Фактура</Typography>
                </Paper>
            </Grid>


            <Grid item xs={8.5}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFour === 3 ? color : null}}
                       onClick={() => {
                           setLevelFour(3)
                       }}>
                    <Typography>Платежно</Typography>
                </Paper>
            </Grid>

        </Grid>
    )
}

export default LevelFiveAkt;