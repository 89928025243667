import Grid from "@mui/material/Grid";
import {Autocomplete, Paper, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import {addPartnerToProject, createPartner, getPartnersByMaterial} from "../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";


function LevelDeliver({stage, group, good, service, selectedObject, levelDeliver, setLevelDeliver, isService, options, color}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [delivers, setDelivers] = React.useState([]);

    useEffect(() => {
        console.log('isService ', isService)
        console.log('service ', service)
        console.log('good ', good)
        getPartnersByMaterial({id: selectedObject}, navigate, accessToken, setAccessToken, stage, group, isService ? service : good, isService)
            .then(result => {
                if (isService) {
                    if (result.items && result.items.services) {
                        setDelivers(result.items.services)
                    } else {
                        setDelivers([])
                    }
                } else {
                    if (result.items && result.items.goods) {
                        setDelivers(result.items.goods)
                    } else {
                        setDelivers([])
                    }
                }

            })
    }, [stage, group, isService ? service : good, isService]);

    return (
        <Grid xs={11} container justifyContent={'space-between'} direction="column">

            <Grid item xs={9}>
                <Paper
                    elevation={6} className={"paper-style"}
                    style={{background: levelDeliver ? color : null, height: "auto"}}>

                    <Grid container spacing={3} justifyContent={'center'} style={{padding: 15, paddingLeft: 5, paddingRight: 5}}>
                        <Grid item xs={12}>
                            <Typography>Доставчик</Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <AutocompleteProps
                                autocompleteProperty={levelDeliver}
                                dataset={delivers}
                                setAutocompleteProperty={setLevelDeliver}
                                restApiAddPropsFunction={(name) => {
                                    createPartner(navigate, accessToken, setAccessToken, setLevelDeliver, name)
                                        .then((result) => {
                                            addPartnerToProject({id: selectedObject}, navigate,
                                                accessToken, setAccessToken, stage, group, good, result.id, isService ? 'service' : 'goods')
                                                .then(result => {
                                                    if (result && result.id) {
                                                        setLevelDeliver({
                                                            "id": result.id,
                                                            "name_partner": name
                                                        })
                                                    }

                                                })
                                        })
                                }}
                                title={"Доставчик"}
                                propName={"name_partner"}
                            />
                        </Grid>

                    </Grid>


                </Paper>
            </Grid>


        </Grid>

    )
}

export default LevelDeliver;