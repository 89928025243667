import {Grid} from "@mui/material";
import LevelTwoInvestorDeliver from "../../../levels/LevelTwoInvestorDeliver";
import React from "react";
import LevelFiveAvansAktDocs from "../../../levels/levelFive/LevelFiveAvansAktDocs";
import LevelFiveAvansAkt from "../../../levels/levelFive/LevelFiveAvansAkt";
import LevelFiveAkt from "../../../levels/levelFive/LevelFiveAkt";
import LevelDocuments from "../../../levels/LevelDocuments";
import TrackThreeInvestorAkt from "./TrackThreeInvestorAkt";
import TrackThreeInvestorAvans from "./TrackThreeInvestorAvans";

function TrackThreeInvestor({
                                selectedObject, service, levelDeliver, deliveryNum,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup,
                      stage, setStage,
                      group, setGroup,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                                act, setAct,
                      color, SERVICE, MATERIAL
                  }) {

    function computeDocName() {

        return `performance-act`;
    }

    return (
        <Grid container>

            <Grid item xs={4} sm={1.5}>
                <LevelFiveAvansAktDocs
                    levelTwo={levelTwo}
                    setLevelTwo={setLevelTwo}
                    setLevelFour={setLevelFour}
                    color={color}
                />
            </Grid>


            { levelTwo === 2 &&
                <Grid item xs={5} sm={3}>
                    <LevelFiveAvansAkt
                        selectedObject={selectedObject}
                        actPath={computeDocName()}
                        levelThree={levelThree}
                        setLevelThree={setLevelThree}
                        setLevelFour={setLevelFour}
                        act={act}
                        setAct={setAct}
                        color={color}
                    />
                </Grid>
            }

            { (levelTwo === 2) &&

                levelThree === 1 ?
                <Grid item xs={9} sm={2.5}>
                    <TrackThreeInvestorAkt
                        selectedObject={selectedObject}
                        actNumber={act}
                        levelFour={levelFour}
                        setLevelFour={setLevelFour}
                        isScanned={isScanned}
                        setScanned={setScanned}
                        isElectronic={isElectronic}
                        setElectronic={setElectronic}
                        isPhysical={isPhysical}
                        setPhysical={setPhysical}
                        color={color}
                    />
                </Grid>
                    :
                    levelThree === 0 ?
                <Grid item xs={9} sm={2.5}>
                    <TrackThreeInvestorAvans
                        service={service}
                        levelDeliver={levelDeliver}
                        deliveryNum={deliveryNum}
                        selectedObject={selectedObject}
                        levelFour={levelFour}
                        setLevelFour={setLevelFour}
                        isScanned={isScanned}
                        setScanned={setScanned}
                        isElectronic={isElectronic}
                        setElectronic={setElectronic}
                        isPhysical={isPhysical}
                        setPhysical={setPhysical}
                        color={color}
                    />
                </Grid>
                        :
                        null

            }

        </Grid>
    )
}
export default TrackThreeInvestor;