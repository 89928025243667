import Grid from "@mui/material/Grid";
import {Autocomplete, Paper, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import React from "react";
import Typography from "@mui/material/Typography";


function LevelServiceMaterial({serviceMaterial, levelTwo, setLevelTwo, setAssignee, setServiceMaterial, color, SERVICE, MATERIAL}) {

    return (
        <Grid xs={10} container justifyContent={'space-between'} direction="column">

            <Grid item xs={8}>
                <Paper
                    onClick={() => {
                        setServiceMaterial(SERVICE)
                        setLevelTwo(1)
                    }}
                    elevation={6} className={"paper-style"}
                    style={{background: serviceMaterial === SERVICE ? color : null, height: "auto"}}>

                    <Grid container spacing={3} justifyContent={'center'} style={{padding: 15,  paddingLeft: 5, paddingRight: 5}}>
                        <Grid item xs={12}>
                            <Typography>Услуга</Typography>
                        </Grid>

                        {
                            serviceMaterial === SERVICE &&
                            <Grid item xs={10}>
                                <Autocomplete
                                    onChange={(event) => {
                                        setAssignee(event.target.value)
                                    }}
                                    options={['Радо']}
                                    renderInput={(params) => (
                                        <TextField {...params} label={`Доставчик на ${serviceMaterial === SERVICE ? 'услуга' : 'материал'}`} />
                                    )}/>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>


            <Grid item xs={8}>
                <Paper
                    onClick={() => {
                        setServiceMaterial(MATERIAL)
                        setLevelTwo(1)
                    }}
                    elevation={6} className={"paper-style"}
                    style={{background: serviceMaterial === MATERIAL ? color : null, height: "auto"}}>

                    <Grid container spacing={3} justifyContent={'center'} style={{padding: 15,  paddingLeft: 5, paddingRight: 5}}>
                        <Grid item xs={12}>
                            <Typography>Материал</Typography>
                        </Grid>
                        {
                            serviceMaterial === MATERIAL &&
                            <Grid item xs={10}>
                                <Autocomplete
                                    onChange={(event) => {
                                        setAssignee(event.target.value)
                                    }}
                                    options={['Радо']}
                                    renderInput={(params) => (
                                        <TextField {...params} label={`Доставчик на ${serviceMaterial === SERVICE ? 'услуга' : 'материал'}`} />
                                    )}/>
                            </Grid>
                        }
                    </Grid>


                </Paper>
            </Grid>


            {/*
            <Grid item xs={9}>
                <Paper elevation={6} className={"paper-style"}
                       onClick={() => {
                           setLevelTwo(1)
                       }}
                       style={{height: 160, background: false && levelTwo === 1 ? color : null}}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12}>
                            <ToggleButtonGroup
                                color="primary"
                                value={serviceMaterial}
                                exclusive
                                onChange={(event, value) => {
                                    setServiceMaterial(event.target.value)
                                }}
                                aria-label="Platform"
                            >
                                <ToggleButton value={SERVICE}>Услуга</ToggleButton>
                                <ToggleButton value={MATERIAL}>Материал</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>


                        <Grid item xs={10}>
                            <Autocomplete
                                onChange={(event) => {
                                    setAssignee(event.target.value)
                                }}
                                options={['Радо']}
                                renderInput={(params) => (
                                    <TextField {...params} label={`Доставчик на ${serviceMaterial === SERVICE ? 'услуга' : 'материал'}`} />
                                )}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
*/}
        </Grid>

    )
}

export default LevelServiceMaterial;