import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import TextField from "@mui/material/TextField";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import Button from "@mui/material/Button";
import {Title} from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddEditWorkDialog({open, handleClose,
                              name, setName,
                              entity, setEntity,
                              costRate, setCostRate,
                               fira, setFira,
                              entityPrice, setEntityPrice,
                               onClick
}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let title;
    switch (open) {
        case 1:
            title = 'Труд';
            break;
        case 2:
            title = 'Механизация';
            break;
        case 3:
            title = 'Материали';
            break;
        case 4:
            title = 'Услуги';
            break;
    }

    return  (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <Grid container padding={3}>

                <Grid item xs={12}>
                    <Typography variant={'h5'}>
                        Добави {title}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={name}
                        onChange={(event) =>  {
                            setName(event.target.value)
                        }}
                        label="Име"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography>Мярка</Typography>
                    <AutocompleteProps
                        autocompleteProperty={entity}
                        dataset={[{name: "КВ.М"}, {name: "КГ"}, {name: "КУБ.М"}, {name: "ТОН"}, {name: "БР."}]}
                        setAutocompleteProperty={setEntity}
                        restApiAddPropsFunction={(name) => {
                        }}
                        title={""}
                        propName={"name"}
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={costRate}
                        onChange={(event) =>  {
                            setCostRate(event.target.value)
                        }}
                        label="Разход"
                    />
                </Grid>

                {open === 3 &&
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="client"
                            value={fira}
                            onChange={(event) => {
                                setFira(event.target.value)
                            }}
                            label="Фира"
                        />
                    </Grid>
                }


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={entityPrice}
                        onChange={(event) =>  {
                            setEntityPrice(event.target.value)
                        }}
                        label="Ед цена"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        margin="dense"
                        variant={'contained'}
                        id="done"
                        onClick={(event) =>  {
                            onClick()
                        }}
                    >
                        Запази
                    </Button>
                </Grid>

            </Grid>


        </Dialog>
    )
}
export default AddEditWorkDialog;