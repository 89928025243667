import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import DescriptionWorkComponent from "./DescriptionWorkComponent";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../services/api-fetch";
import {useLocalStorage} from "../../useLocalStorage";
import {getWorkingGroups, importWorkingGroupsFromFile} from "../../utils/NetworkUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function DescriptionWorkDialog({open, handleClose, selectedObject}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [tab, setTab] = useState(0);
    const [items, setItems] = useState([]);

    const [kssDialogOpen, setKssDialogOpen] = useState(false);

    useEffect(() => {
        getWorkingGroups({id: selectedObject}, navigate, accessToken, setAccessToken)
            .then((result) => {
                console.log('result.items ', result.items)

                if (result && result.items) {
                    for (const resultElement of result.items) {
                        resultElement.name = resultElement.name_group;
                        resultElement.title = resultElement.name_group;
                        resultElement.children = resultElement.subgroups;
                        resultElement.expanded = true;
                        for (const resultElementElement of resultElement.children) {
                            resultElementElement.name = resultElementElement.name_group;
                            resultElementElement.title = resultElementElement.name_group;
                            resultElementElement.children = resultElementElement.works;
                            resultElementElement.expanded = true;
                            for (const work of resultElementElement.children) {
                                work.title = work.name_work;
                                work.name = work.name_work;
                                work.entity = work.edm;
                                work.quantity = work.qty;
                            }
                        }
                    }
                    setItems(result.items)
                    console.log('result.items ', result.items)
                }


            }).catch((error) => {

            })
    }, [open])

    function reformatAndSend() {
        const formattedItems = items;
        if (formattedItems) {
            for (const resultElement of formattedItems) {
                resultElement.name_group = resultElement.title;
                resultElement.sub = resultElement.children;
                for (const resultElementElement of resultElement.sub) {
                    resultElementElement.name_group = resultElementElement.title;
                    resultElementElement.works = resultElementElement.children;
                    for (const work of resultElementElement.works) {
                        work.name_work = work.title;
                        work.edm = work.entity;
                        work.qty = work.quantity;
                    }
                }
            }
            setItems(formattedItems)
            importWorkingGroupsFromFile({id: selectedObject}, formattedItems, navigate, accessToken, setAccessToken)
                .then(() => {
                    //setKssDialogOpen(true)
                })
        }
    }

    return  (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            maxWidth={'md'}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Опис подлежащи за изпълнение работи
                    </Typography>

                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={(event) => {
                            event.stopPropagation()
                            event.nativeEvent.stopImmediatePropagation();
                            console.log(items)
                            reformatAndSend()
                        }}
                        aria-label="close"
                    >
                        <Button variant={'contained'}>Запис</Button>

                    </IconButton>
                </Toolbar>
            </AppBar>

            <DescriptionWorkComponent
                selectedObject={selectedObject}
                items={items}
                setItems={setItems}
                kssDialogOpen={kssDialogOpen}
                setKssDialogOpen={setKssDialogOpen}/>

        </Dialog>
    )
}
export default DescriptionWorkDialog;