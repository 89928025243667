import { Routes, Route, Navigate } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import {createTheme, ThemeProvider} from "@mui/material";
import HomeScreen from "./screens/HomeScreen";

function App() {
    // Or Create your Own theme:
    const theme = createTheme({
        palette: {
            primary: {
                main: '#ffa033'
            }
        }
    });
  return (
      <ThemeProvider theme={theme}>

          <div className="App">
            <Routes>
              <Route exact path="/" element={<Navigate to="/login"/>} />
              <Route path="/login" element={<LoginScreen />} />
                <Route path="/home" element={<HomeScreen />} />
            </Routes>
          </div>
      </ThemeProvider>
  );
}

export default App;
