import {Grid} from "@mui/material";
import LevelTwoInvestorDeliver from "../../../levels/LevelTwoInvestorDeliver";
import React from "react";
import LevelServiceMaterial from "../../../levels/LevelServiceMaterial";
import LevelStageGroup from "../../../levels/LevelStageGroup";
import LevelFiveAvansAktDocs from "../../../levels/levelFive/LevelFiveAvansAktDocs";
import LevelFiveAvansAktCert from "../../../levels/levelFive/LevelFiveAvansAktCert";
import TrackThreeDeliverAvans from "./TrackThreeDeliverAvans";
import TrackThreeDeliverAkt from "./TrackThreeDeliverAkt";
import TrackThreeDeliverCert from "./TrackThreeDeliverCert";
import LevelDeliver from "../../../levels/LevelDeliver";
import LevelFiveAvansDeliveryDocs from "../../../levels/levelFive/LevelFiveAvansAktDeliveryDocs";
import LevelFiveAvansDeliveryNum from "../../../levels/levelFive/LevelFiveAvansAvansDeliveryNum";
import LevelFiveAvansDeliveryNumDocs from "../../../levels/levelFive/LevelFiveAvansAvansDeliveryNumDocs";

function TrackThreeDeliverMaterial({
                       selectedObject, material, service,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup,
                      stage, setStage,
                      group, setGroup,
                      levelDeliver, setLevelDeliver,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                                act, setAct,
                      color, SERVICE, MATERIAL
                  }) {

    return (
        <Grid container>

            <Grid item xs={4} sm={3.5}>
                <LevelDeliver
                    isService={serviceMaterial === SERVICE}
                    selectedObject={selectedObject}
                    levelDeliver={levelDeliver}
                    setLevelDeliver={setLevelDeliver}
                    stage={stage}
                    group={group}
                    good={material}
                    service={service}
                    options={[{name: 'Радо'}, {name: 'Емо'}]}
                    color={color}/>
            </Grid>


            {
                /*
                    Аванс, Доставка, Документи
                */
                levelDeliver !== null ?
                    <Grid item xs={4} sm={2.3}>
                        <LevelFiveAvansDeliveryDocs
                            levelTwo={levelTwo}
                            setLevelTwo={setLevelTwo}
                            setLevelFour={() => {}}
                            color={color}
                        />
                    </Grid>
                    :
                    null
            }

            {
                (levelDeliver !== null && levelTwo === 2) &&
                <Grid item xs={8} sm={6.2}>
                    <LevelFiveAvansDeliveryNumDocs
                        selectedObject={selectedObject}
                        levelTwo={levelTwo}
                        levelThree={levelThree}
                        levelFour={levelFour}
                        setLevelTwo={setLevelTwo}
                        setLevelThree={setLevelThree}
                        setLevelFour={setLevelFour}
                        material={material}
                        stage={stage}
                        group={group}
                        assignee={assignee}
                        levelDeliver={levelDeliver}
                        isScanned={isScanned}
                        setScanned={setScanned}
                        isElectronic={isElectronic}
                        setElectronic={setElectronic}
                        isPhysical={isPhysical}
                        setPhysical={setPhysical}
                        color={color}
                    />
                </Grid>
            }


        </Grid>
    )
}
export default TrackThreeDeliverMaterial;