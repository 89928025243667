import {CommonFetch} from "../services/api-fetch";

export const getObjects = (navigate, accessToken, setAccessToken, setObjects, setSelectedObject) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
                setObjects(result.items)
                if (result.items && result.items.length > 0) {
                    //setSelectedObject(result.items[0].id)
                }
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}


export const getWorkingGroups = (department, navigate, accessToken, setAccessToken) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getKss = (department, navigate, accessToken, setAccessToken) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/getKSS?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        stage: "offer"
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export const selectSec = (department, navigate, accessToken, setAccessToken) => {

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/selectSEC?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const selectFromProject = (department, navigate, accessToken, setAccessToken) => {

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/selectFromProject?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const importWorkingGroupsFromFile = (department, data, navigate, accessToken, setAccessToken) => {
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/importFile?`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            token: accessToken,
            data: data
        })
    });
}

export const getReference = (department, etap, group, navigate, accessToken, setAccessToken) => {
    console.log('getWorkingGroups dep ', department)
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getProjectDocs?`+ new URLSearchParams({
        project_id: department,
        id_etap: etap ? etap.id : 0,
        id_group: group ? group.id : group,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export const getMaterials = (department, navigate, accessToken, setAccessToken, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocGoods?`+ new URLSearchParams({
        project_id: department.id,
        id_etap: stageId || 0,
        id_group: groupId || 0,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getServices = (department, navigate, accessToken, setAccessToken, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocServices?`+ new URLSearchParams({
        project_id: department.id,
        id_etap: stageId || 0,
        id_group: groupId || 0,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export const getPartnersByMaterial = (department, navigate, accessToken, setAccessToken, stage, group, good, isService) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;
    const goodId = good ? good.id : 0;

    return CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/docs/getProjectPartners?` + new URLSearchParams({
        project_id: department.id,
        id_etap: stageId || 0,
        id_group: groupId || 0,
        id_good: goodId || 0,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const addPartnerToProject = (department, navigate, accessToken, setAccessToken, stage, group, good, partnerId, typePartner='goods') => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;
    const goodId = good ? good.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/addPartnerToProject`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            id_etap: stageId || 0,
            id_group: groupId || 0,
            id_good: goodId || 0,
            partner_id: partnerId,
            type_partner: typePartner,
            token: accessToken
        })
    });
}


export const addMaterial = (department, navigate, accessToken, setAccessToken, name, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/addGoods`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            good_name: name,
            id_etap: stageId || 0,
            id_group: groupId || 0,
            token: accessToken
        })
    });
}


export const addService = (department, navigate, accessToken, setAccessToken, name, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/addServices`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            service_name: name,
            id_etap: stageId || 0,
            id_group: groupId || 0,
            token: accessToken
        })
    });
}


export const addGroup = (department, navigate, accessToken, setAccessToken, setStage, setGroup, name, idAworkgroup="0") => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup":idAworkgroup,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code":"1",
            "name_group":name,
            "id_project":department.id,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                const value = {
                    "id": result && result.id ? result.id : 0,
                    "name_group": name,
                    "status":"0"
                };

                if (idAworkgroup === "0") {
                    setStage(value);
                } else {
                    setGroup(value)
                }
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}


export const createPartner = (navigate, accessToken, setAccessToken, setAssignee, name) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/add?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "name_firm": name,
            "phone":"",
            "contact_person":name,
            "id_category":2,
            "token": accessToken
        })
    });
}




export const getDocState = (department, navigate, accessToken, setAccessToken, docName) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocState?`+ new URLSearchParams({
        project_id: department.id,
        doc: docName,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}



export const getDocNums = (department, navigate, accessToken, setAccessToken, docName) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocNumbers?`+ new URLSearchParams({
        project_id: department.id,
        doc: docName,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}



export const setDocState = (department, navigate, accessToken, setAccessToken, docName, file, type = "physical") => {
    let finalBody = null;

    if (type === "physical") {
        finalBody = JSON.stringify({
            doc: docName,
            token: accessToken,
            project_id: department.id,
            ...file
        });
    } else {
        finalBody = new FormData();
        finalBody.append("doc", docName);
        finalBody.append("token", accessToken);
        finalBody.append("project_id", department.id);
        finalBody.append(type, file, file.name);
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/setDocState`, {
        method: "POST",
        mode: 'cors',
        headers: type === "physical" ? {
            "Content-Type": "application/json",
        } : {},
        body: finalBody
    });
}



export const changePercent = (id, type, newPercent, navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id": id
    }
    switch (type) {
        case 1:
            data.perc_work = newPercent;
            break;
        case 2:
            data.perc_mech = newPercent;
            break;
        case 3:
            data.perc_goods = newPercent;
            break;
        case 4:
            data.perc_services = newPercent;
            break;
        case 5:
            data.perc_work_profit = newPercent;
            break;
        case 6:
            data.perc_mech_profit = newPercent;
            break;
        case 7:
            data.perc_good_profit = newPercent;
            break;
        case 8:
            data.perc_services_profit = newPercent;
            break;
        case 9:
            data.perc_goods_store = newPercent;
            break;
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/updatePercents`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}
export const changePercentWork = (id, type, newPercent, navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id_work": id
    }
    switch (type) {
        case 1:
            data.perc_work_add_costs = newPercent;
            break;
        case 2:
            data.perc_mech_add_costs = newPercent;
            break;
        case 3:
            data.perc_goods_accsessory = newPercent;
            break;
        case 4:
            data.perc_services_add_costs = newPercent;
            break;
        case 5:
            data.perc_work_profit = newPercent;
            break;
        case 6:
            data.perc_mech_profit = newPercent;
            break;
        case 7:
            data.perc_good_profit = newPercent;
            break;
        case 8:
            data.perc_services_profit = newPercent;
            break;
        case 9:
            data.perc_goods_store = newPercent;
            break;
        case 10:
            data.perc_services_add_costs = newPercent;
            break;
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/updatePercents`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const updateDetail = (id, name, entity, qty, perc_fira, cost_rate, price, amount, navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id": id,
        "name": name,
        "entity": entity,
        "qty": qty,
        "perc_fira": perc_fira,
        "cost_rate": cost_rate,
        "price": price,
        "amount": amount
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/updateDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}


export const addAnalysisDetail = (idSmrWorks, type,
                                  name, entity, qty, perFira,
                                  costRate, price, amount,
                                  navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id_smr_works": idSmrWorks,
        "id_detail_type": type,
        "name": name,
        "entity": entity,
        "qty": qty,
        "perc_fira": perFira,
        "cost_rate": costRate,
        "price": price,
        "amount": amount
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/addDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const addAnalysisDetailFromScratch = (id, type,
                                  name, entity, qty, perFira,
                                  costRate, price, amount,
                                  navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id_awork": id,
        "id_detail_type": type,
        "name": name,
        "entity": entity,
        "qty": qty,
        "perc_fira": perFira,
        "cost_rate": costRate,
        "price": price,
        "amount": amount
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/addDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const updateAnalysisDetailFromScratch = (id,
                                  name, entity, qty, perFira,
                                  costRate, price, amount,
                                  navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id": id,
        "name": name,
        "entity": entity,
        "qty": qty,
        "perc_fira": perFira,
        "cost_rate": costRate,
        "price": price,
        "amount": amount
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/updateDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const addWork = (groupId, name, code, entity, quantity,
                                  navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id_aworkgroup": groupId,
        "code": code,
        "name_work": name,
        "edm": entity,
        "qty": quantity,
        "price": 0,
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/add`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}
export const updateAwork = (id, name, entity, quantity, price, idAworkgroup, navigate, accessToken, setAccessToken) => {

    console.log('entity', entity)
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/update`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token": accessToken,
            "id": id,
            "id_aworkgroup": idAworkgroup,
            "code":"",
            "name_work":name,
            "edm":entity && entity.name ? entity.name : typeof entity === 'string' ? entity : "",
            "qty":quantity,
            "price":price
        })
    });
}
export const deleteAwork = (id, navigate, accessToken, setAccessToken) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/delete`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token": accessToken,
            "id": id,
        })
    });
}


export const addGroupKss = (department, navigate, accessToken, setAccessToken,
                         name, idAworkgroup="0") => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup":idAworkgroup,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code":"1",
            "name_group":name,
            "id_project":department.id,
            "token": accessToken
        })
    });
}


export const addObject = (navigate, accessToken, setAccessToken, name, shortName) => {
   return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id":"0",
            "code":"1",
            "project_name": name,
            "shortprjname": shortName,
            "status":"0",
            "token": accessToken
        })
    });
}

export const getFile = (navigate, accessToken, setAccessToken, id) => {
    const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${id}&token=${accessToken}`;
    window.open(url, '_blank').focus();
}