import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {AutocompleteProps} from "../../../inputs/AutocompleteProps";
import LevelFiveAvansDeliveryNum from "./LevelFiveAvansAvansDeliveryNum";
import TrackThreeDeliverAvans from "../../tracks/trackThree/deliver/TrackThreeDeliverAvans";

function LevelFiveAvansDeliveryNumDocs({selectedObject, levelDeliver, levelTwo, levelThree, levelFour, setLevelTwo, setLevelThree, setLevelFour,
                                            stage, group,
                                            material, assignee,
                                           isScanned, setScanned,
                                           isElectronic, setElectronic,
                                           isPhysical, setPhysical,
                                           color}) {
    const [deliveryNum, setDeliveryNum] = React.useState(null);

    function computeDocName() {
        console.log('stage ', stage)
        console.log('group ', group)
        console.log('material ', material)
        console.log('levelDeliver ', levelDeliver)
        const stageId = stage ? stage.id : 0;
        const groupId = group ? group.id : 0;
        const goodId = material ? material.id : 0;
        const assigneeId = levelDeliver ? levelDeliver.id : 0;

        return `performance-${stageId}-${groupId}-${goodId}-${assigneeId}-supply`;
    }

    return (
        <Grid container>

            <Grid item xs={6} sm={6}>
                <LevelFiveAvansDeliveryNum
                    deliveryNum={deliveryNum}
                    setDeliveryNum={setDeliveryNum}
                    deliveryPath={computeDocName()}
                    selectedObject={selectedObject}
                    levelTwo={levelThree}
                    setLevelTwo={setLevelThree}
                    setLevelFour={() => {}}
                    color={color}
                />
            </Grid>


            {
                (levelThree === 0 || levelThree === 1) ?
                    <Grid item xs={6} sm={6}>
                        <TrackThreeDeliverAvans
                            deliveryNum={deliveryNum}
                            setDeliveryNum={setDeliveryNum}
                            selectedObject={selectedObject}
                            material={material}
                            assignee={assignee}
                            levelDeliver={levelDeliver}
                            stage={stage}
                            group={group}
                            levelThree={levelThree}
                            levelFour={levelFour}
                            setLevelFour={setLevelFour}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}
                            isCertificateEnabled={levelThree === 1 ? true : null}
                            color={color}
                        />
                    </Grid>
                    :
                    null
            }

        </Grid>
    )
}

export default LevelFiveAvansDeliveryNumDocs;