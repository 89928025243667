import Grid from "@mui/material/Grid";
import LevelThreeMost from "../../levels/levelThree/LevelThreeMost";
import LevelFourOffers from "../../levels/levelFour/LevelFourOffers";
import LevelDocuments from "../../levels/LevelDocuments";
import React from "react";
import LevelThree from "../../levels/levelThree/LevelThree";
import LevelThreeZeroOneOfferInvector from "../../levels/LevelThreeZeroOne/LevelThreeZeroOneOfferInvestor";
import LevelServiceMaterial from "../../levels/LevelServiceMaterial";
import LevelStageGroup from "../../levels/LevelStageGroup";
import LevelThreeNoZBUT from "../../levels/levelThree/LevelThreeNoZBUT";
import LevelThreeZeroOneOfferInvestorNoZBUT from "../../levels/LevelThreeZeroOne/LevelThreeZeroOneOfferInvestorNpZBUT";
import LevelServiceMaterialAutocomplete from "../../levels/LevelServiceMaterialAutocomplete";
import TrackThreeDeliverMaterial from "../trackThree/deliver/TrackThreeDeliverMaterial";
import TrackThreeDeliverService from "../trackThree/deliver/TrackThreeDeliverService";
import LevelDeliver from "../../levels/LevelDeliver";

function TrackTwoDeliver({
                             selectedObject,
                              levelOneTwo,levelThree, levelFour,
                              setLevelThree, setLevelFour,
                             material, good, setMaterial,
                             levelTwo, setLevelTwo,
                              isDocuments, setDocuments,
                            assignee, levelDeliver, setLevelDeliver,
                             serviceMaterial,
                             setAssignee, setServiceMaterial,
                              isScanned, setScanned,
                             levelStageGroup, stage, setStage, group, setGroup,
                              isElectronic, setElectronic,
                              isPhysical, setPhysical,
                              color,SERVICE,MATERIAL
                          }) {
    function computeDocName() {
        let levelFourText;
        switch (isDocuments) {
            case 0:
                levelFourText = 'contract';
                break;
            case 1:
                levelFourText = 'kss';
                break;
            case 2:
                levelFourText = 'graphic';
                break;
            case 3:
                levelFourText = 'paysgraphict';
                break;
            case 4:
                levelFourText = 'agreement';
                break;
            case 5:
                levelFourText = 'annex';
                break;
        }
        const stageId = stage ? stage.id : 0;
        const groupId = group ? group.id : 0;
        const goodId = serviceMaterial === MATERIAL ?
                    material ? material.id : 0
                    :
                    good ? good.id : 0;

        const assigneeId = levelDeliver ? levelDeliver.id : 0;

        return `contract-${stageId}-${groupId}-${goodId}-${assigneeId}-${levelFourText}`;
    }

    return (
        <Grid container>


            {
                /* ----------- 3.-----------*/
            }
            <Grid item xs={4} sm={2}>
                <LevelStageGroup
                    selectedObject={selectedObject}
                    levelStageGroup={levelStageGroup}
                    stage={stage}
                    setStage={setStage}
                    group={group}
                    setGroup={setGroup}
                    color={color}/>
            </Grid>

            {
                /* ----------- 3.-----------*/
            }
            <Grid item xs={5} sm={3}>
                <LevelServiceMaterialAutocomplete
                    stage={stage}
                    group={group}
                    selectedObject={selectedObject}
                    serviceMaterial={serviceMaterial}
                    levelTwo={levelTwo}
                    setLevelTwo={() => {}}
                    setAssignee={setAssignee}
                    setServiceMaterial={setServiceMaterial}
                    material={material}
                    setMaterial={setMaterial}
                    color={color}
                    SERVICE={SERVICE}
                    MATERIAL={MATERIAL}
                />
            </Grid>


            {serviceMaterial &&
                <Grid item xs={4} sm={2}>
                    <LevelDeliver
                        isService={serviceMaterial === SERVICE}
                        selectedObject={selectedObject}
                        levelDeliver={levelDeliver}
                        setLevelDeliver={setLevelDeliver}
                        stage={stage}
                        group={group}
                        good={material}
                        options={[{name: 'Радо'}, {name: 'Емо'}]}
                        color={color}/>
                </Grid>
            }

            {
                /* ----------- 5.-----------*/
            }


            {
                (levelDeliver) &&
                <Grid item xs={5} sm={2}>
                    {
                        /*
                        Договор, План сметка, График, График плащания, Споразумение ЗБУТ, Анекс, Документи
                        */
                    }
                    {
                        serviceMaterial === MATERIAL ?
                            <LevelThreeNoZBUT
                                levelFour={levelFour}
                                setLevelFour={setLevelFour}
                                setDocuments={setDocuments}
                                color={color}/>
                            :
                            <LevelThree
                                levelFour={levelFour}
                                setLevelFour={setLevelFour}
                                setDocuments={setDocuments}
                                color={color}/>
                    }
                </Grid>
            }

            {
                /* ----------- 6.-----------*/
            }
            {
                /*
                Договор, План сметка, График, График плащания, Споразумение ЗБУТ, Анекс
                */
            }
            {
                levelFour === 6 &&
                <Grid item xs={5} sm={2}>
                    {
                        serviceMaterial === MATERIAL ?
                            <LevelThreeZeroOneOfferInvestorNoZBUT
                                levelFourDocuments={isDocuments}
                                setLevelFourDocuments={setDocuments}
                                color={color}/>
                            :
                            <LevelThreeZeroOneOfferInvector
                                levelFourDocuments={isDocuments}
                                setLevelFourDocuments={setDocuments}
                                color={color}/>
                    }
                </Grid>
            }



            {/* ----------- 7.-----------*/}
            {
                (levelFour === 6 && isDocuments !== null) ?
                    <Grid item xs={5} sm={1}>
                        {/*
                                Физически, Електронен, Сканиран
*/}
                        <LevelDocuments
                            selectedObject={selectedObject}
                            docName={computeDocName()}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}/>
                    </Grid>
                    :
                    null
            }
        </Grid>
    );


}
export default TrackTwoDeliver;