import List from "@mui/material/List";
import {ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';

function WorkTypeComponent() {

    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(
    [
                {
                    name: 'Временно строителство',
                    sub: [
                        {
                            name: 'Временно строителство',
                            works: [
                                {
                                    code: 1,
                                    name: 'Разчистване на строителна площадка',
                                    entity: 'кв.м.',
                                    quantity: 1650,
                                    entity_price: 8.64,
                                    total_value: 14256
                                }
                            ]
                        }
                    ]

                },
                {
                    name: 'Груб стоеж',
                    sub: [
                        {
                            name: 'Зидария',
                            works: [
                                {
                                    code: 1,
                                    name: 'Зидане',
                                    entity: 'кв.м.',
                                    quantity: 1650,
                                    entity_price: 8.64,
                                    total_value: 14256
                                },
                                {
                                    code: 2,
                                    name: 'Зидане',
                                    entity: 'кв.м.',
                                    quantity: 1650,
                                    entity_price: 8.64,
                                    total_value: 14256
                                }
                            ]
                        }
                    ]

                },

            ]
        )
    }, [])

    return (
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell align="right">Код</TableCell>
                    <TableCell align="right">Вид работа</TableCell>
                    <TableCell align="right">Едм</TableCell>
                    <TableCell align="right">Кол</TableCell>
                    <TableCell align="right">Ед. цена</TableCell>
                    <TableCell align="right">Стойност</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    items && items.map((top) => (
                        <React.Fragment>
                            <TableRow>
                                <TableCell style={{background: '#63fff2'}} colSpan={7} align={'center'}><h3><b>{top.name}</b></h3></TableCell>
                            </TableRow>
                            {top && top.sub && top.sub.map((item) => (
                                <React.Fragment>
                                    <TableRow style={{background: '#d5d5d5'}}>
                                        <TableCell align={'center'} colSpan={7}><b>{item.name}</b></TableCell>
                                    </TableRow>
                                    {
                                        item.works.map((work) => (
                                            <TableRow
                                                key={work.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row"></TableCell>
                                                <TableCell align="right">{work.code}</TableCell>
                                                <TableCell align="right">{work.name}</TableCell>
                                                <TableCell align="right">{work.entity}</TableCell>
                                                <TableCell align="right">{work.quantity}</TableCell>
                                                <TableCell align="right">{work.entity_price}</TableCell>
                                                <TableCell align="right">{work.total_value}</TableCell>
                                            </TableRow>
                                        ))
                                    }

                                </React.Fragment>
                            ))}

                        </React.Fragment>

                    ))

                }

            </TableBody>
        </Table>
    )
}
export default WorkTypeComponent;