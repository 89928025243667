import {Button} from "@mui/material";
import * as React from "react";

function AddOneMoreButton({upload, row, partner, element}) {
    return (false &&
        <Button
            component={"label"}>Качи още
            <input
                type="file"
                onChange={(event => {
                    upload(event, row, partner, element.path)
                })}
                hidden
            />
        </Button>
    );
}
export default AddOneMoreButton;