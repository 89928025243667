import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect} from "react";
import {AutocompleteProps} from "../../../inputs/AutocompleteProps";
import {getDocNums, getPartnersByMaterial} from "../../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";

function LevelFiveAvansDeliveryNum({levelTwo, setLevelTwo, setLevelFour, color,
                                       deliveryPath, selectedObject,
                                       deliveryNum, setDeliveryNum}) {

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [deliveryItems, setDeliveryItems] = React.useState([]);


    useEffect(() => {
        getDocNums({id: selectedObject}, navigate, accessToken, setAccessToken, deliveryPath)
            .then(result => {
                const deliveries = result.items;
                deliveries.forEach((element, index) => {
                    const newElement = element;
                    newElement.doc_no = newElement.doc_no+"";
                    deliveries[index] = newElement;
                });

                setDeliveryItems(result.items)
            })
    }, [deliveryPath, levelTwo])

    return (
        <Grid container>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelTwo === 0 ? color : null, padding: 10}}
                       onClick={() => {
                           setLevelTwo(0)
                           setLevelFour(null)
                       }}>
                    <Typography>Аванс</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelTwo === 1 ? color : null, padding: 10}}
                       onClick={() => {
                           setLevelTwo(1)
                           setLevelFour(null)
                       }}>
                    <Grid container>
                        <Grid item>
                            <Typography>Доставка №</Typography>
                         </Grid>

                        {
                            levelTwo === 1 &&
                            <Grid item xs={12}>

                                <AutocompleteProps
                                    autocompleteProperty={deliveryNum}
                                    dataset={deliveryItems}
                                    setAutocompleteProperty={setDeliveryNum}
                                    restApiAddPropsFunction={(name) => {
                                    }}
                                    title={"Доставка №"}
                                    propName={"doc_no"}
                                />
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>

        </Grid>
    )
}

export default LevelFiveAvansDeliveryNum;