import {Button, TableCell, TableRow} from "@mui/material";
import Grid from "@mui/material/Grid";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import * as React from "react";

function EmptyGroupRow({style, setGroupOpen, topIndex}) {
    return (
        <TableRow style={{background: '#d5d5d5'}}>
            <TableCell width="10%" style={style.tableCell}></TableCell>
            <TableCell width="45%" style={style.tableCell}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>

                    <Grid item>
                        <span style={{marginRight: 10}}></span>
                    </Grid>

                    <Grid item>
                        <Button onClick={() => {
                            setGroupOpen({topIndex: topIndex, subIndex: 0})
                        }}
                                style={{padding: 5, marginLeft: 20}}>
                            <ControlPointIcon fontSize={'medium'} sx={{color: 'black'}}/>
                        </Button>
                    </Grid>

                </Grid>
            </TableCell>
            <TableCell width="7%" style={style.tableCell}></TableCell>
            <TableCell width="10%" style={style.tableCell}></TableCell>
            <TableCell width="10%" style={style.tableCell}></TableCell>
            <TableCell width="15%" style={style.tableCell}></TableCell>


        </TableRow>
    )
}
export default EmptyGroupRow;