import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {ListItemButton} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Group} from "@mui/icons-material";
import moment from "moment";
import bg from 'moment/locale/bg'
import {getFile} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import DeleteIcon from '@mui/icons-material/Delete';
import PDFRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/pdf";
import PNGRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/png";
import DocViewer from "@cyntler/react-doc-viewer";
import JPGRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/gif";
import MSDocRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/msdoc";
import {CommonFetch} from "../services/api-fetch";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AttachmentDialogComponent({viewFile, open, handleClose,
                                       id, setRandomToRefresh}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [isImage, setIsImage] = useState(false);

    function checkImage(url) {
        let s = document.createElement("IMG");
        s.src = url
        s.onerror = function(){
            setIsImage(false)
        }
        s.onload = function(){
            setIsImage(true)
        }

    }

    useEffect(() => {
        checkImage(viewFile)
    }, [viewFile])

    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Преглед
                    </Typography>
                </Toolbar>
            </AppBar>

            {
                id &&             <Grid container justifyContent={'flex-end'}>
                <span style={{
                    height: 25,
                    width: 25,
                    marginRight: 20,
                    marginTop: 10,
                    borderRadius: "50%",
                    backgroundColor: 'transparent',
                    border: `1px solid #ccc`,
                    cursor: "pointer"
                }}
                      onClick={() => {
                          if (window.confirm('Наиситна ли искате да изтриете документа?')) {
                              CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/docs/deleteDoc?` + new URLSearchParams({
                                  id: id,
                                  token: accessToken
                              }), {
                                  method: "POST",
                                  mode: 'cors',
                                  headers: {
                                      "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify({
                                      id: id,
                                  })
                              })
                                  .then((result) => {
                                      if (setRandomToRefresh) {
                                          setRandomToRefresh(Math.random())
                                      }
                                  });
                          handleClose();
                      }
                      }}>
                    <DeleteIcon/>
                </span>
                </Grid>
            }


            {
                isImage ?
                    <img style={{maxWidth: '100%',
                        'maxHeight': '100vh',
                        margin: 'auto'}} src={viewFile} alt={'Изображение'}/>
                    :
                    <DocViewer documents={[
                        {uri: viewFile}
                    ]} pluginRenderers={[PDFRenderer, PNGRenderer, JPGRenderer, MSDocRenderer]} />
            }

        </Dialog>
    )
}
export default AttachmentDialogComponent;