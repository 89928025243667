import * as React from 'react';
import {useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {Collapse, ListItemButton, Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import moment from "moment";
import bg from 'moment/locale/bg'
import {getReference} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import LevelStageGroup from "./levels/LevelStageGroup";
import LevelDocumentsForRef from "./levels/LevelDocumentsForRef";
import ReferenceCard from "./ReferenceCard";
import ReferenceCardGoods from "./ReferenceCardGoods";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ReferenceComponent({department, open, handleClose, color}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [stage, setStage] = React.useState(null);
    const [group, setGroup] = React.useState(null);

    const [services, setServices] = React.useState(null);
    const [goods, setGoods] = React.useState(null);

    const [openId, setOpenId] = React.useState(null);
    const [randomRefresh, setRandomToRefresh] = React.useState(0);


    useEffect(() => {
        let prom = getReference(department, stage, group, navigate, accessToken, setAccessToken)
            if (prom) {
                prom.then((result) => {
                    setServices(result.items.services)
                    setGoods(result.items.goods)
                });
            }
    }, [stage, group, randomRefresh]);

    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Справка
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container justifyContent={'flex-end'} style={{padding: 10}}>
                <Grid item xs={4} sm={2}>
                    <LevelStageGroup
                        selectedObject={department}
                        levelStageGroup={null}
                        stage={stage}
                        setStage={setStage}
                        group={group}
                        setGroup={setGroup}
                        color={color}/>
                </Grid>

                <Grid item xs={10} sm={10}>
                    <Paper style={{padding: 10}} elevation={6}>
                        Услуги
                        <ReferenceCard
                            department={department}
                            open={open}
                            setRandomToRefresh={setRandomToRefresh}
                            stage={stage}
                            group={group}
                            items={services}
                            openId={openId}
                            setOpenId={setOpenId}/>

                    </Paper>

                    <Paper style={{padding: 10}} elevation={6}>
                        Материали
                        <ReferenceCardGoods
                            department={department}
                            open={open}
                            setRandomToRefresh={setRandomToRefresh}
                            stage={stage}
                            group={group}
                            items={goods}
                            openId={openId}
                            setOpenId={setOpenId}/>

                    </Paper>
                </Grid>

             {/*   <Grid item xs={10} sm={8}>
                    <Paper style={{marginTop: 15, padding: 10}} elevation={6}>
                        <Typography>Материали</Typography>
                        <ReferenceCard
                            department={department}
                            open={open}
                            items={goods}
                            openId={openId}
                            setOpenId={setOpenId}/>

                    </Paper>
                </Grid>*/}
            </Grid>
        </Dialog>
    )
}
export default ReferenceComponent;