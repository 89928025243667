import {TableCell, TableRow} from "@mui/material";
import * as React from "react";
import {changePercent, changePercentWork} from "../../utils/NetworkUtils";
import ChangePriceDialog from "./ChangePriceDialog";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";

function ProfitCell({filter, profitNum, profitPercent, totalItems, additionalCost, dostSkladovi, workId, topItem, setTopItem, setForceRefresh, type}) {
    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };
    let navigate = useNavigate();
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [profitDialog, setProfitDialog] = useState(false);
    const [profitNumState, setProfitNumState] = useState(profitNum || 0);
    return (
        <React.Fragment>

            <TableRow
                key={'profit-row'}
            >
                <TableCell component="th" scope="row" style={style.tableCell}>
                    <b>Печалба</b>
                </TableCell>
                <TableCell align="right" style={style.tableCell}>%</TableCell>
                <TableCell align="right" style={style.tableCell} onClick={() => {
                    setProfitNumState(profitPercent);
                    setProfitDialog(true)
                }}>{profitPercent % 1 !==0 ? profitPercent.toFixed(2) : profitPercent}%</TableCell>
                {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                <TableCell style={style.tableCell} align="right">
                    {(totalItems + additionalCost + dostSkladovi).toFixed(2)
                    .toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</TableCell>
                <TableCell align="right" style={style.tableCell}>{
                    profitNum ?
                        profitNum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : null}
                </TableCell>
            </TableRow>


        <ChangePriceDialog
            open={profitDialog}
            handleClose={() => setProfitDialog(false)}
            label={'Печалба'}
            item={profitNumState}
            setItem={setProfitNumState}
            onClick={() => {
                changePercentWork(workId, type+4, Number(profitNumState), navigate, accessToken, setAccessToken)
                setProfitDialog(false)
                setForceRefresh(Math.random())
            }}/>
        </React.Fragment>

    )
}
export default ProfitCell;