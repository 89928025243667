export async function CommonFetch(navigate, setAccessToken, url, options, headers) {
    try {
        const response = await fetch(url, {
            ...options,
            ...headers,
            accept: 'application/json',
        })


        const responseJson = await response.json()
        if (response.status === 401 || (responseJson.message && responseJson.message === 'Invalid Token!')) {
            setAccessToken(null)
            navigate('/login')
            return;
        }
        return responseJson;

    }
    catch (e) {
        console.log('fetch error ', url, ': ',  e)
    }
}