import {Grid} from "@mui/material";
import React from "react";
import LevelFiveAvansDeliveryDocs from "../../../levels/levelFive/LevelFiveAvansAktDeliveryDocs";
import LevelFiveAvansAkt from "../../../levels/levelFive/LevelFiveAvansAkt";
import TrackThreeDeliverAvans from "./TrackThreeDeliverAvans";
import TrackThreeDeliverServiceAvans from "./TrackThreeDeliverServiceAvans";
import LevelDeliver from "../../../levels/LevelDeliver";

function TrackThreeDeliverService({
                                      selectedObject, service,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup,
                      stage, setStage,
                      group, setGroup,
                      levelDeliver, setLevelDeliver,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                                act, setAct,
                      color, SERVICE, MATERIAL
                  }) {

    function computeDocName() {
        const stageId = stage ? stage.id : 0;
        const groupId = group ? group.id : 0;
        const goodId = service ? service.id : 0;
        const assigneeId = levelDeliver ? levelDeliver.id : 0;

        return `performance-${stageId}-${groupId}-${goodId}-${assigneeId}-act`;
    }

    return (
        <Grid container>

            <Grid item xs={4} sm={3.5}>
                <LevelDeliver
                    isService={serviceMaterial === SERVICE}
                    selectedObject={selectedObject}
                    levelDeliver={levelDeliver}
                    setLevelDeliver={setLevelDeliver}
                    stage={stage}
                    group={group}
                    good={service}
                    service={service}
                    options={[{name: 'Радо'}, {name: 'Емо'}]}
                    color={color}/>
            </Grid>


            {
            /*
                Аванс, Доставка, Документи
            */
            }
            {
                levelDeliver !== null ?
                <Grid item xs={4} sm={2.5}>
                    <LevelFiveAvansDeliveryDocs
                        levelTwo={levelTwo}
                        setLevelTwo={setLevelTwo}
                        setLevelFour={() => {}}
                        color={color}
                    />
                </Grid>
                    :
                    null
            }

            {
                (levelDeliver !== null && levelTwo === 2) &&
                <Grid item xs={8} sm={3}>
                    <LevelFiveAvansAkt
                        actPath={computeDocName()}
                        selectedObject={selectedObject}
                        levelTwo={levelTwo}
                        levelThree={levelThree}
                        levelFour={levelFour}
                        setLevelTwo={setLevelTwo}
                        setLevelThree={setLevelThree}
                        setLevelFour={setLevelFour}
                        act={act}
                        setAct={setAct}
                        isScanned={isScanned}
                        setScanned={setScanned}
                        isElectronic={isElectronic}
                        setElectronic={setElectronic}
                        isPhysical={isPhysical}
                        setPhysical={setPhysical}
                        color={color}
                    />
                </Grid>
            }

            {
                (levelDeliver !== null && levelTwo === 2 && levelThree === 0) ?
                    <Grid item xs={6} sm={3}>
                        <TrackThreeDeliverAvans
                            selectedObject={selectedObject}
                            stage={stage}
                            group={group}
                            material={service}
                            levelDeliver={levelDeliver}
                            assignee={assignee}
                            levelFour={levelFour}
                            setLevelFour={setLevelFour}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}
                            color={color}
                        />
                    </Grid>
                    :
                    (levelTwo === 2 && levelThree === 1) ?
                        <Grid item xs={6} sm={3}>
                            <TrackThreeDeliverServiceAvans
                                selectedObject={selectedObject}
                                material={service}
                                stage={stage}
                                group={group}
                                levelDeliver={levelDeliver}
                                deliveryNum={act}
                                levelFour={levelFour}
                                setLevelFour={setLevelFour}
                                isScanned={isScanned}
                                setScanned={setScanned}
                                isElectronic={isElectronic}
                                setElectronic={setElectronic}
                                isPhysical={isPhysical}
                                setPhysical={setPhysical}
                                color={color}
                            />
                        </Grid>
                        :
                        null
            }


        </Grid>
    )
}
export default TrackThreeDeliverService;