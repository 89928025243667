import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import TextField from "@mui/material/TextField";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import Button from "@mui/material/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ObjectDataDialog({open, handleClose,
                              name, setName,
                              entity, setEntity,
                              workPercWork, setWorkPercWork,
                              workPercMaterial, setWorkPercMaterial,
                              workPercMech, setWorkPercMech
}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    return  (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Добави работа
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container padding={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={name}
                        onChange={(event) =>  {
                            setName(event.target.value)
                        }}
                        label="Код"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={name}
                        onChange={(event) =>  {
                            setName(event.target.value)
                        }}
                        label="Име"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography>Мярка</Typography>
                    <AutocompleteProps
                        autocompleteProperty={entity}
                        dataset={[{name: "КВ.М"}, {name: "КГ"}, {name: "КУБ.М"}, {name: "ТОН"}, {name: "БР."}]}
                        setAutocompleteProperty={setEntity}
                        restApiAddPropsFunction={(name) => {
                        }}
                        title={""}
                        propName={"name"}
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="perc_work"
                        type="number"
                        value={workPercWork}
                        onChange={(event) =>  {
                            setWorkPercWork(event.target.value)
                        }}
                        label="% труд"
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="perc_goods"
                        type="number"
                        value={workPercMaterial}
                        onChange={(event) =>  {
                            setWorkPercMaterial(event.target.value)
                        }}
                        label="% материали"
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="perc_mech"
                        type="number"
                        value={workPercMech}
                        onChange={(event) =>  {
                            setWorkPercMech(event.target.value)
                        }}
                        label="% мехазниация"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        margin="dense"
                        variant={'contained'}
                        id="done"
                        onClick={(event) =>  {
                            if (Number(workPercWork) + Number(workPercMaterial) + Number(workPercMech) !== 100) {
                                alert('Сумата трябва да е 100%')
                            }
                        }}
                    >
                        Запази
                    </Button>
                </Grid>

            </Grid>


        </Dialog>
    )
}
export default ObjectDataDialog;