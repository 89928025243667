import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import AnalysisComponent from "./AnalysisComponent";
import {Paper, Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../services/api-fetch";
import {useLocalStorage} from "../../useLocalStorage";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import {addService} from "../../utils/NetworkUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function AnalysisDialog({open, handleClose, workId, idSmrGroup}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    const [tab, setTab] = useState(0);

    const [topData, setTopData] = useState({});
    const [items, setItems] = useState([]);

    const [quantity, setQuantity] = useState(null);
    const [entity, setEntity] = useState(null);

    const [totalWithNoVat, setTotalWithNoVat] = useState(0);

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/getWork?`+ new URLSearchParams({
            id: workId,
            token: accessToken
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        }).then((result) => {
            console.log(result.items[0])
            if (result.items[0]) {
                setTopData(result.items[0])
                setEntity({name: result.items[0].entity})
                setItems({items: result.items[0].subs
                        .filter((item) => {
                            /*if (filter) {
                                return item.id_detail_type === filter
                            }*/
                            return true;
                        })})
            }

        });

    }, [workId])


    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Анализ
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container style={{margin: 10, padding: 10}}>
                <Grid item xs={12}>

                    <Paper elevation={6}>
                        <Grid container style={{padding: 10}}>
                            <Grid item xs={10}>
                                <Grid container spacing={1}>

                                    <Grid item xs={12}>
                                        <TextField fullWidth value={topData.name}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Мярка</Typography>
                                            <AutocompleteProps
                                                size={'small'}
                                                autocompleteProperty={entity}
                                                dataset={[{name: "КВ.М"}, {name: "КГ"}, {name: "КУБ.М"}, {name: "ТОН"}]}
                                                setAutocompleteProperty={setEntity}
                                                restApiAddPropsFunction={(name) => {
                                                }}
                                                title={""}
                                                propName={"name"}
                                            />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Количество</Typography>
                                        <TextField fullWidth value={quantity} size="small" type="number" onChange={(event) => {
                                            setQuantity(event.target.value)
                                        }}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>За количество</Typography>
                                        <TextField size="small" fullWidth value={"1"}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography><b>Обща стойност</b></Typography>
                                        <TextField size="small" fullWidth value={
                                            quantity ?
                                                parseFloat(Number(quantity) * totalWithNoVat).toFixed(2)
                                                    .toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                                : null
                                        }/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Единична цена</Typography>
                                        <TextField size="small" fullWidth value={totalWithNoVat ? totalWithNoVat.toFixed(2) : null}/>
                                    </Grid>


                                    <Grid item xs={6}>
                                        {/*
                                    <TextField size="small" fullWidth/>
*/}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>

            </Grid>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(event, newValue) => {
                    setTab(newValue);
                }} aria-label="basic tabs example">
                    <Tab label="Всичко" />
                    <Tab label="Труд" />
                    <Tab label="Материали" />
                    <Tab label="Услуги" />
                    <Tab label="Механизация" />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <AnalysisComponent
                    id={workId}
                    items={items} topData={topData}
                    totalWithNoVat={totalWithNoVat}
                    setTotalWithNoVat={setTotalWithNoVat}
                    setItems={setItems}
                    idSmrGroup={idSmrGroup}/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <AnalysisComponent id={workId} filter={1} items={items} topData={topData} setItems={setItems} totalWithNoVat={totalWithNoVat} setTotalWithNoVat={setTotalWithNoVat} idSmrGroup={idSmrGroup}/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <AnalysisComponent id={workId} filter={3} items={items} topData={topData} setItems={setItems} totalWithNoVat={totalWithNoVat} setTotalWithNoVat={setTotalWithNoVat} idSmrGroup={idSmrGroup}/>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <AnalysisComponent id={workId} filter={4} items={items} topData={topData} setItems={setItems} totalWithNoVat={totalWithNoVat} setTotalWithNoVat={setTotalWithNoVat} idSmrGroup={idSmrGroup}/>
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <AnalysisComponent id={workId} filter={2} items={items} topData={topData} setItems={setItems} totalWithNoVat={totalWithNoVat} setTotalWithNoVat={setTotalWithNoVat} idSmrGroup={idSmrGroup}/>
            </TabPanel>

        </Dialog>
    )
}
export default AnalysisDialog;