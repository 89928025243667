import {TableCell, TableRow} from "@mui/material";
import * as React from "react";
import ChangePriceDialog from "./ChangePriceDialog";
import {changePercent, changePercentWork} from "../../utils/NetworkUtils";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";

function DostSkladCell({filter, additionalCost, dostSkladovi, totalItems, workId, setForceRefresh}) {
    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };

    let navigate = useNavigate();
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [dostSkladoviDialog, setDostSkladoviDialog] = useState(false);
    const [dostSkladoviState, setDostSkladoviState] = useState(dostSkladovi || 0);

    useEffect(() => {
        setDostSkladoviState(dostSkladovi)
    }, [dostSkladovi])

    return (
        <React.Fragment>
            <TableRow
                key={'dost-skladovi-row'}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell component="th" scope="row" style={style.tableCell}>
                    <b>Доставно складови</b>
                </TableCell>
                <TableCell align="right" style={style.tableCell}>%</TableCell>
                <TableCell align="right" style={style.tableCell} onClick={() => setDostSkladoviDialog(true)}>{dostSkladoviState}%</TableCell>
                {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                {(!filter || filter === 3) && <TableCell align="right" style={style.tableCell}></TableCell>}
                <TableCell align="right" style={style.tableCell}>{(additionalCost + totalItems).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }</TableCell>
                <TableCell align="right" style={style.tableCell}>{((additionalCost + totalItems) * (dostSkladoviState / 100)).toFixed(2)}
                </TableCell>
            </TableRow>


            <ChangePriceDialog
                open={dostSkladoviDialog}
                handleClose={() => setDostSkladoviDialog(false)}
                label={'Доставно складови'}
                item={dostSkladoviState}
                setItem={setDostSkladoviState}
                onClick={() => {
                    changePercentWork(workId, 9, Number(dostSkladoviState), navigate, accessToken, setAccessToken)
                    setForceRefresh(Math.random())
                    setDostSkladoviDialog(false)
                }}/>
        </React.Fragment>

)
}
export default DostSkladCell;