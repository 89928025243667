import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import TextField from "@mui/material/TextField";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import Button from "@mui/material/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddEditWorkDialog({open, handleClose,
                              name, setName,
                              entity, setEntity,
                              quantity, setQuantity,
                               onClick
}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    return  (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <Grid container padding={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="work-name"
                        value={name}
                        onChange={(event) =>  {
                            setName(event.target.value)
                        }}
                        label="Име"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography>Мярка</Typography>
                    <AutocompleteProps
                        autocompleteProperty={entity}
                        dataset={[{name: "КВ.М"}, {name: "КГ"}, {name: "КУБ.М"}, {name: "ТОН"}, {name: "БР."}]}
                        setAutocompleteProperty={setEntity}
                        restApiAddPropsFunction={(name) => {
                        }}
                        title={""}
                        propName={"name"}
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={quantity}
                        onChange={(event) =>  {
                            setQuantity(event.target.value)
                        }}
                        label="Количество"
                    />
                </Grid>


                <Grid item xs={12}>
                    <Button
                        fullWidth
                        margin="dense"
                        variant={'contained'}
                        id="done"
                        onClick={(event) =>  {
                            onClick()
                        }}
                    >
                        Запази
                    </Button>
                </Grid>

            </Grid>


        </Dialog>
    )
}
export default AddEditWorkDialog;