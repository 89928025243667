import Grid from "@mui/material/Grid";
import {Autocomplete, Paper, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import {addMaterial, addService, getMaterials, getServices} from "../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";


function LevelServiceMaterialAutocomplete({selectedObject, serviceMaterial, levelTwo, setLevelTwo,
                                              setAssignee, setServiceMaterial,
                                              material, setMaterial, materialOptions,
                                              setLevelDeliver,
                                              service, setService,
                                              stage, group,
                                              color, SERVICE, MATERIAL}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [materials, setMaterials] = React.useState([]);
    const [services, setServices] = React.useState([]);

    useEffect(() => {
        getMaterials({id: selectedObject}, navigate, accessToken, setAccessToken, stage, group)
            .then((result) => {
                setMaterials(result.items)
            });

        getServices({id: selectedObject}, navigate, accessToken, setAccessToken, stage, group)
            .then((result) => {
                setServices(result.items)
            })
    }, [stage, group])


    useEffect(() => {
        if (setLevelDeliver) {
            setLevelDeliver(null)
        }
    }, [serviceMaterial])

    return (
        <Grid xs={10} container justifyContent={'space-between'} direction="column">

            <Grid item xs={8}>
                <Paper
                    onClick={() => {
                        setServiceMaterial(SERVICE)
                        setLevelTwo(1)
                    }}
                    elevation={6} className={"paper-style"}
                    style={{background: serviceMaterial === SERVICE ? color : null, height: "auto"}}>

                    <Grid container spacing={3} justifyContent={'center'} style={{padding: 15,  paddingLeft: 5, paddingRight: 5}}>
                        <Grid item xs={12}>
                            <Typography>Вид работа</Typography>
                        </Grid>

                        {
                            serviceMaterial === SERVICE &&
                            <Grid item xs={10}>
                                <AutocompleteProps
                                    autocompleteProperty={service}
                                    dataset={services}
                                    setAutocompleteProperty={setService}
                                    restApiAddPropsFunction={(name) =>
                                        addService({id: selectedObject}, navigate, accessToken, setAccessToken, name, stage, group)
                                            .then(result => {
                                                if (result && result.id) {
                                                    setService({
                                                        "id": result.id,
                                                        "service_name": name
                                                    })
                                                }

                                            })
                                    }
                                    title={"Вид"}
                                    propName={"service_name"}
                                />
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>


            <Grid item xs={8}>
                <Paper
                    onClick={() => {
                        setServiceMaterial(MATERIAL)
                        setLevelTwo(1)
                    }}
                    elevation={6} className={"paper-style"}
                    style={{background: serviceMaterial === MATERIAL ? color : null, height: "auto"}}>

                    <Grid container spacing={3} justifyContent={'center'} style={{padding: 15,  paddingLeft: 5, paddingRight: 5}}>
                        <Grid item xs={12}>
                            <Typography>Материал</Typography>
                        </Grid>
                        {
                            serviceMaterial === MATERIAL &&
                            <Grid item xs={10}>
                                <AutocompleteProps
                                    autocompleteProperty={material}
                                    dataset={materials}
                                    setAutocompleteProperty={setMaterial}
                                    restApiAddPropsFunction={(name) =>
                                        addMaterial({id: selectedObject}, navigate, accessToken, setAccessToken, name, stage, group)
                                            .then(result => {
                                                if (result && result.id) {
                                                    setMaterial({
                                                        "id": result.id,
                                                        "good_name": name
                                                    })
                                                }

                                            })
                                }
                                    title={"Вид"}
                                    propName={"good_name"}
                                />
                            </Grid>
                        }
                    </Grid>


                </Paper>
            </Grid>

        </Grid>

    )
}

export default LevelServiceMaterialAutocomplete;