import TextField from "@mui/material/TextField";
import * as React from "react";
import {TableCell, TableRow} from "@mui/material";

export default function TotalNoVatRow({totalWithNoVat}) {
    return (
        <TableRow
            key={'tot-no_vat'}>
            <TableCell component="th" scope="row">
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right" colSpan={2}><b>Общо без ДДС</b></TableCell>
            <TableCell align="right"><b>{totalWithNoVat ? totalWithNoVat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</b>
            </TableCell>
        </TableRow>
    )
}