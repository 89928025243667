import List from "@mui/material/List";
import {Button, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import Grid from "@mui/material/Grid";
import AddEditWorkDialog from "./AddEditWorkDialog";
import {addGroup, addGroupKss, addWork, setDocState} from "../../utils/NetworkUtils";
import {ExcelRenderer} from 'react-excel-renderer';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FromScratchAnalysisDialog from "../analysis/FromScratchAnalysis/AnalysisDialog";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CreateDialog from "./CreateDialog";
import EmptyGroupRow from "./EmptyGroupRow";
import EmptyWorksRow from "./EmptyWorksRow";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";

function KssComponent({items, setItems, grandTotal, emptyAnalysisDialog, setEmptyAnalysisDialog, selectedObject}) {
    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#000000",
            borderBottomColor: "#000000",
            display: "tableRowGroup",
        }
        , tableGroupCell: {
            borderRightWidth: '0.6px',
            borderRightStyle: "solid",
            borderRightColor: "#000000",
            borderBottomColor: "#000000",
            display: "tableRowGroup",
        }
        , tableEtapCell: {
            borderRightWidth: '0.6px',
            borderRightStyle: "solid",
            borderRightColor: "#000000",
            borderBottomColor: "#000000",
            display: "tableRowGroup",
        }
    };
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [etapOpen, setEtapOpen] = useState( false);
    const [groupOpen, setGroupOpen] = useState( false);
    const [etapStageName, setEtapStageName] = useState( null);


    const [name, setName] = useState( null);
    const [entity, setEntity] = useState( null);
    const [quantity, setQuantity] = useState( null);

    const [workName, setWorkName] = useState( null);
    const [workEntity, setWorkEntity] = useState( null);
    const [workQuantity, setWorkQuantity] = useState( null);

    const [addWorkOpen, setAddWorkOpen] = useState(false);
    const [idAworkgroup, setIdAworkgroup] = useState(null);
    const [emptyAnalysisDialogId, setEmptyAnalysisDialogId] = useState(null);

    const [emptyAnalysisDialogMounted, setEmptyAnalysisDialogMounted] = useState(null);

    return (
        <React.Fragment>

            <Table aria-label="a dense table" size="small">
                <TableHead>
                    <TableRow style={{background: '#8dffa7'}}>
                        <TableCell width="10%" style={style.tableCell}>No</TableCell>
                        <TableCell width="45%" style={Object.assign({}, style.tableCell, {})}><h3>Наименование</h3></TableCell>
                        <TableCell width="7%" style={style.tableCell} align="center">Ед.м.</TableCell>
                        <TableCell width="10%" style={style.tableCell} align="center">Кол</TableCell>
                        <TableCell width="10%" style={style.tableCell} align="center">Ед. цена</TableCell>
                        <TableCell width="15%" style={style.tableCell} align="center">Сума</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow style={{background: '#8dffa7'}}>
                        <TableCell width="10%" style={style.tableCell}></TableCell>
                        <TableCell width="45%" style={Object.assign({}, style.tableCell, {})}></TableCell>
                        <TableCell width="10%" style={style.tableCell} align="right" colSpan={3}><h3><b>Общо за обекта без ДДС</b></h3></TableCell>
                        <TableCell width="15%" style={style.tableCell} align="right"><h3><b>{grandTotal && !isNaN(grandTotal) ? Number(grandTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "00.00"}</b></h3></TableCell>
                    </TableRow>
                    {
                        items && items.map((top, index) => (
                            <React.Fragment>
                                <TableRow>
                                    <TableCell width="10%" style={Object.assign({}, {background: '#63fff2'}, style.tableEtapCell)}></TableCell>
                                    <TableCell width="45%" style={Object.assign({},{background: '#63fff2'}, style.tableEtapCell)}>
                                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                            <h2 style={{padding: 0}}><b>{top.name}</b></h2>
                                            <Button onClick={() => {
                                                setEtapOpen(index+1)
                                            }}
                                                    style={{padding: 5, marginLeft: 20}}>
                                                <ControlPointIcon fontSize={'medium'} sx={{color: 'black'}}/>
                                            </Button>
                                        </Grid>

                                    </TableCell>
                                    <TableCell width="7%" style={Object.assign({},{background: '#63fff2'}, style.tableEtapCell)}><b></b></TableCell>
                                    <TableCell width="10%" style={Object.assign({},{background: '#63fff2'}, style.tableEtapCell)}><b></b></TableCell>
                                    <TableCell width="10%" style={Object.assign({},{background: '#63fff2'}, style.tableEtapCell)}><b></b></TableCell>
                                    <TableCell align="right" width="15%" style={Object.assign({},{background: '#63fff2'}, style.tableEtapCell)}>{top && top.total && !isNaN(top.total) ? Number(top.total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "00.00"}</TableCell>
                                </TableRow>
                                {
                                    top && top.sub && top.sub.length === 0 &&
                                    <EmptyGroupRow
                                        topIndex={index}
                                        style={style}
                                        setGroupOpen={setGroupOpen}/>
                                }
                                {top && top.sub && top.sub.map((item, subIndex) => (
                                    <React.Fragment>
                                        <TableRow style={{background: '#d5d5d5'}}>
                                            <TableCell width="10%" style={style.tableGroupCell}></TableCell>
                                            <TableCell width="45%" style={style.tableGroupCell}>
                                                <Grid container alignItems={'center'} justifyContent={'space-between'}>

                                                    <Grid item>
                                                        <span style={{marginRight: 10}}>{item.name}</span>
                                                        {
                                                            item.works.some((work) => !work.total)  &&
                                                            <WarningAmberIcon sx={{ color: 'red' }}/>
                                                        }
                                                    </Grid>

                                                    <Grid item>
                                                        <Button onClick={() => {
                                                            setGroupOpen({topIndex: index, subIndex: subIndex})
                                                        }}
                                                                style={{padding: 5, marginLeft: 20}}>
                                                            <ControlPointIcon fontSize={'medium'} sx={{color: 'black'}}/>
                                                        </Button>
                                                    </Grid>

                                                </Grid>
                                            </TableCell>
                                            <TableCell width="7%" style={style.tableGroupCell}></TableCell>
                                            <TableCell width="10%" style={style.tableGroupCell}></TableCell>
                                            <TableCell width="10%" style={style.tableGroupCell}></TableCell>
                                            <TableCell align="right" width="15%" style={style.tableGroupCell}>{item && item.total && !isNaN(item.total) ? Number(item.total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "00.00"}</TableCell>


                                        </TableRow>
                                        {
                                            item && item.works && item.works.length === 0 && <EmptyWorksRow group={item} setAddWorkOpen={setAddWorkOpen}
                                                                                                            setWorkName={setWorkName} setWorkEntity={setWorkEntity} setWorkQuantity={setWorkQuantity}
                                                                                                            style={style}/>
                                        }
                                        {
                                            item.works.map((work, index) => (
                                                <TableRow
                                                    key={work.name}
                                                    style={{height: 40}}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell width="10%" component="th" scope="row" style={style.tableCell}>
                                                        {index === 0 && <Button onClick={() => {
                                                            setWorkName(null)
                                                            setWorkEntity(null)
                                                            setWorkQuantity(null)
                                                            setAddWorkOpen(item.id)
                                                        }}
                                                                style={{padding: 5, marginLeft: 20}}>
                                                            <ControlPointIcon fontSize={'medium'} sx={{color: 'black'}}/>
                                                        </Button> }

                                                    </TableCell>
                                                    <TableCell width="45%" style={style.tableCell} onClick={() => {
                                                        setWorkName(work.name)
                                                        setWorkEntity(work.entity)
                                                        setWorkQuantity(work.quantity)
                                                        setEmptyAnalysisDialogMounted(true)
                                                        setEmptyAnalysisDialog(true)
                                                        setEmptyAnalysisDialogId(work.id)
                                                        console.log('setting ', item)
                                                        setIdAworkgroup(item.id)
                                                    }}>{work.name}</TableCell>
                                                    <TableCell width="7%" align="right" style={style.tableCell}>{work.entity}</TableCell>
                                                    <TableCell width="10%" align="right" style={style.tableCell}>{work.quantity && !isNaN(work.quantity) ? work.quantity.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                    <TableCell width="10%" align="right" style={style.tableCell}><p style={{color: !work.price ? 'red' : null}}>{work.price && !isNaN(work.price) ? Number(work.price).toFixed(2) : "0.00"}</p></TableCell>
                                                    <TableCell width="15%" align="right" style={Object.assign({}, style.tableCell, {width: 200})}>{work.total ? work.total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                </TableRow>
                                            ))
                                        }

                                    </React.Fragment>
                                ))}

                            </React.Fragment>

                        ))

                    }

                </TableBody>

                <AddEditWorkDialog
                    open={addWorkOpen}
                    name={workName}
                    setName={setWorkName}
                    entity={workEntity}
                    setEntity={setWorkEntity}
                    quantity={workQuantity}
                    setQuantity={setWorkQuantity}
                    handleClose={() => {
                        setAddWorkOpen(false)
                    }}
                    onClick={() => {
                        const newItems = items;
                        const etap = newItems.find((item) => {
                                if (!item.sub) {
                                    return false;
                                }
                                return item.sub.find(sub => sub.id === addWorkOpen)
                            }
                        )

                        if (etap && etap.sub) {
                            const group = etap.sub.find(sub => sub.id === addWorkOpen)

                            if (group && group.works) {

                                addWork(group.id, workName, '', workEntity && workEntity.name ? workEntity.name : "",
                                    Number(workQuantity),
                                    navigate, accessToken, setAccessToken)
                                    .then((result) => {
                                        group.works.push({
                                            code: '',
                                            name: workName,
                                            entity: workEntity && workEntity.name ? workEntity.name : "",
                                            quantity: Number(workQuantity),
                                            id: result.id
                                        })
                                        setAddWorkOpen(false)

                                    })

                                console.log('newItems ', newItems)
                                //setItems(newItems)
                                setWorkName(null)
                                setWorkEntity(null)
                                setWorkQuantity(null)
                            }
                        }

                        /* setName(null)
                         setEntity(null)
                         setQuantity(null)*/
                    }}/>
            </Table>

            {
                emptyAnalysisDialogMounted && <FromScratchAnalysisDialog
                    workId={emptyAnalysisDialogId}
                    idAworkgroup={idAworkgroup}
                    open={emptyAnalysisDialog}
                    name={workName}
                    setName={setWorkName}
                    quantity={workQuantity}
                    setQuantity={setWorkQuantity}
                    entity={workEntity}
                    setEntity={setWorkEntity}
                    handleClose={() => {
                        setEmptyAnalysisDialog(false);
                        setTimeout(() => {
                            setEmptyAnalysisDialogMounted(false)
                        }, 300);
                    }}/>
            }


           <CreateDialog
                    open={etapOpen}
                    handleClose={() => setEtapOpen(false)}
                    name={etapStageName}
                    setName={setEtapStageName}
                    itemName={'Етап'}
                    onClick={() => {
                        Array.prototype.insert = function ( index, ...items ) {
                            this.splice( index, 0, ...items );
                        };

                        addGroupKss({id: selectedObject}, navigate, accessToken, setAccessToken, etapStageName)
                            .then((result) => {
                                console.log('groupOpen ', groupOpen)
                                const newItems = items;
                                newItems.insert(etapOpen, {
                                    id: result.id,
                                    name: etapStageName,
                                    sub: []
                                })
                                setItems(newItems)
                                setEtapStageName(null)
                                setEtapOpen(false)
                            })
                    }}/>

           <CreateDialog
                    open={groupOpen}
                    handleClose={() => setGroupOpen(false)}
                    name={etapStageName}
                    setName={setEtapStageName}
                    itemName={'Група'}
               onClick={() => {
                   Array.prototype.insert = function ( index, ...items ) {
                       this.splice( index, 0, ...items );
                   };

                   const newItems = items;


                   console.log('grupppppa request')

                   addGroupKss({id: selectedObject}, navigate, accessToken, setAccessToken, etapStageName, newItems[groupOpen.topIndex].id)
                       .then((result) => {
                           console.log('groupOpen ', groupOpen)
                           newItems[groupOpen.topIndex].sub.insert(groupOpen.subIndex+1, {
                               id: result.id,
                               name: etapStageName,
                               works: []
                           })
                           setItems(newItems)
                           setEtapStageName(null)
                           setGroupOpen(false)
                       })
               }}/>



        </React.Fragment>
    )
}
export default KssComponent;