import {Grid} from "@mui/material";
import LevelTwoInvestorDeliver from "../../../levels/LevelTwoInvestorDeliver";
import React from "react";
import LevelServiceMaterial from "../../../levels/LevelServiceMaterial";
import LevelStageGroup from "../../../levels/LevelStageGroup";
import LevelFiveAvansAktDocs from "../../../levels/levelFive/LevelFiveAvansAktDocs";
import LevelFiveAvansAktCert from "../../../levels/levelFive/LevelFiveAvansAktCert";
import LevelFiveAvans from "../../../levels/levelFive/LevelFiveAvans";
import LevelDocuments from "../../../levels/LevelDocuments";

function TrackThreeDeliverAvans({
                                    deliveryNum, selectedObject, levelDeliver, material, levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      levelStageGroup,
                      stage, setStage,
                      group, setGroup,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical, isCertificateEnabled,
                                act, setAct,
                      color, SERVICE, MATERIAL
                  }) {

    function computeDocName() {
        // Изпълнение - доставчик - материал - аванс
        // Изпълнение - доставчик - материал - доставка номер
        console.log('stage ', stage)
        console.log('group ', group)
        console.log('material ', material)
        console.log('levelDeliver ', levelDeliver)
        const stageId = stage ? stage.id : 0;
        const groupId = group ? group.id : 0;
        const goodId = material ? material.id : 0;
        const assigneeId = levelDeliver ? levelDeliver.id : 0;

        const levelFourText = levelFour === 0 ? 'invoice' : levelFour === 1 ? 'payment' : 'certificate';


        let adviceSupply;
        if (levelThree === 1) {
            adviceSupply = `supply-${deliveryNum ? deliveryNum.doc_no : 0}`
        } else {
            adviceSupply = 'advance';
        }

        return `performance-${stageId}-${groupId}-${goodId}-${assigneeId}-${adviceSupply}-${levelFourText}`;
    }

    return (
        <Grid container>

            <Grid item xs={4} sm={9}>
{/*
                Фактура, Платежно, Сертификат
*/}
                <LevelFiveAvans
                    levelFour={levelFour}
                    setLevelFour={setLevelFour}
                    isCertificateEnabled={isCertificateEnabled}
                    color={color}
                />
            </Grid>

            {/* ----------- 5.-----------*/}
            {
                (levelFour !== null) ?
                    <Grid item xs={5} sm={1}>
                        {/*
                                Физически, Електронен, Сканиран
*/}
                        <LevelDocuments
                            selectedObject={selectedObject}
                            docName={computeDocName()}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}/>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
}
export default TrackThreeDeliverAvans;