import List from "@mui/material/List";
import * as React from 'react';
import {ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ChangePriceDialog from "./ChangePriceDialog";
import Button from "@mui/material/Button";
import workTypeDialog from "../workType/WorkTypeDialog";
import TempAdditionalCell from "./TempAdditionalCell";
import AddEditWorkDialog from "./AddEditWorkDialog";
import DostSkladCell from "./DostSkladCell";
import ProfitCell from "./ProfitCell";
import TotalNoVatRow from "./TotalNoVatRow";
import {addAnalysisDetail, changePercent} from "../../utils/NetworkUtils";

function AnalysisComponent({id, filter, topData, items, setItems, setTotalWithNoVat, totalWithNoVat, idSmrGroup}) {
    let navigate = useNavigate();
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [changePriceDialogOpen, setChangePriceDialogOpen] = useState(false);
    const [changeFiraDialogOpen, setChangeFiraDialogOpen] = useState(false);

    const [changeAdditionalCostDialog, setChangeAdditionalCostDialog] = useState(false);
    const [additionalCostDialogEdit, setAdditionalCostDialogEdit] = useState(null);

    const [editingItemId, setEditingItemId] = useState(null);
    const [entityPrice, setEntityPrice] = useState(null);
    const [fira, setFira] = useState(null);

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [nameAddDialog, setNameAddDialog] = useState(null);
    const [entityAddDialog, setEntityAddDialog] = useState(null);
    const [costRateAddDialog, setCostRateAddDialog] = useState(null);
    const [firaAddDialog, setFiraAddDialog] = useState(null);
    const [entityPriceDialogAddDialog, setEntityPriceDialogAddDialog] = useState(null);

    const calculateTotalAmount = (item) => {
        return (item.cost_rate + item.cost_rate * (Number(item.perc_fira) / 100));
    }
    useEffect(() => {

        setTotalWithNoVat(0)
        let allTypes = 0;
        for (let i = 1; i <= 4; i++) {
            const type = i;

            let additionalCost = 0;

            //Calculate items only
            const totalItems = (items && items.items) && items.items
                .filter((item) => {
                    return item.id_detail_type === type
                })
                .reduce(function (a, b) {
                    const totalQuantity = calculateTotalAmount(b);

                    return totalQuantity ? a + (totalQuantity * b.price) : a;
                }, 0);

            // get the line with id === 0
            let additionalItem = (items && items.items) && items.items.find((item) => {
                return item.id === 0 && item.id_detail_type === type;
            });
            // if a SERVICE
            if (type === 4) {
                additionalItem = {
                    cost_rate: 10
                }
            }

            if (additionalItem && additionalItem.cost_rate) {
                additionalCost = ((additionalItem.cost_rate / 100) * totalItems)
            }

            // Only calculate Доставно складови, if materials
            const percDostSkladovi = 10;
            const dostSkladovi = type === 3 ? (totalItems + additionalCost) * (percDostSkladovi / 100) : 0;

            const profitPercent = 10;
            const profitNum = (totalItems + additionalCost + dostSkladovi) * (profitPercent / 100);

            const total = totalItems + additionalCost + dostSkladovi + profitNum;
            allTypes += total;
        }
        console.log('calc triggered ', allTypes)
        setTotalWithNoVat(allTypes)
    }, [addDialogOpen, nameAddDialog, entityAddDialog, costRateAddDialog, firaAddDialog, entityPriceDialogAddDialog,
        changeAdditionalCostDialog, changePriceDialogOpen, changeFiraDialogOpen])


    const renderMainContent = (type, name) => {
        let additionalCost = 0;

        //Calculate items only
        const totalItems = (items && items.items) && items.items
            .filter((item) => {
                return item.id_detail_type === type
            })
            .reduce( function(a, b){
                const totalQuantity = calculateTotalAmount(b);

                return totalQuantity ? a + (totalQuantity * b.price): a;
            }, 0);

        // get the line with id === 0
        let additionalItem = (items && items.items) && items.items.find((item) => {
            return item.id === 0 && item.id_detail_type === type;
        });
        // if a SERVICE
        if (type === 4) {
            additionalItem = {
                cost_rate: 10
            }
        }

        if (additionalItem && additionalItem.cost_rate){
            additionalCost = ((additionalItem.cost_rate / 100) * totalItems )
        }

        // Only calculate Доставно складови, if materials
        const percDostSkladovi = 10;
        const dostSkladovi = type === 3 ? (totalItems + additionalCost) * (percDostSkladovi / 100) : 0;

        const profitPercent = 10;
        const profitNum = (totalItems + additionalCost + dostSkladovi) * (profitPercent/100);


        return (
            <React.Fragment>
                <TableRow
                    key={name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell colSpan={7}>
                        <Typography variant={'h5'}>&nbsp;</Typography>
                    </TableCell>
                </TableRow>

                <TableRow
                    key={name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell colSpan={7}>
                        <Grid container>
                            <Grid item>
                                <Typography variant={'h5'}>{ name }</Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => {
                                    setAddDialogOpen(type)
                                }}>Добави</Button>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>

                {
                    items && items.items && items.items
                        .filter((item) => {
                            return item.id_detail_type === type
                        })
                        .map((item) => {
                            const totalQuantity = calculateTotalAmount(item);

                            return <TableRow
                                key={item.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {item.id === 0 ? <b>{item.name}</b> : item.name }
                                </TableCell>

                                <TableCell align="right">{item.entity}</TableCell>

                                <TableCell align="right"
                                    onClick={(e) => {
                                        if (item.id === 0) {
                                            e.stopPropagation()
                                            e.nativeEvent.stopImmediatePropagation();
                                            setChangeAdditionalCostDialog(type)
                                            setAdditionalCostDialogEdit(item.cost_rate)
                                        }
                                    }}
                                    >{item.cost_rate ? item.cost_rate.toFixed(6) : null}</TableCell>

                                {(!filter || item.id_detail_type === 3) && <TableCell
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.nativeEvent.stopImmediatePropagation();
                                        setChangeFiraDialogOpen(true)
                                        setFira(item.perc_fira)
                                        setEditingItemId(item.id)
                                    }}
                                    align="right">{item.id_detail_type === 3 ? ((item.perc_fira) ? item.perc_fira + '%' : '0%') : null}</TableCell>}

                                {(!filter || item.id_detail_type === 3) && <TableCell
                                    align="right">{(item.id_detail_type === 3) ? totalQuantity ? totalQuantity.toFixed(8) : null : null}</TableCell>}

                                <TableCell onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();
                                    setChangePriceDialogOpen(true)
                                    setEntityPrice(item.price)
                                    setEditingItemId(item.id)
                                }}
                                    align="right">{item.id !== 0 ? item.price : totalItems.toFixed(2)}</TableCell>

                                <TableCell align="right">{
                                    item.id !== 0 ?
                                        totalQuantity && item.cost_rate ?
                                        (totalQuantity * item.price).toFixed(2) : null
                                        :
                                        (additionalCost).toFixed(2)

                                }</TableCell>
                            </TableRow>
                })
                }

                {(type === 4) && <TempAdditionalCell
                    filter={filter}
                    additionalCost={additionalCost}
                    totalItems={totalItems}/> }

                {(type === 3) && <DostSkladCell
                    filter={filter} additionalCost={additionalCost}
                    dostSkladovi={dostSkladovi} totalItems={totalItems}/>
                }

               <ProfitCell
                   filter={filter} profitNum={profitNum} profitPercent={profitPercent}
                   totalItems={totalItems} additionalCost={additionalCost} dostSkladovi={dostSkladovi}/>

                <TableRow
                    key={'profit'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        <b>Общо {name}</b>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    {(!filter || filter === 3) && <TableCell align="right"></TableCell>}
                    {(!filter || filter === 3) && <TableCell align="right"></TableCell>}
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">{(totalItems + additionalCost + dostSkladovi + profitNum).toFixed(2)}
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    return (
        <Paper>

            <Grid container spacing={2}>


                <Grid item xs={12}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Име</TableCell>
                                <TableCell align="right">Мярка</TableCell>
                                <TableCell align="right">Разход</TableCell>
                                {(!filter || filter === 3) && <TableCell align="right">Фира%</TableCell>}
                                {(!filter || filter === 3) && <TableCell align="right">Общо разход</TableCell>}
                                <TableCell align="right">Ед. цена (лв)</TableCell>
                                <TableCell align="right">Стойност</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!filter || filter === 1) &&
                                renderMainContent(1, "Труд")
                            }
                            {
                                (!filter || filter === 3) &&
                                renderMainContent(3, "Материали")
                            }
                            {
                                (!filter || filter === 4) &&
                                renderMainContent(4, "Услуги")
                            }
                            {
                                (!filter || filter === 2) &&
                                renderMainContent(2, "Механизация")
                            }
                            {
                                !filter && <TotalNoVatRow totalWithNoVat={totalWithNoVat}/>
                            }

                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <ChangePriceDialog
                open={changePriceDialogOpen}
                handleClose={() => setChangePriceDialogOpen(false)}
                label={'Цена'}
                item={entityPrice}
                setItem={setEntityPrice}
                onClick={() => {
                    const newItems = items;
                    const itemToEditIndex = newItems.items
                        .findIndex((item) => {
                            return item.id === editingItemId
                        })

                    newItems.items[itemToEditIndex].price = entityPrice;
                    setItems(newItems)
                    setChangePriceDialogOpen(false)
                }}/>


            <ChangePriceDialog
                open={changeFiraDialogOpen}
                handleClose={() => setChangeFiraDialogOpen(false)}
                label={'Фира'}
                item={fira}
                setItem={setFira}
                onClick={() => {
                    const newItems = items;
                    const itemToEditIndex = newItems.items
                        .findIndex((item) => {
                            return item.id === editingItemId
                        })

                    newItems.items[itemToEditIndex].perc_fira = fira;
                    setItems(newItems)
                    setChangeFiraDialogOpen(false)

                }}/>


            <ChangePriceDialog
                open={changeAdditionalCostDialog}
                handleClose={() => setChangeAdditionalCostDialog(false)}
                label={'Допълнителни разходи'}
                item={additionalCostDialogEdit}
                setItem={setAdditionalCostDialogEdit}
                onClick={() => {
                    const newItems = items;
                    const itemToEditIndex = newItems.items
                        .findIndex((item) => {
                            return item.id === 0 && item.id_detail_type === changeAdditionalCostDialog
                        })

                    newItems.items[itemToEditIndex].cost_rate = Number(additionalCostDialogEdit);
                    setItems(newItems)
                    changePercent(id, changeAdditionalCostDialog, Number(additionalCostDialogEdit), navigate, accessToken, setAccessToken)
                    console.log('newItems ', newItems)
                    setChangeAdditionalCostDialog(false)

                }}/>

            <AddEditWorkDialog
                open={addDialogOpen}
                handleClose={() => {setAddDialogOpen(false)}}
                name={nameAddDialog}
                setName={setNameAddDialog}
                entity={entityAddDialog}
                setEntity={setEntityAddDialog}
                costRate={costRateAddDialog}
                setCostRate={setCostRateAddDialog}
                fira={firaAddDialog}
                setFira={setFiraAddDialog}
                entityPrice={entityPriceDialogAddDialog}
                setEntityPrice={setEntityPriceDialogAddDialog}
                onClick={() => {
                    const newItems = items;
                    const itemToInsertIndex = newItems.items
                        .findLastIndex((item) => {
                            return item.id_detail_type === addDialogOpen
                        })

                    newItems.items.splice(itemToInsertIndex, 0, {
                        cost_rate: Number(costRateAddDialog),
                        entity: entityAddDialog.name,
                        price: Number(entityPriceDialogAddDialog),
                        perc_fira: firaAddDialog ? firaAddDialog : 0,
                        id_detail_type: addDialogOpen,
                        id: new Date().valueOf(),
                        name: nameAddDialog
                    });

                    addAnalysisDetail(id, addDialogOpen, nameAddDialog,
                        entityAddDialog.name, null, firaAddDialog,
                        Number(costRateAddDialog), Number(entityPriceDialogAddDialog), 1,
                        navigate, accessToken, setAccessToken)


                    setItems(newItems)
                    setAddDialogOpen(false)
                    setNameAddDialog(null)
                    setEntityAddDialog(null)
                    setCostRateAddDialog(null)
                    setFiraAddDialog(null)
                    setEntityPriceDialogAddDialog(null)
                }}/>

        </Paper>
    )
}
export default AnalysisComponent;