import {Grid} from "@mui/material";
import LevelTwoInvestorDeliver from "../../../levels/LevelTwoInvestorDeliver";
import React from "react";
import LevelServiceMaterial from "../../../levels/LevelServiceMaterial";
import LevelStageGroup from "../../../levels/LevelStageGroup";
import LevelFiveAvansAktDocs from "../../../levels/levelFive/LevelFiveAvansAktDocs";
import LevelFiveAvansAktCert from "../../../levels/levelFive/LevelFiveAvansAktCert";
import TrackThreeDeliverAvans from "./TrackThreeDeliverAvans";
import TrackThreeDeliverAkt from "./TrackThreeDeliverAkt";
import TrackThreeDeliverCert from "./TrackThreeDeliverCert";
import LevelDeliver from "../../../levels/LevelDeliver";
import LevelFiveAvansDeliveryDocs from "../../../levels/levelFive/LevelFiveAvansAktDeliveryDocs";
import LevelFiveAvansDeliveryNum from "../../../levels/levelFive/LevelFiveAvansAvansDeliveryNum";
import LevelFiveAvansDeliveryNumDocs from "../../../levels/levelFive/LevelFiveAvansAvansDeliveryNumDocs";
import TrackThreeDeliverMaterial from "./TrackThreeDeliverMaterial";
import TrackThreeDeliverService from "./TrackThreeDeliverService";
import LevelServiceMaterialAutocomplete from "../../../levels/LevelServiceMaterialAutocomplete";

function TrackThreeDeliver({
                      selectedObject, hideBeforeDeliverer,
                      levelOne, levelTwo, levelOneTwo,levelThree, levelFour,
                      setLevelTwo, setLevelOneTwo, setLevelThree, setLevelFour,
                      serviceMaterial, setServiceMaterial,
                      material, setMaterial,
                      service, setService,
                      levelStageGroup,
                      stage, setStage,
                      group, setGroup,
                      levelDeliver, setLevelDeliver,
                      assignee, setAssignee,
                      isDocuments, setDocuments,
                      isScanned, setScanned,
                      isElectronic, setElectronic,
                      isPhysical, setPhysical,
                                act, setAct,
                      color, SERVICE, MATERIAL
                  }) {

    return (
        <Grid container>
            {
                !hideBeforeDeliverer &&
                <Grid item xs={4} sm={2.1}>
                    <LevelStageGroup
                        selectedObject={selectedObject}
                        levelStageGroup={levelStageGroup}
                        stage={stage}
                        setStage={setStage}
                        group={group}
                        setGroup={setGroup}
                        color={color}/>
                </Grid>
            }


            {
                !hideBeforeDeliverer &&
                <Grid item xs={7} sm={2}>
                    <LevelServiceMaterialAutocomplete
                        stage={stage}
                        group={group}
                        selectedObject={selectedObject}
                        serviceMaterial={serviceMaterial}
                        levelTwo={levelTwo}
                        setLevelTwo={() => {
                        }}
                        setAssignee={setAssignee}
                        setServiceMaterial={setServiceMaterial}
                        setLevelDeliver={setLevelDeliver}
                        material={material}
                        setMaterial={setMaterial}
                        service={service}
                        setService={setService}
                        color={color}
                        SERVICE={SERVICE}
                        MATERIAL={MATERIAL}
                    />
                </Grid>
            }

            {
                serviceMaterial === MATERIAL ?
                <Grid item xs={12} sm={hideBeforeDeliverer ? 11.5 : 7.4}>
                    <TrackThreeDeliverMaterial
                        material={material}
                        stage={stage}
                        group={group}
                        good={material}
                        service={service}
                        assignee={assignee}
                        selectedObject={selectedObject}
                        levelDeliver={levelDeliver}
                        serviceMaterial={serviceMaterial}
                        setLevelDeliver={setLevelDeliver}
                        levelTwo={levelTwo}
                        levelThree={levelThree}
                        levelFour={levelFour}
                        setLevelTwo={setLevelTwo}
                        setLevelThree={setLevelThree}
                        setLevelFour={setLevelFour}
                        isScanned={isScanned}
                        setScanned={setScanned}
                        isElectronic={isElectronic}
                        setElectronic={setElectronic}
                        isPhysical={isPhysical}
                        setPhysical={setPhysical}
                        color={color}
                        SERVICE={SERVICE}
                        MATERIAL={MATERIAL}/>
                </Grid>
                :
                serviceMaterial === SERVICE ?
                    <Grid item xs={12} sm={hideBeforeDeliverer ? 11.5 : 7.4}>

                        <TrackThreeDeliverService
                            selectedObject={selectedObject}
                            serviceMaterial={serviceMaterial}
                            service={service}
                            assignee={assignee}
                            levelDeliver={levelDeliver}
                            setLevelDeliver={setLevelDeliver}
                            levelTwo={levelTwo}
                            levelThree={levelThree}
                            levelFour={levelFour}
                            setLevelTwo={setLevelTwo}
                            setLevelThree={setLevelThree}
                            setLevelFour={setLevelFour}
                            stage={stage}
                            group={group}
                            act={act}
                            setAct={setAct}
                            isScanned={isScanned}
                            setScanned={setScanned}
                            isElectronic={isElectronic}
                            setElectronic={setElectronic}
                            isPhysical={isPhysical}
                            setPhysical={setPhysical}
                            color={color}
                            SERVICE={SERVICE}
                            MATERIAL={MATERIAL}/>
                    </Grid>
                        :
                        null
            }

        </Grid>
    )
}
export default TrackThreeDeliver;