import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelThreeMost({setLevelThree, levelThree, color}) {

    return (
        <Grid xs={7} container justifyContent={'space-between'} direction="column">

            <Grid item xs={7}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 0 ? color : null}}
                       onClick={() => {
                           setLevelThree(0)
                       }}>
                    <Typography>КСС</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 1 ? color : null}}
                       onClick={() => {
                           setLevelThree(1)
                       }}>
                    <Typography>График</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelThree === 2 ? color : null}}
                       onClick={() => {

                           setLevelThree(2)
                       }}>
                    <Typography>Документи</Typography>
                </Paper>
            </Grid>

        </Grid>

    )
}
export default LevelThreeMost;