import ListItem from "@mui/material/ListItem";
import {Button, Collapse, ListItemButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import List from "@mui/material/List";
import LevelDocumentsForRef from "./levels/LevelDocumentsForRef";
import * as React from "react";
import {useLocalStorage} from "../useLocalStorage";
import AttachmentDialogComponent from "./AttachmentDialogComponent";
import OfferContractCard from "./OfferConractTable";
import Grid from "@mui/material/Grid";
import {setDocState} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import AddOneMoreButton from "./buttons/AddOneMoreButton";

function ReferenceCard({department, open, items, openId, setOpenId, stage, group, setRandomToRefresh}) {
    const styles = {
        tableContainer: {
            maxWidth: "150vh",
            margin: "auto",
            marginTop: "15vh",
            height: "70vh",
            background: "#ccffff",
            borderWidth: 2,
            borderColor: "black",
            borderStyle: "solid"
        },
        table: {
            height: "70vh"
        },
        tableCell: {
            borderRightWidth: '0.4px',
            borderRightStyle: "solid",
            borderRightColor: "#b2b2b2",
            display: "tableRowGroup"
        }
    };
    function rowIs(row, name) {
        return row['path='].endsWith(name)
    }

    function rowHas(row, name) {
        return !!row[name] && row[name].id;
    }

    function subDocsHave(subdocs, name, type) {
        let elements = subdocs.filter(e => e.docname === name);
        console.log(elements)
        let result = elements.length > 0
            && rowHas(elements[0], type);
        console.log('result ', result)

        if (result) {
            console.log('resultttt ', elements[0])
            return elements[0];
        }
        return null;
    }

    function getDoc(subdocs, name) {
        let elements = subdocs.filter(e => e.docname === name);
        return elements[0];
    }

    function formatPerformancePath(path, assigneeId, docType) {
        const stageId = stage ? stage.id : 0;
        const groupId = group ? group.id : 0;
        const goodId = 0;
        return path.replace('performance', `performance-${docType}-${stageId}-${groupId}-${goodId}-${assigneeId}`);
    }

    function upload(event, row, partner, path) {
        event.stopPropagation();

        console.log(event)
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            // Create a new array from the current files
            setDocState({id: department}, navigate, accessToken, setAccessToken, path,
                event.target.files[0],
                "scanned").then(r => setRandomToRefresh(Math.random()))
        }
    }

    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [viewFile, setViewFile] = React.useState(null);
    const [attachmentId, setAttachmentId] = React.useState(null);

    return (
        <List>
            <AttachmentDialogComponent
                viewFile={viewFile}
                open={viewFile}
                id={attachmentId}
                setRandomToRefresh={setRandomToRefresh}
                handleClose={() => setViewFile(null)}/>

            {
                items && items[0] && items[0].map((partner) => {
                    const sum = partner.partner_docs
                        .filter((row) => {return row["path="].includes('performance')})
                        .map(item => {
                            return item && item.services_price && !isNaN(item.services_price)
                                ? parseFloat(item.services_price) : 0
                        }).reduce((prev, next) => prev + next);


                    const offerItem = partner.partner_docs
                            .find(o => o['path='].endsWith('workdescript')) || {physical: null, scanned: null};

                    const contractItem = partner.partner_docs
                            .find(o => o['path='].endsWith('contract')) || {physical: null, scanned: null};

                    const kssItem = partner.partner_docs
                            .find(o => o['path='].endsWith('kss')) || {physical: null, scanned: null};

                    const agreementItem = partner.partner_docs
                            .find(o => o['path='].endsWith('agreement')) || {physical: null, scanned: null};

                    const annexItem = partner.partner_docs
                            .find(o => o['path='].endsWith('annex')) || {physical: null, scanned: null};

                    return <div>
                        <ListItem>
                            <ListItemButton onClick={() => {
                                if (openId !== partner.id) {
                                    setOpenId(partner.id)
                                } else {
                                    setOpenId(null)
                                }
                            }}>
                                {/*     <ListItemIcon>
                                            <InboxIcon />
                                        </ListItemIcon>*/}
                                <ListItemText primary={partner.partner_name} />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </ListItem>


                        <Collapse in={openId === partner.id} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Grid container justifyContent={'flex-start'} style={{paddingLeft: 10, paddingRight: 10}}>
                                    <Grid item xs={3}>
                                        <OfferContractCard
                                            data={ [{name: 'Оферта', path: offerItem["path="], physical: offerItem.physical, scanned: offerItem.scanned}, {name: 'Договор', path: contractItem["path="], physical: contractItem.physical, scanned: contractItem.scanned}]}
                                            department={department}
                                            setRandomToRefresh={setRandomToRefresh}
                                            open={open}
                                            partner={partner}
                                            viewFile={viewFile}
                                            setViewFile={setViewFile}
                                            setAttachmentId={setAttachmentId}
                                            openId={openId}
                                            setOpenId={setOpenId}/>
                                    </Grid>
                                    <Grid item xs={3} style={{marginLeft: 30}}>
                                        <OfferContractCard
                                            data={ [{name: 'КСС', path: kssItem["path="], physical: kssItem.physical, scanned: kssItem.scanned}, {name: 'Спораз ЗБУТ', path: agreementItem["path="], physical: agreementItem.physical, scanned: agreementItem.scanned}]}
                                            department={department}
                                            setRandomToRefresh={setRandomToRefresh}
                                            open={open}
                                            partner={partner}
                                            viewFile={viewFile}
                                            setViewFile={setViewFile}
                                            setAttachmentId={setAttachmentId}
                                            openId={openId}
                                            setOpenId={setOpenId}/>
                                    </Grid>
                                    <Grid item xs={3} style={{marginLeft: 30}}>
                                        <OfferContractCard
                                            data={ [{name: 'Анекс', path: annexItem["path="], physical: annexItem.physical, scanned: annexItem.scanned} ]}
                                            department={department}
                                            setRandomToRefresh={setRandomToRefresh}
                                            open={open}
                                            partner={partner}
                                            viewFile={viewFile}
                                            setViewFile={setViewFile}
                                            setAttachmentId={setAttachmentId}
                                            openId={openId}
                                            setOpenId={setOpenId}/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Table sx={{ minWidth: 650, pl: 10, mt: 10 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="7%" style={styles.tableCell} align="center">ф-ра</TableCell>
                                                    <TableCell width="10%" style={styles.tableCell} align="center">Дата</TableCell>
                                                    <TableCell width="10%" style={styles.tableCell} align="center">Услуги</TableCell>
                                                    <TableCell width="10%" style={styles.tableCell} align="center">Материал</TableCell>
                                                    <TableCell width="21%" style={styles.tableCell} align="center" colSpan={2}>Акт</TableCell>
                                                    <TableCell width="21%" style={styles.tableCell} align="center" colSpan={2}>Фактура</TableCell>
                                                    <TableCell width="21%" style={styles.tableCell} align="center" colSpan={2}>Платежно</TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell style={styles.tableCell}/>
                                                    <TableCell style={styles.tableCell}/>
                                                    <TableCell style={styles.tableCell}/>
                                                    <TableCell style={styles.tableCell}/>
                                                    <TableCell style={styles.tableCell} align="center">
                                                        Физ
                                                    </TableCell>
                                                    <TableCell align="center" style={styles.tableCell}>
                                                        Скан
                                                    </TableCell>
                                                    <TableCell style={styles.tableCell} align="center">
                                                        Физ
                                                    </TableCell>
                                                    <TableCell align="center" style={styles.tableCell}>
                                                        Скан
                                                    </TableCell>
                                                    <TableCell style={styles.tableCell} align="center">
                                                        Физ
                                                    </TableCell>
                                                    <TableCell align="center" style={styles.tableCell}>
                                                        Скан
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    partner.partner_docs
                                                        .filter((row) => {return row["path="].includes('performance')})
                                                        .map((row, index) => {
                                                            const rowActScanned = subDocsHave(row.subdocs,"АКТ", 'scanned');
                                                            const rowInvoiceScanned = subDocsHave(row.subdocs,"Фактура", 'scanned');
                                                            const rowKssScanned = subDocsHave(row.subdocs,"КСС", 'scanned');
                                                            const rowPaymentScanned = subDocsHave(row.subdocs,"Платежно", 'scanned');

                                                            const rowActScannedElement = getDoc(row.subdocs,"АКТ", 'scanned');
                                                            const rowInvoiceScannedElement = getDoc(row.subdocs,"Фактура", 'scanned');
                                                            const rowKssScannedElement = getDoc(row.subdocs,"КСС", 'scanned');
                                                            const rowPaymentScannedElement = getDoc(row.subdocs,"Платежно", 'scanned');

                                                            console.log('rowActScanned ', rowActScanned)
                                                            console.log('rowInvoiceScanned ', rowInvoiceScanned)
                                                            console.log('rowPaymentScanned ', rowPaymentScanned)

                                                            return <TableRow
                                                                key={index}
                                                                sx={{'&:last-child td, &:last-child th': {}}}
                                                            >
                                                                <TableCell component="th" scope="row" style={styles.tableCell} align="center">
                                                                    {row.doc_no}
                                                                </TableCell>
                                                                <TableCell align="center" style={styles.tableCell}>{row.date || ""}</TableCell>
                                                                <TableCell align="right" style={styles.tableCell}>{(row.services_price ? row.services_price.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "") || ""}</TableCell>
                                                                <TableCell align="right" style={styles.tableCell}>{row.goods_price || ""}</TableCell>




                                                                <TableCell align="center" style={styles.tableCell}>
                                                                    <Button color={row.akt ? "success" : 'error' }>{row.akt ? "Да" : "Не"}</Button>
                                                                </TableCell>
                                                                <TableCell align="center" style={styles.tableCell}>
                                                                    <Button color={rowActScanned ? "success" : 'error' }
                                                                            component={!(rowActScanned) ? "label" : null}
                                                                            onClick={() => {
                                                                                if (rowActScanned) {
                                                                                    const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${rowActScanned.scanned.id}&token=${accessToken}`
                                                                                    setViewFile(url);
                                                                                    setAttachmentId(rowActScanned.scanned.id)
                                                                                }
                                                                            }}>{rowActScanned ? "Виж" : "Качи"}
                                                                                {!(rowActScanned) && <input
                                                                                                type="file"
                                                                                                onChange={(event => {
                                                                                                    upload(event, row, partner, rowActScannedElement.path)
                                                                                                })}
                                                                                                hidden
                                                                                            /> }
                                                                    </Button>

                                                                    {rowActScanned && <AddOneMoreButton
                                                                        upload={upload}
                                                                        row={row}
                                                                        partner={partner}
                                                                        element={rowActScannedElement}/>
                                                                    }
                                                                </TableCell>




                                                                <TableCell align="center" style={styles.tableCell}>
                                                                    <Button color={row.invoice ? "success" : 'error' }>{row.invoice ? "Да" : "Не"}</Button>
                                                                </TableCell>
                                                                <TableCell align="center" style={styles.tableCell}>
                                                                    <Button color={rowInvoiceScanned ? "success" : 'error' }
                                                                            component={!(rowInvoiceScanned) ? "label" : null}
                                                                            onClick={() => {
                                                                                if (rowInvoiceScanned) {
                                                                                    const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${rowInvoiceScanned.scanned.id}&token=${accessToken}`
                                                                                    setViewFile(url);
                                                                                    setAttachmentId(rowInvoiceScanned.scanned.id)
                                                                                }
                                                                            }}>{rowInvoiceScanned ? "Виж" : "Качи"}
                                                                        {!(rowInvoiceScanned) && <input
                                                                            type="file"
                                                                            onChange={(event => {
                                                                                upload(event, row, partner, rowInvoiceScannedElement.path)
                                                                            })}
                                                                            hidden
                                                                        /> }</Button>

                                                                    {rowInvoiceScanned && <AddOneMoreButton
                                                                        upload={upload}
                                                                        row={row}
                                                                        partner={partner}
                                                                        element={rowInvoiceScannedElement}/>
                                                                    }
                                                                </TableCell>




                                                                <TableCell align="center" style={styles.tableCell}>
                                                                    <Button color={row.payment ? "success" : 'error' }>{row.payment ? "Да" : "Не"}</Button>
                                                                </TableCell>
                                                                <TableCell align="center" style={styles.tableCell}>
                                                                    <Button color={rowPaymentScanned ? "success" : 'error' }
                                                                            component={!(rowPaymentScanned) ? "label" : null}
                                                                            onClick={() => {
                                                                                if (rowPaymentScanned) {
                                                                                    const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${rowPaymentScanned.scanned.id}&token=${accessToken}`
                                                                                    setViewFile(url);
                                                                                    setAttachmentId(rowPaymentScanned.scanned.id)
                                                                                }
                                                                            }}>{rowPaymentScanned ? "Виж" : "Качи"}
                                                                        {!(rowPaymentScanned) && <input
                                                                            type="file"
                                                                            onChange={(event => {
                                                                                upload(event, row, partner, rowPaymentScannedElement.path)
                                                                            })}
                                                                            hidden
                                                                        /> }</Button>

                                                                    {rowPaymentScanned && <AddOneMoreButton
                                                                        upload={upload}
                                                                        row={row}
                                                                        partner={partner}
                                                                        element={rowPaymentScannedElement}/>
                                                                    }
                                                                </TableCell>



                                                            </TableRow>

                                                        })
                                                }


                                                <TableRow>
                                                    <TableCell align="center" style={styles.tableCell}>
                                                        <b>Общо</b>
                                                    </TableCell>
                                                    <TableCell style={styles.tableCell}/>
                                                    <TableCell style={styles.tableCell} align="right">
                                                        {
                                                            sum ? sum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0
                                                        }
                                                    </TableCell>
                                                    <TableCell style={styles.tableCell}/>

                                                    <TableCell style={styles.tableCell} align="center">
                                                    </TableCell>
                                                    <TableCell align="center" style={styles.tableCell}/>
                                                    <TableCell style={styles.tableCell} align="center">

                                                    </TableCell>
                                                    <TableCell align="center" style={styles.tableCell}>

                                                    </TableCell>
                                                    <TableCell style={styles.tableCell} align="center">

                                                    </TableCell>
                                                    <TableCell align="center" style={styles.tableCell}>

                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                </Grid>

                            </List>
                        </Collapse>
                    </div>

                })
            }
        </List>
    )
}
export default ReferenceCard;
