import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {ListItemButton, Popover} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Group} from "@mui/icons-material";
import moment from "moment";
import bg from 'moment/locale/bg'
import {addObject, getFile} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import PDFRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/pdf";
import PNGRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/png";
import DocViewer from "@cyntler/react-doc-viewer";
import JPGRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/gif";
import MSDocRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/msdoc";
import TextField from "@mui/material/TextField";
import ObjectSelectComponent from "./ObjectSelectComponent";
import ObjectSelectForDialogComponent from "./ObjectSelectForDialogComponent";
import ObjectDataDialog from "./ObjectDataDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function OfferObjectDialog({open, handleClose, anchorEl, selectedObject, setSelectedObject,
                               selectedObjectForOfferName, setSelectedObjectForOfferName, objects}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [objectSettings, setObjectSettings] = React.useState(null);


    return  (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 0,
                horizontal: "right"
            }}
            TransitionComponent={Transition}
        >
            <Grid container padding={3} justifyContent={'center'} spacing={2}>
                <Grid item xs={7}>
                    <ObjectSelectForDialogComponent
                        objects={objects}
                        selectedObject={selectedObject}
                        onChange={(chosen) => {
                            for (const object of objects) {
                                console.log('test ', object)
                                console.log('chosen ', chosen)
                                if (object.id === chosen) {
                                    setSelectedObjectForOfferName(object.name_project)
                                    break;
                                }
                            }
                            handleClose()
                        }}
                        setSelectedObject={setSelectedObject}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        id={"save"}
                        onClick={(event) =>  {
                            setObjectSettings(true)
                        }}
                    >Създай нов</Button>
                </Grid>

            </Grid>

            <ObjectDataDialog
                open={objectSettings}
                handleClose={() => setObjectSettings(false)}
                setSelectedObjectForOffer={setSelectedObject}
                setSelectedObjectForOfferName={setSelectedObjectForOfferName}
                setObjectSettings={setObjectSettings}
                onClick={addObject}/>


        </Popover>
    )
}
export default OfferObjectDialog;