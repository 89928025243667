import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CreateDialog({open, handleClose,
                                       name, setName,
                          itemName, onClick
                                   }) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    return  (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Създай {itemName}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container style={{margin: 10, padding: 10}}>
                <Grid item xs={12}>
                    <TextField value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}/>
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={() => {
                        onClick()
                    }}>Създай</Button>
                </Grid>

            </Grid>

        </Dialog>
    )
}
export default CreateDialog;