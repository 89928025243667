import Grid from "@mui/material/Grid";
import {Paper, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {getDocState, getFile, setDocState} from "../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import CategoryDialogComponent from "../CategoryDialogComponent";
import AttachmentDialogComponent from "../AttachmentDialogComponent";
import {SetPhysicalDialog} from "../SetPhysicalDialog";

function LevelDocumentsForRef({
                            docName, selectedObject,
                        }) {
    const green = 'rgba(66,239,66,0.87)';
    const red = 'rgba(241,65,65,0.88)';

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [hasHistory, setHasHistory] = useState(false);
    const [hasHistoryElectric, setHasHistoryElectric] = useState(false);
    const [hasHistoryScanned, setHasHistoryScanned] = useState(false);
    const [fullscreenDialogOpen, setFullscreenDialogOpen] = React.useState(null);
    const [viewFile, setViewFile] = React.useState(null);
    const [physicalDialogOpen, setPhysicalDialogOpen] = React.useState(false);

    const [physicalRemark, setPhysicalRemark] = React.useState(null);


    const [isPhysical, setPhysical] = React.useState(false);
    const [isElectronic, setElectronic] = React.useState(false);
    const [isScanned, setScanned] = React.useState(false);

    useEffect(() => {
        getDocState({id: selectedObject}, navigate, accessToken, setAccessToken, docName)
            .then(r => {
                setHasHistory(false);
                setHasHistoryElectric(false);
                setHasHistoryScanned(false);
                if(r.items && r.items) {
                    // PHYSICAL
                    if (r.items.physical && r.items.physical.state === 1) {
                        setPhysical(true)
                        if (r.items.physical.remark) {
                            setPhysicalRemark(r.items.physical.remark)
                        } else {
                            setPhysicalRemark(null)
                        }
                    } else {
                        setPhysical(false)
                        setPhysicalRemark(null)
                    }

                    // ELECTRONIC
                    if (r.items.electronic && r.items.electronic.id) {
                        setElectronic(r.items.electronic.id)
                        if (r.items.electronic && r.items.electronic.history && r.items.electronic.history.length > 0) {
                            setHasHistoryElectric(r.items.electronic.history);
                        }
                    } else {
                        setElectronic(false)
                    }

                    // SCANNED
                    if (r.items.scanned && r.items.scanned.id) {
                        setScanned(r.items.scanned.id)
                        if (r.items.scanned && r.items.scanned.history && r.items.scanned.history.length > 0) {
                            setHasHistoryScanned(r.items.electronic.history);
                        }
                    } else {
                        setScanned(false)
                    }
                }

            })
    }, [docName]);

    return (
        <Grid xs={10} container justifyContent={'space-between'}>

            <Grid item xs={3}>
                <Paper elevation={6} className={"paper-style"}
                       style={{height: 'auto', background: isElectronic ? green : red}}
                       onClick={() => {
                           if (!isElectronic){
                               setElectronic(-1)
                           }

                           console.log(docName)

                       }}>
                    <Grid container justifyContent={'center'} style={{padding: 10}}>
                        <Grid item xs={10}>
                            <Typography>Електронен</Typography>
                        </Grid>
                        {
                            isElectronic > 0 &&
                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    onClick={() => {
                                        getFile(navigate, accessToken, setAccessToken, isElectronic);
                                    }}>
                                    Виж файл
                                </Button>
                            </Grid>
                        }
                        {
                            isElectronic > 0 &&
                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    onClick={() => {
                                        const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${isElectronic}&token=${accessToken}`
                                        setViewFile(url);
                                    }}>
                                    Прегледай
                                </Button>
                            </Grid>
                        }

                        {
                            isElectronic >= -1 &&

                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    component="label">
                                    Прикачи файл
                                    <input
                                        type="file"
                                        onChange={(event => {
                                            event.stopPropagation();

                                            console.log(event)
                                            if (event && event.target && event.target.files && event.target.files.length > 0) {
                                                // Create a new array from the current files
                                                setDocState({id: selectedObject}, navigate, accessToken, setAccessToken, docName,
                                                    event.target.files[0],
                                                    "electronic")
                                            }
                                        })}
                                        hidden
                                    />
                                </Button>
                            </Grid>
                        }

                        {
                            hasHistoryElectric &&

                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    onClick={() => {
                                        setHasHistory(hasHistoryElectric)
                                        setFullscreenDialogOpen(true)
                                    }}>
                                    Виж история
                                </Button>
                            </Grid>
                        }


                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={3}>
                <Tooltip title={physicalRemark}>
                    <Paper elevation={6} className={"paper-style"}
                           style={{height: 130, background: isPhysical ? green : red}}
                           onClick={() => {
                               if (!isPhysical) {
                                   setPhysicalDialogOpen(true)
                               } else {
                                   setPhysical(false)
                                   setDocState({id: selectedObject}, navigate, accessToken, setAccessToken, docName,
                                       {
                                           physical: {state: 0}
                                       })
                               }

                           }}
                    >
                        <Typography>Физически</Typography>
                    </Paper>
                </Tooltip>

            </Grid>

            <Grid item xs={3}>
                <Paper elevation={6} className={"paper-style"}
                       style={{height: 'auto', background: isScanned ? green : red}}
                       onClick={() => {
                           if (!isScanned){
                               setScanned(-1)
                           }
                       }}>
                    <Grid container justifyContent={'center'} style={{padding: 10}}>

                        <Grid item xs={10}>
                            <Typography>Сканиран</Typography>
                        </Grid>

                        {
                            isScanned > 0 &&
                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    onClick={() => {
                                        getFile(navigate, accessToken, setAccessToken, isScanned);
                                    }}>
                                    Виж файл
                                </Button>
                            </Grid>
                        }

                        {
                            isScanned >= -1 &&

                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    component="label">
                                    Прикачи файл
                                    <input
                                        type="file"
                                        multiple="multiple"
                                        onChange={(event => {
                                            event.stopPropagation();

                                            console.log(event)
                                            if (event && event.target && event.target.files && event.target.files.length > 0) {
                                                // Create a new array from the current files
                                                setDocState({id: selectedObject}, navigate, accessToken, setAccessToken, docName,
                                                    event.target.files[0],
                                                    "scanned")
                                            }
                                        })}
                                        hidden
                                    />
                                </Button>
                            </Grid>
                        }


                        {
                            hasHistoryScanned &&

                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    onClick={() => {
                                        setHasHistory(hasHistoryScanned)
                                        setFullscreenDialogOpen(true)
                                    }}>
                                    Виж история
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>

            <CategoryDialogComponent
                open={fullscreenDialogOpen}
                handleClose={() => setFullscreenDialogOpen(false)}
                attachments={hasHistory}
                category={"Файлове"}
                object={""}
                setViewFile={setViewFile}
            />

            <AttachmentDialogComponent
                viewFile={viewFile}
                open={viewFile}
                handleClose={() => setViewFile(null)}/>

            <SetPhysicalDialog
                setDocState={setDocState}
                open={physicalDialogOpen}
                handleClose={() => {
                    setPhysical(false)
                    setPhysicalDialogOpen(false)
                }}
                setPhysical={setPhysical}
                selectedObject={selectedObject}
                docName={docName}
                isPhysical={isPhysical}
                setOpen={setPhysicalDialogOpen}
            />

        </Grid>

    )
}
export default LevelDocumentsForRef;