import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import React from "react";

//autocompleteProperty, dataset, setAutocompleteProperty, restApiAddPropsFunction, title, propName
export function AutocompleteProps(props) {
    const filter = createFilterOptions();

    return (
        <Autocomplete
            size={props.size}
            value={props.autocompleteProperty}
            onChange={(event, newValue) => {
                if (props.onChange) {
                    props.onChange()
                }
                if (typeof newValue === 'string') {
                    props.setAutocompleteProperty({
                        [props.propName]: newValue,
                    });
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    props.restApiAddPropsFunction(newValue.inputValue)

                    props.setAutocompleteProperty({
                        [props.propName]: newValue.inputValue,
                    });
                } else {
                    props.setAutocompleteProperty(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option[props.propName]);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        [props.propName]: `Добави "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="autocompleteProperty"
            fullWidth
            options={props.dataset}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option[props.propName];
            }}
            renderOption={(renderProps, option) => {
                return <Typography style={{fontWeight: option[props.propName] && option[props.propName].startsWith("Добави \"") ? 'bold' : 'none'}} {...renderProps}>{option[props.propName]}</Typography>
            }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={props.title} />
            )}
            {...props.additional}
        />
    )
}