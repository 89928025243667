import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";
import React, {useEffect} from "react";
import {addGroup, getWorkingGroups} from "../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";


function LevelStageGroup({levelStageGroup, selectedObject, stage, setStage, group, setGroup, color}) {
    let navigate = useNavigate();

    const [stages, setStages] = React.useState([]);
    const [groups, setGroups] = React.useState([]);


    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    useEffect(() => {
        // Paste code to be executed on subsequent renders:
        getWorkingGroups({id: selectedObject}, navigate, accessToken, setAccessToken)
            .then((result) => {
                setStages(result.items)
            })
    }, [selectedObject]);

    useEffect(() => {
        if (stage && stage.subgroups) {
            setGroups(stage.subgroups);
        }
    }, [stage]);

    return (
        <Grid xs={11} container justifyContent={'space-between'} direction="column">
            <Paper elevation={6} className={"paper-style"}
                   onClick={() => {
                       //setLevelStageGroup(0)
                   }}
                   style={{background: levelStageGroup === 0 ? color : null}}>
                <Grid item xs={10}>

                    <AutocompleteProps
                        autocompleteProperty={stage}
                        dataset={stages}
                        setAutocompleteProperty={setStage}
                        restApiAddPropsFunction={(name) => {
                            addGroup({id: selectedObject}, navigate,
                                accessToken, setAccessToken, setStage, setGroup,
                                name)
                        }}
                        onChange={() => {
                            setGroups([]);
                        }}
                        title={"Етап"}
                        propName={"name_group"}
                    />
                </Grid>
            </Paper>

            <Paper elevation={6} className={"paper-style"}
                   onClick={() => {
                       //setLevelStageGroup(1)
                   }}
                   style={{background: levelStageGroup === 1 ? color : null}}>

                <Grid item xs={10}>

                    <AutocompleteProps
                        autocompleteProperty={group}
                        dataset={groups}
                        setAutocompleteProperty={setGroup}
                        restApiAddPropsFunction={(name) => {
                            addGroup({id: selectedObject}, navigate,
                                accessToken, setAccessToken, setStage, setGroup,
                                name, stage.id)
                        }}
                        title={"Група"}
                        propName={"name_group"}
                    />
                </Grid>

            </Paper>

        </Grid>
    )
}

export default LevelStageGroup;