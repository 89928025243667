import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelOneOfferContractExecution({offersMode, selectedObject, levelOne, setLevelOne, setReferenceOpen, color, setDocuments, resetProps}) {

    return (
        <Grid xs={8} container justifyContent={'space-between'} direction="column">
            { (offersMode || selectedObject) ?
                <Grid item xs={8}>
                    <Paper
                        onClick={() => {
                            resetProps()
                            setLevelOne(0)
                            setDocuments(false)
                        }}
                        elevation={6} className={"paper-style"}
                        style={{background: levelOne === 0 ? color : null}}>
                        <Typography>Оферта</Typography>
                    </Paper>
                </Grid> : null
            }

            {
                selectedObject ?
                <Grid item xs={8}>
                    <Paper elevation={6} className={"paper-style"}
                           onClick={() => {
                               resetProps()
                               setLevelOne(1)
                               setDocuments(false)
                           }}
                           style={{background: levelOne === 1 ? color : null}}>
                        <Typography>Договор</Typography>
                    </Paper>
                </Grid> : null
            }



            { selectedObject ?
                <Grid item xs={8}>
                    <Paper elevation={6} className={"paper-style"}
                           onClick={() => {
                               resetProps()
                               setLevelOne(2)
                               setDocuments(false)
                           }}
                           style={{background: levelOne === 2 ? color : null}}>
                        <Typography>Изпълнение</Typography>
                    </Paper>
                </Grid> : null
            }


            { selectedObject ?
                <Grid item xs={8}>
                    <Paper elevation={6} className={"paper-style"}
                           onClick={() => {
                               setReferenceOpen(true)
                           }}>
                        <Typography>Справка</Typography>
                    </Paper>
                </Grid> : null
            }


        </Grid>

    )
}

export default LevelOneOfferContractExecution;