import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LevelThreeZeroOneMaterial({levelFourDocuments, setLevelFourDocuments, color}) {

    return (
        <Grid xs={7} container justifyContent={'space-between'} direction="column">

            <Grid item xs={7}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFourDocuments === 0 ? color : null}}
                       onClick={() => {
                           setLevelFourDocuments(0)
                       }}>
                    <Typography>Договор</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFourDocuments === 1 ? color : null}}
                       onClick={() => {
                           setLevelFourDocuments(1)
                       }}>
                    <Typography>График доставка</Typography>
                </Paper>
            </Grid>

            <Grid item xs={8}>
                <Paper elevation={6} className={"paper-style"}
                       style={{background: levelFourDocuments === 2 ? color : null}}
                       onClick={() => {
                           setLevelFourDocuments(2)
                       }}>
                    <Typography>Анекс</Typography>
                </Paper>
            </Grid>

        </Grid>

    )
}
export default LevelThreeZeroOneMaterial;